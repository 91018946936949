// @ts-check
import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { ActionButton, TextInput } from '../../Common'
import {
  getBookmarks,
  addBookmark,
  formValuesToLocalStorage
} from '../SearchBar/Bookmarks/helpers'
import { formValidParams } from '../SearchForm/SearchForm'

const AddBookmarkCta = ({ disabled, handleClick, text = 'Add Bookmark' }) => {
  return (
    <ActionButton disabled={disabled} onClick={handleClick} title={text}>
      {text}
    </ActionButton>
  )
}

const MAX_BOOKMARKS_NB = 3

/**
 *
 * @param {Object} props
 * @param {boolean} props.filtersFormIsDirty - to enable the button if the form is dirty
 * @param {Object} props.filters - to be set to localStorage as params in Bookmark
 * @returns {JSX.Element}
 */
const BookmarksForm = ({ filtersFormIsDirty, filters }) => {
  const { t } = useTranslation()
  const [showForm, setShowForm] = useState(false)
  const toggleShowForm = () => setShowForm(!showForm)

  // get bookmarks from localStorage
  const bookmarks = getBookmarks()

  const canAddBookmark =
    filtersFormIsDirty && bookmarks.length < MAX_BOOKMARKS_NB

  const handleAddBookmark = values => {
    const formattedValues = formValuesToLocalStorage(filters)
    // get params from URL to be set into localStorage
    /** @type {URLSearchParams} */
    const urlSearchParams = new URLSearchParams(formattedValues)

    // remove params that are out of the SearchForm
    for (const key of urlSearchParams.keys()) {
      if (!formValidParams.includes(key)) {
        urlSearchParams.delete(key)
      }
    }

    /** @type {import('../SearchBar/Bookmarks/types').Bookmark} */
    const bookmark = { name: values.name, params: urlSearchParams.toString() }

    // add bookmark to localStorage
    addBookmark(bookmark)
    toggleShowForm()
  }

  return (
    <>
      {!showForm && (
        <AddBookmarkCta
          disabled={!canAddBookmark}
          handleClick={toggleShowForm}
          text={`${t('kanban-board.buttons.addBookmark')} (${
            bookmarks.length
          }/${MAX_BOOKMARKS_NB})`}
        />
      )}
      {showForm && (
        <Formik
          initialValues={{ name: '' }}
          onSubmit={handleAddBookmark}
          validate={values => {
            const errors = {}
            if (!values.name) {
              errors.name = t('kanban-board.bookmark-form.validation.required')
            }
            // make sure name is unique
            else if (bookmarks.some(b => b.name === values.name)) {
              errors.name = t(
                'kanban-board.bookmark-form.validation.nameNotUnique'
              )
            }
            return errors
          }}>
          {formikProps => (
            <Form>
              <div style={{ width: 'inherit' }}>
                <Field
                  name='name'
                  placeholder={t('kanban-board.bookmark-form.inputPlaceholder')}
                  component={TextInput}
                  maxLength={20}
                />
              </div>

              <ActionButton
                style={{ height: 40, marginTop: 10, whiteSpace: 'nowrap' }}
                className='action-btn--blue'
                type='submit'
                title={t('common.add')}>
                {t('common.add')}
              </ActionButton>
              <ActionButton
                style={{ height: 40, marginTop: 10 }}
                onClick={toggleShowForm}
                title={t('common.cancel')}>
                {t('common.cancel')}
              </ActionButton>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default BookmarksForm
