// @ts-check
import * as Yup from 'yup'

import { mergeUrlWithParams } from '../../../../helpers/url'
import actions from '../../../../state/actions/data'
import { dataTypes } from '../../../../configurations'

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .trim() // to ensure that the string is not only whitespaces
      .max(50, t('admin.tags.validation.tooLongMessage'))
      .required(t('admin.tags.validation.nameRequired')),
    categoryId: Yup.number()
      .nullable()
      .required(t('admin.tags.validation.categoryRequired')),
    active: Yup.bool()
  })

/**
 * Returns an error message based on the response code.
 * If the response code is 'ALREADY_EXIST', it returns a specific message indicating that the tag already exists.
 * Otherwise, it returns a generic error message.
 * @param {object} res - The response object.
 * @param {string} res.code - The response code.
 * @param {string} res.message - The response message.
 * @param {Function} t - The translation function.
 * @returns {string} The error message.
 */
export const getApiErrorMessage = (res, t) => {
  console.log(res.code)
  switch (res.code) {
    case 'ALREADY_EXIST':
      return t('admin.tags.validation.alreadyExists')
    case 'CANNOT_DELETE_TAG':
      return t('admin.tags.validation.cannotDelete')
    default:
      return t('messages.error')
  }
}

/**
 * Handles the edit operation for a given item. It dispatches an action to update the data.
 * If the item has an update URL, it dispatches the updateDataPreInit action with the necessary parameters.
 * @param {Object} item - The tag to be edited.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {string} rawUrl - The raw URL for the list.
 * @param {Function} callback - The callback function to be executed after the update.
 * @returns {Function} A function that takes the new values and form actions, and dispatches the update action.
 */
export const handleEdit =
  (item, dispatch, rawUrl, tagCategoriesContext, callback) =>
  (values, formActions) => {
    const updateUrl = item._links.update.href
    const listUrl = mergeUrlWithParams(null)(rawUrl)

    const dataToInvalidate = [
      { dataType: dataTypes.tags.name, context: listUrl },
      { dataType: dataTypes.tagCategories.name, context: tagCategoriesContext },
      { dataType: dataTypes.ideas.name }
    ]

    if (!!updateUrl) {
      dispatch(
        actions.updateDataPreInit(
          updateUrl,
          values,
          dataTypes.tags.name,
          dataToInvalidate,
          callback(values, formActions),
          listUrl
        )
      )
    }
  }
