import i18n from '../i18n'

const options = {
  dateStyle: 'medium',
  timeStyle: 'short'
}

export const dateLocal = input => {
  const { language } = i18n
  return new Date(input).toLocaleString(language, options)
}
export const onlyDateLocal = input => {
  const { language } = i18n
  return new Date(input).toLocaleDateString(language, {
    dateStyle: options.dateStyle
  })
}
