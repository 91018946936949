// @ts-check
import React from 'react'
import PropTypes from 'prop-types'

import './hero.scss'

const VfHero = ({
  imageUrl,
  headline,
  videoSrcSet,
  text,
  narrow,
  showVideo
}) => {
  let style = {
    backgroundImage: `url(${imageUrl})`,
    backgroundColor: '#fff',
    minHeight: '30vh',
    height: 'auto'
  }

  if (narrow) {
    style = { ...style, height: '45vh' }
  }

  return (
    <section
      className={`vf-hero hero ${showVideo || 'no-video'}`}
      style={style}>
      {showVideo && Array.isArray(videoSrcSet) && !!videoSrcSet.length && (
        <div className='hero__video-container'>
          <video
            autoPlay
            muted
            loop
            controls
            controlsList='nodownload nofullscreen'>
            {videoSrcSet.map((item, idx) => (
              <source key={idx} src={item.src} type={item.type} />
            ))}
          </video>
        </div>
      )}

      {(headline || text) && (
        <div className='vf-hero__content'>
          <h1 className='vf-hero__headline hero__title'>{headline}</h1>
          {text}
        </div>
      )}
    </section>
  )
}

VfHero.propTypes = {
  imageUrl: PropTypes.string,
  headline: PropTypes.string,
  text: PropTypes.string,
  narrow: PropTypes.bool,
  showVideo: PropTypes.bool,
  videoSrcSet: PropTypes.array
}

VfHero.defaultProps = {
  headline: '',
  text: '',
  narrow: false,
  showVideo: false,
  videoSrcSet: null
}

export default VfHero
