import React, { useState, useEffect } from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import SearchFormContent from './SearchFormContent'
import { VfButton } from '../../DesignSystem/Buttons'
import { TextInput, SubmitButton } from '../../Common'

export const ResetButton = ({
  t,
  disabled = false,
  handleClick,
  className
}) => (
  <VfButton
    text={t('common.reset')}
    color=''
    className={`search-form__reset-btn ${className}`}
    disabled={disabled}
    onClick={handleClick}
  />
)

ResetButton.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string
}

const FormComponent = ({
  lng,
  form,
  isSearching = false,
  dirty = false,
  handleSubmit,
  setIsDirty,
  resetForm,
  setValues,
  initialValuesInStore
}) => {
  const { t } = useTranslation()

  const [fullScreenForm, setFullScreenForm] = useState(false)

  useEffect(() => {
    if (!!initialValuesInStore) {
      setValues(initialValuesInStore)
    }
  }, [setValues, initialValuesInStore])

  const containsTextElement = elements =>
    elements.find(el => el.parameterName === 'containsText') || {}

  return (
    <>
      {/* Small screens */}
      <div className='search-form--md-hidden'>
        <VfButton
          text={t('common.changeFilter')}
          outlined
          color='secondary'
          className='w-100'
          onClick={() => setFullScreenForm(true)}
        />

        <div className='mt-3'>
          <Field
            name='containsText'
            placeholder={containsTextElement(form.elements).label}
            component={TextInput}
          />

          <SubmitButton
            button={
              <VfButton
                text={t('common.search')}
                color='secondary'
                className='my-3 w-100'
                form='search-form'
              />
            }
            inProgress={isSearching}
            inProgressText={t('common.searching')}
            disabled={isSearching}
          />

          <ResetButton
            t={t}
            className='w-100'
            disabled={isSearching || !dirty}
            handleClick={() => {
              setIsDirty(false)
              resetForm()
              handleSubmit()
            }}
          />
        </div>
      </div>

      <div
        className={`search-form ${
          fullScreenForm ? 'search-form--fullScreen' : ''
        }`}>
        <div className='search-form__top search-form--md-hidden'>
          <span>{t('forms.searchIdeas.filters')}</span>
          <button
            aria-label='close-form'
            onClick={() => setFullScreenForm(false)}>
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} id='search-form'>
          <SearchFormContent formElements={form.elements} language={lng} />

          <div className='search-form__buttons my-2'>
            <VfButton
              text={t('common.applyFilter')}
              outlined
              color='secondary'
              className='search-form--md-hidden mt-3'
              onClick={() => setFullScreenForm(false)}
            />

            <SubmitButton
              button={
                <VfButton
                  text={t('common.search')}
                  color='secondary'
                  className='my-2'
                  onClick={() => {
                    setFullScreenForm(false)
                    setIsDirty(true)
                  }}
                />
              }
              inProgress={isSearching}
              inProgressText={t('common.searching')}
              disabled={isSearching}
            />

            <ResetButton
              t={t}
              disabled={isSearching || !dirty}
              handleClick={() => {
                setIsDirty(false)
                resetForm()
                handleSubmit()
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

FormComponent.propTypes = {
  lng: PropTypes.string,
  form: PropTypes.object,
  isSearching: PropTypes.bool,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setIsDirty: PropTypes.func,
  resetForm: PropTypes.func,
  setValues: PropTypes.func,
  initialValuesInStore: PropTypes.object
}

export default FormComponent
