import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from './'
import actions from '../../../state/actions/data'
import { getCreateChallengeUrl } from '../../../selectors'

const CreateForm = ({ notificationsTrigger }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const createUrl = useSelector(getCreateChallengeUrl)

  const [apiError, setApiError] = useState(null)

  const initialValues = {
    title: '',
    description: '',
    members: []
  }

  const validationSchema = Yup.object({
    title: Yup.string().required(t('team.form.validation.nameRequired')),
    description: Yup.string().max(
      500,
      t('team.form.validation.descriptionTooLong')
    ),
    members: Yup.array()
    // .required(t('team.form.validation.membersRequired')) // TODO: not needed for now
  })

  const formatDataForApi = ({ title, description, members }) => ({
    title,
    description,
    members: members.map(item => item.value)
  })

  const sendDataToApi = (data, formActions) => {
    const dataToInvalidate = [{ dataType: 'challenges' }]

    const callback = res => {
      if (!!res.error) {
        setApiError(t('messages.somethingWentWrong'))
        formActions.setSubmitting(false)
      } else {
        const returnUrl = `/team-details/${res.id}`

        notificationsTrigger({ id: res.id, url: returnUrl })
      }
    }

    dispatch(
      actions.createDataPreInit(
        createUrl,
        formatDataForApi(data),
        'challenges',
        dataToInvalidate,
        callback
      )
    )
  }

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={initialValues}
      handleSubmit={sendDataToApi}
      apiError={apiError}
    />
  )
}

CreateForm.propTypes = {
  notificationsTrigger: PropTypes.func
}

export default CreateForm
