import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import Form, { EditFormFields } from './Form'
import { getTagCategoriesListUrl, getTagsListUrl } from '../../../../selectors'
import { handleEdit } from './helpers'
import ActionButton from '../../../Common/ActionButton'

const Edit = ({ item, apiError, editCallback, handleCancel, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const rawUrl = useSelector(getTagCategoriesListUrl)
  const tagsUrl = useSelector(getTagsListUrl)

  const handleSubmit = handleEdit(item, dispatch, rawUrl, tagsUrl, editCallback)

  const CancelBtn = ({ disabled = false }) => (
    <ActionButton onClick={handleCancel} disabled={disabled}>
      {t('common.cancel')}
    </ActionButton>
  )

  return (
    <div>
      <Form
        initialValues={{ ...item }}
        handleSubmit={handleSubmit}
        CancelBtn={CancelBtn}
        FormFields={EditFormFields}
        {...props}
      />

      {apiError && <div className='vf-utility-typo--error'>{apiError}</div>}
    </div>
  )
}

Edit.propTypes = {
  item: PropTypes.object,
  apiError: PropTypes.string,
  editCallback: PropTypes.func,
  handleCancel: PropTypes.func
}

export default Edit
