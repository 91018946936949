// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import VfHero from '../DesignSystem/Hero'
import { VfContainer } from '../DesignSystem/Utils'

const Template = ({ heroImg, title, children, ...props }) => {
  return (
    <div className='overview-page'>
      {!!heroImg && <VfHero imageUrl={heroImg} />}

      <VfContainer>
        <h1 className='vf-text--center mt-3'>{title}</h1>
        {children}
      </VfContainer>
    </div>
  )
}

Template.propTypes = {
  heroImg: PropTypes.string,
  title: PropTypes.string,
  showVideo: PropTypes.bool,
  children: PropTypes.node
}

Template.defaultProps = {
  title: ''
}

export default Template
