// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import actions from '../../state/actions/other'

const Pagination = ({
  totalPages,
  currentPage,
  isLast,
  isFirst,
  handleNextClick,
  handlePrevClick,
  storeKey
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onPrevClick = () => {
    if (!!storeKey) {
      dispatch(
        actions.setPaginationConfig({
          storeKey,
          currentPage: currentPage - 1
        })
      )
    }
    handlePrevClick()
  }

  const onNextClick = () => {
    if (!!storeKey) {
      dispatch(
        actions.setPaginationConfig({
          storeKey,
          currentPage: currentPage + 1
        })
      )
    }
    handleNextClick()
  }

  return (
    totalPages > 1 && (
      <ul
        className='vf-pagination mt-2'
        style={{ alignItems: 'center', width: '100%' }}>
        <li className={`vf-page-item ${isFirst && 'disabled'}`}>
          <button
            className='vf-page-link'
            aria-disabled={isFirst}
            onClick={onPrevClick}>
            {t('common.prev')}
          </button>
        </li>
        <li className={`vf-page-item ${isLast && 'disabled'}`}>
          <button
            className='vf-page-link'
            aria-disabled={isLast}
            onClick={onNextClick}>
            {t('common.next')}
          </button>
        </li>
        <li className='vf-page-item' style={{ marginLeft: 'auto' }}>
          {t('common.page')} {currentPage} {t('common.of')} {totalPages}
        </li>
      </ul>
    )
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  handleNextClick: PropTypes.func,
  handlePrevClick: PropTypes.func,
  storeKey: PropTypes.string
}

export default Pagination
