// @ts-check
import { combineReducers } from 'redux'
import { actionTypes } from '../actions/data'
import { actionTypes as dndActionTypes } from '../actions/dnd'
import { INVALIDATE_COMMENTS } from '../actions/other'

const getDetails = dataType => {
  const filtersDefault = {}
  const filters = (state = filtersDefault, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_SUCCEEDED: {
        const tmp = state[action.context] || []
        return {
          ...state,
          [action.context]: [...new Set([...tmp, ...action.allIds])]
        }
      }
      case actionTypes.INVALIDATE_DATA:
        return filtersDefault

      case dndActionTypes.DND_UPDATE_FILTERS: {
        const { filters, itemId } = action
        const newSourceItems = state[filters.source].filter(id => id !== itemId)
        const newTargetItems = [...state[filters.target], itemId]

        return {
          ...state,
          [filters.source]: newSourceItems,
          [filters.target]: newTargetItems
        }
      }
      default:
        return state
    }
  }

  const isFetchingDefault = []
  const isFetching = (state = isFetchingDefault, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_INIT:
        return [...state, action.url]
      case actionTypes.FETCH_DATA_SUCCEEDED:
      case actionTypes.FETCH_DATA_FAILED:
        return state.filter(item => item !== action.context)
      case actionTypes.INVALIDATE_DATA:
      case dndActionTypes.DND_INVALIDATE_DATA:
      case INVALIDATE_COMMENTS:
        return isFetchingDefault

      default:
        return state
    }
  }

  const itemsDefault = {}
  const items = (state = itemsDefault, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_SUCCEEDED:
        return { ...state, ...action.payload.items }
      // not invalidating here on DND as this is being updated and valid separately. See below.
      case actionTypes.INVALIDATE_DATA: {
        return itemsDefault
      }
      case dndActionTypes.DND_UPDATE_DATA: {
        const { updatedItem } = action
        return { ...state, [updatedItem.id]: updatedItem }
      }
      default:
        return state
    }
  }

  const payloadDefault = {}
  const payload = (state = payloadDefault, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_SUCCEEDED:
        return { ...state, [action.context]: action.payload }
      case actionTypes.INVALIDATE_DATA: {
        return payloadDefault
      }
      default:
        return state
    }
  }

  const allDefault = []
  const all = (state = allDefault, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_SUCCEEDED:
        return [...state, action.context]
      case actionTypes.INVALIDATE_DATA:
      case dndActionTypes.DND_INVALIDATE_DATA:
      case INVALIDATE_COMMENTS:
        return allDefault
      default:
        return state
    }
  }

  return combineReducers({ filters, payload, items, all, isFetching })
}

const allReducers = {
  activities: getDetails('activities'),
  challenges: getDetails('challenges'),
  ideas: getDetails('ideas'),
  comments: getDetails('comments'),
  dashboard: getDetails('dashboard'),
  allUsers: getDetails('allUsers')
}

export default combineReducers(allReducers)
