// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { VfCardAsLink } from '../../DesignSystem/Cards'
import {
  StarsPainter,
  LikesPainter,
  CommentsSummary,
  ViewsPainter
} from '../../Common'
import { getAuthorFullName } from '../../../helpers/author'
import { getChallengeTheme } from '../../../helpers/color'
import { dateLocal } from '../../../helpers/date'
import { icons } from '../../../assets/icons'

const Item = ({
  item,
  showViews = false,
  toggleEditForm = () => {}
}) => {
  const { t } = useTranslation()

  const {
    id,
    title,
    localizedChallengeInfo = {},
    published = '',
    author,
    description = '',
    starsCount = 0,
    commentsCount = 0,
    likesCount = 0,
    numberOfViews = 0,
    challenge: { id: challengeId },
    ideaState
  } = item

  const colors = getChallengeTheme(challengeId)
  const bodyText = !!description ? description.slice(0, 200) : ''

  return (
    <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
      <VfCardAsLink
        to={`/improvement-details/${id}`}
        title={title}
        bodyText={bodyText}
        headerBgColor={colors.mainColor}
        headerTitle={localizedChallengeInfo.title || localizedChallengeInfo.titles?.EN}
        headerCategory={t('common.challenge_plural')}
        caption={`${t('common.published')} ${dateLocal(published)} | ${t('common.status')} ${t('ideaStates.' + ideaState.toLowerCase())}`}
        barColor={colors.bar}
        topChildren={
          <div className='edit-button__container'>
            <div
              onClick={e => {
                e.preventDefault()
                toggleEditForm(item)
              }}
              className='edit-button__icon'>
              <img src={icons.Edit} alt={t('common.edit')} />
            </div>
          </div>
        }>
        <div className='vf-card__text'>
          {author && (
            <p className='mb-1 idea-card__author'>
              {t('common.contributor')} <b>{getAuthorFullName(author)}</b>
            </p>
          )}
          <div className='idea-opinions'>
            {showViews && <ViewsPainter count={numberOfViews} />}
            <StarsPainter rating={starsCount} />
            <LikesPainter count={likesCount} />
            <CommentsSummary count={commentsCount} t={t} />
          </div>
        </div>
      </VfCardAsLink>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  showViews: PropTypes.bool,
  toggleEditForm: PropTypes.func
}

export default Item
