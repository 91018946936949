import React from 'react'
import { useTranslation } from 'react-i18next'

import PerOLCTable from '../Components/Ideas/ImprovementTables/PerOLCTable'
import PerTeamTable from '../Components/Ideas/ImprovementTables/PerTeamTable'
import { GlobalStatistics } from '../Components/Ideas/Dashboard'

const DashboardPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className='mt-4'>
        <h3>{t('titles.globalStatistics')}</h3>

        <GlobalStatistics />
      </div>

      <div className='mt-4'>
        <h3>{t('titles.improvementTeamsInsights')}</h3>

        <PerTeamTable />
      </div>

      <div className='mt-4'>
        <h3>{t('titles.organisationInsights')}</h3>

        <PerOLCTable />
      </div>
    </>
  )
}

export default DashboardPage
