import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { NewItem } from '../../../Common/InlineList'
import Form, { CreateFormFields } from './Form'
import actions from '../../../../state/actions/data'
import { getTagCategoriesListUrl } from '../../../../selectors'
import { mergeUrlWithParams } from '../../../../helpers/url'
import { getApiErrorMessage } from './helpers'
import { dataTypes } from '../../../../configurations'

const Create = ({ createCategoryOptimisticUI, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const rawUrl = useSelector(getTagCategoriesListUrl)

  const [apiError, setApiError] = useState(null)

  const handleSubmit = (values, formActions) => {
    const url = mergeUrlWithParams(null)(rawUrl)
    const dataToInvalidate = [
      { dataType: dataTypes.tagCategories.name, context: url }
    ]

    const callback = res => {
      if (!!res.error) {
        const errorMessage = getApiErrorMessage(res, t)

        setApiError(errorMessage)
        formActions.setSubmitting(false)
      } else {
        setApiError(null)
        createCategoryOptimisticUI(values)
        formActions.resetForm()
      }
    }

    dispatch(
      actions.createDataPreInit(
        url,
        values,
        dataTypes.tagCategories.name,
        dataToInvalidate,
        callback,
        url
      )
    )
  }

  return (
    <NewItem className='mt-2' style={{ display: 'inline-block' }}>
      <Form
        initialValues={{ name: '', color: '', active: true }}
        handleSubmit={handleSubmit}
        btnText={t('common.add')}
        FormFields={CreateFormFields}
        {...props}
      />

      {apiError && <div className='vf-utility-typo--error'>{apiError}</div>}
    </NewItem>
  )
}

Create.propTypes = {
  createCategoryOptimisticUI: PropTypes.func
}

export default Create
