import React from 'react'
import PropTypes from 'prop-types'

import ActionButton from '../../../Common/ActionButton'

const SaveBtn = ({ disabled = false, btnText }) => {
  return (
    <ActionButton
      type='submit'
      className='action-btn--blue'
      disabled={disabled}>
      {btnText}
    </ActionButton>
  )
}

SaveBtn.propTypes = {
  disabled: PropTypes.bool,
  btnText: PropTypes.string
}

export default SaveBtn
