import * as yup from 'yup'
import {
  obligatoryStatusesForAssignee,
  obligatoryStatusesForDeadline,
  checkIfObligatory
} from '../../IdeaForm/helpers'

export const validationSchema = t =>
  yup.object({
    activities: yup
      .string()
      .max(
        500,
        t('idea-details.form.validation.tooLongMessage', { count: 500 })
      ),
    ideaState: yup.string(),
    challenge: yup.string(),
    solution: yup.string(),
    tags: yup.array(),
    assignee: yup
      .mixed()
      .test(
        'checkIfAssineeObligatory',
        `${t('idea-details.form.validation.chooseAssignee')}`,
        (value, state) =>
          checkIfObligatory(value, state, obligatoryStatusesForAssignee)
      ),
    deadline: yup
      .mixed()
      .test(
        'checkIfDeadlineObligatory',
        `${t('idea-details.form.validation.deadlineRequired')}`,
        (value, state) =>
          checkIfObligatory(value, state, obligatoryStatusesForDeadline)
      )
  })

export const getAssignee = user => {
  if (!user) {
    return
  }
  return {
    label: user.displayName,
    value: user.id
  }
}
