import React from 'react'
import { useTranslation } from 'react-i18next'

import { Kpis } from '../../Components/Admin'

const TagsAdmin = () => {
  const { t } = useTranslation()

  return (
    <>
      <h1 className='mt-2'>{t('admin.kpis.title')}</h1>

      <Kpis />
    </>
  )
}

export default TagsAdmin
