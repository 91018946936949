import React from 'react'
import { PropTypes } from 'prop-types'

import Picker from '../../Common/Picker'

const MultiSelect = ({
  field,
  label,
  form: { setFieldValue, setFieldTouched },
  options
}) => {
  const emptyValue = []

  const handleChange = value => {
    setFieldValue(field.name, value || emptyValue)
  }

  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }

  // ab - filter option with empty value ('') as clear select works the same way
  const filteredOptions = options?.filter(item => !!item.value)

  return (
    <Picker
      options={filteredOptions}
      name={field.name}
      value={field.value}
      label={label}
      handleBlur={handleBlur}
      handleChange={handleChange}
      isMulti
      className='tags-picker'
    />
  )
}

MultiSelect.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  )
}

export default MultiSelect
