import React from 'react'
import { useField } from 'formik'

import Select, { selectSizes } from '../../../Common/Picker'
import { useData } from '../../../../hooks'
import { dataTypes } from '../../../../configurations'
import {
  getTagCategoriesList,
  getTagCategoriesListUrl,
  isFetchingTagCategories
} from '../../../../selectors'
import actions from '../../../../state/actions/data'

const CategoryPickerWithFormik = ({ ...props }) => {
  const [field, meta, helpers] = useField(props)

  const { data } = useData(
    dataTypes.tagCategories.name,
    null,
    getTagCategoriesListUrl,
    getTagCategoriesList,
    actions.requestPreInit,
    isFetchingTagCategories
  )

  const categoryOptions =
    data?.map(category => ({
      label: category.name,
      value: category.id
    })) ?? []

  const { setValue } = helpers

  const handleChange = selectedOption => {
    setValue(selectedOption.value)
  }

  const selectedCategory = categoryOptions.find(
    option => option.value === field.value
  )

  const hasError = meta.touched && meta.error

  return (
    <>
      <Select
        {...field}
        {...props}
        value={selectedCategory}
        options={categoryOptions}
        handleChange={handleChange}
        size={selectSizes.small}
      />

      {hasError && (
        <div className='vf-utility-typo--error mt-1'>{meta.error}</div>
      )}
    </>
  )
}

export default CategoryPickerWithFormik
