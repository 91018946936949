// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import CommentField from './CommentField'
import { VfButton } from '../../DesignSystem/Buttons'
import { SubmitButton } from '../../Common'
import { useSelector } from 'react-redux'
import { getUrlFromConfig } from '../../../selectors'

const CommentForm = ({
  className = '',
  handleSubmit,
  handleCancelClick,
  initialValues
}) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    content: yup.string().required(t('forms.comment.required'))
  })

  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'))

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}>
      {({ isSubmitting, dirty, values }) => {
        return (
          <Form className={`mt-2 ${className}`}>
            <Field
              name='content'
              component={CommentField}
              t={t}
              rawUrl={profileSearchUrl}
            />
            <SubmitButton
              button={
                <VfButton className='mt-2' text={t('forms.comment.button')} />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting}
            />
            {!!handleCancelClick && (
              <VfButton
                color='secondary'
                className='mt-2 ml-2'
                text={t('common.cancel')}
                onClick={handleCancelClick}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

CommentForm.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancelClick: PropTypes.func,
  initialValues: PropTypes.object
}

export default CommentForm
