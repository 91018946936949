// @ts-check
import React from 'react'
import PropTypes from 'prop-types'

import './switch-buttons.scss'

export const SwitchButton = ({ item, name, buttonSelected, handleChange }) => {
  const { label, value } = item
  const id = value
  const isSelected = buttonSelected === value

  const getClassName = () => {
    let className = 'switch_buttons__item'

    if (isSelected) {
      className += ' active'
    }

    return className
  }

  return (
    <div className={getClassName()}>
      <input
        type='radio'
        id={id}
        name={name}
        value={value}
        onClick={() => handleChange(value)} // need this to handle same item click
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

SwitchButton.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  buttonSelected: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string
}

const SwitchButtons = ({
  buttons: items = [],
  handleButtonChange = () => {},
  defaultButtonSelected = '',
  buttonSelected = '',
  name,
  ButtonTemplate = SwitchButton
}) => {
  const isButtonSelected =
    buttonSelected === '' ? defaultButtonSelected : buttonSelected

  return (
    <div className='switch_buttons'>
      {items.map((item, index) => {
        return (
          <ButtonTemplate
            key={index}
            item={item}
            handleChange={handleButtonChange}
            buttonSelected={isButtonSelected}
            name={name}
          />
        )
      })}
    </div>
  )
}

SwitchButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  handleButtonChange: PropTypes.func,
  defaultButtonSelected: PropTypes.string,
  buttonSelected: PropTypes.string,
  name: PropTypes.string,
  ButtonTemplate: PropTypes.elementType
}

export default SwitchButtons
