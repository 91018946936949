// @ts-check
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDrop } from 'react-dnd'
import Item from './Item'
import { useData, useLanguage } from '../../hooks'
import {
  getIdeasAdditionalInfo,
  getIdeasList,
  getIdeasListUrl,
  getIdeasPaginationSetup,
  getIsIdeasFetching
} from '../../selectors'
import actions from '../../state/actions/data'
import { useSelector } from 'react-redux'

/** @typedef {import('../../swagger/types').IdeasMapResource} IdeasMapResource  */
/** @typedef {import('../../swagger/types').IdeaResource} IdeaResource  */
/** @typedef {import('../../swagger/types').IdeaStates} IdeaStates  */

/**
 * @param {Object} props
 * @param {IdeaResource[]} props.items
 * @param {string} props.filterUrl
 * @param {(item: IdeaResource) => void} props.editIdea
 * @returns {JSX.Element}
 */
const List = ({ items, filterUrl, editIdea }) => {
  return (
    <>
      {items.map(item => (
        <Item
          key={item.id}
          item={item}
          filterUrl={filterUrl}
          editIdea={editIdea}
        />
      ))}
    </>
  )
}

List.propTypes = {
  items: PropTypes.array.isRequired,
  filterUrl: PropTypes.string.isRequired,
  editIdea: PropTypes.func.isRequired
}

const Header = ({ text = '' }) => {
  return <p className='kanban-board__header'>{text}</p>
}

// helper
/**
 * @param {URLSearchParams} queryParams
 * @returns {Object | null} - object with key value pairs or null if no query params
 */
export const queryParamsToObject = queryParams => {
  if (!queryParams || !queryParams.size) {
    return null
  }
  const obj = {}
  for (const [key, value] of queryParams) {
    obj[key] = value
  }

  return obj
}

/**
 * @param {Object} props
 * @param {{labelTKey: string, apiValue: string}} props.ideaState
 * @param {(item: IdeaResource) => void} props.editIdea
 * @returns {JSX.Element}
 */
const ListContainer = ({ ideaState, editIdea }) => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const { labelTKey, apiValue: status } = ideaState
  const [page, setPage] = useState(0)
  const resetPage = () => setPage(0)
  const incrementPage = () => setPage(page + 1)

  // Handle query params
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryParamsObj = queryParamsToObject(queryParams)

  const containsText = queryParams.get('q') || ''
  const hasQueryParams = !!queryParamsObj || !!containsText

  const dataSelector = hasQueryParams
    ? getIdeasList
    : key => state => {
        return Object.values(state.dataCommon.ideas.items).filter(
          item => item.ideaState === status
        )
      }

  // Setup DnD
  const [{ canDrop, isOver, item: dndItem, didDrop, dropResult }, drop] =
    useDrop(() => ({
      accept: 'box',
      drop: sourceItem => {
        return { name: status }
      },
      collect: monitor => {
        return {
          isOver: monitor.isOver(), // true if there is a drag operation in progress, and the pointer is currently hovering over the owner.
          canDrop: monitor.canDrop(),
          item: monitor.getItem(), // currently dragged item
          didDrop: monitor.didDrop(), // true if the item was dropped
          itemType: monitor.getItemType(), // type of the currently dragged item
          dropResult: monitor.getDropResult() // result of the drop operation
        }
      },
      canDrop: item => {
        return item.ideaState !== status
      }
    }))

  // #region Get data from the store / api
  const urlParams = {
    containsText,
    lng,
    page,
    pageSize: 10,
    sort: 'LAST_PUBLISHED',
    status,
    ...queryParamsObj
  }

  /** @type {{data: IdeaResource[], url: string, isFetching: boolean}} */
  const { url, isFetching } = useData(
    'ideas',
    { ...urlParams },
    getIdeasListUrl,
    dataSelector,
    actions.requestPreInit,
    getIsIdeasFetching
  )

  const [urls, setUrls] = useState([url])

  // On pageNumber change need to add new url to urls array to display new data with the old ones.
  useEffect(() => {
    if (!urls.includes(url)) {
      setUrls([...urls, url])
    }
  }, [url])

  // On filter change need to reset pageNumber and urls to display new data from first page.
  useEffect(() => {
    setUrls([url])
    resetPage()
  }, [location.search])

  // get all filters (filter contains needed ideasIds)
  const fetchedFilters = useSelector(state => state.dataCommon.ideas.filters)

  // from related fitler get ideasIds
  const ideasIds = urls
    .map(url => fetchedFilters[url])
    .reduce((acc, val = []) => {
      return [...acc, ...val]
    }, [])

  // get all ideas (full object )
  const fetchedIdeas = useSelector(state => state.dataCommon.ideas.items)

  // get ideas based on ideasIds
  const ideas = ideasIds.map(id => fetchedIdeas[id])

  // #endregion Get data from the store / api

  // #region totalElements

  // get number of items from the store / api
  const ideasInfo = useSelector(getIdeasAdditionalInfo(url)) || {
    totalElements: '...'
  }
  const { totalElements } = ideasInfo

  // using state in here to update totalItems on Dnd
  const [totalItems, setTotalItems] = useState(totalElements)

  // initialize totalItems with data from store / api
  useEffect(() => {
    setTotalItems(totalElements)
  }, [totalElements])

  // update totalItems on Dnd
  useEffect(() => {
    const isSourceList = dndItem?.ideaState === status // list that is being dragged from
    const isTargetList = status === dropResult?.name // list that is being dragged to

    if (didDrop && dndItem && isSourceList) {
      setTotalItems(totalItems - 1)
    }
    if (didDrop && isTargetList) {
      setTotalItems(totalItems + 1)
    }
  }, [didDrop])

  // #endregion totalElements

  const paginationSetup = useSelector(getIdeasPaginationSetup(url))
  const { totalPages } = paginationSetup

  // load more data on scroll
  const observerTarget = useRef(null)

  useEffect(() => {
    const { current } = observerTarget
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          incrementPage()
        }
      },
      { threshold: 1 }
    )

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [observerTarget.current])

  return (
    <div className='vf-col'>
      <Header text={`${t(labelTKey)}  (${totalItems})`} />

      <div
        ref={drop}
        className={`kanban-board__list-container ${
          canDrop ? 'dnd__can-drop' : ''
        } ${isOver ? 'dnd__is-over' : ''}`}>
        <List items={ideas} filterUrl={url} editIdea={editIdea} />
        {page + 1 < totalPages && <div ref={observerTarget} />}
        {isFetching && <div className='vf-col'>{t('common.loading')}</div>}
      </div>
    </div>
  )
}

ListContainer.propTypes = {
  ideaState: PropTypes.shape({
    labelTKey: PropTypes.string.isRequired,
    apiValue: PropTypes.string.isRequired
  }).isRequired,
  editIdea: PropTypes.func.isRequired
}

export default ListContainer
