// @ts-check
import { put, call, all } from 'redux-saga/effects'
import actionCreators from '../../actions/data'
import api, { apiPut } from '../../api'

function* updateData({
  url,
  data,
  dataType,
  dataToInvalidate,
  callback,
  refreshUrl
}) {
  try {
    yield put(actionCreators.updateDataInit())

    const response = yield call(api, url, apiPut(data))

    if (!!response.error) {
      yield put(actionCreators.updateDataFailed(response))
    } else if (response) {
      yield put(actionCreators.updateDataSuccess(response))

      if (Array.isArray(dataToInvalidate)) {
        const invalidations = dataToInvalidate.map(item =>
          put(actionCreators.invalidateData(item.dataType, item.context))
        )

        yield all(invalidations)
      }

      if (Array.isArray(refreshUrl)) {
        for (const url of refreshUrl) {
          yield put(actionCreators.requestPreInit(url, dataType))
        }
      }

      if (!!refreshUrl && !Array.isArray(refreshUrl)) {
        yield put(actionCreators.requestPreInit(refreshUrl, dataType))
      }
    }

    if (response && typeof callback === 'function') {
      yield call(callback, response)
    }
  } catch (err) {
    yield put(actionCreators.updateDataFailed(err))
    console.log('error', err)
  }
}

export default updateData
