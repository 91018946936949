import React from 'react'
import PropTypes from 'prop-types'

const OrderIcon = ({ property, sortConfig }) => {
  const getOrderIcon = () =>
    sortConfig?.sortBy === property && sortConfig?.order === 'desc' ? '↑' : '↓'

  return (
    <span
      className='order-icon'
      style={{
        visibility: sortConfig?.sortBy !== property ? 'hidden' : 'visible'
      }}>
      {getOrderIcon()}
    </span>
  )
}

const Header = ({
  className = '',
  listConfig = {},
  handleSort = () => {},
  sortConfig
}) => {
  const { gridTemplateColumns = '', attributes = [] } = listConfig

  return (
    <div className={`header-list ${className}`} style={{ gridTemplateColumns }}>
      {attributes.map(attr =>
        attr.sortable ? (
          <div
            key={attr.propName}
            style={attr.headerStyle}
            onClick={() => handleSort(attr.propName)}
            className='header-list--sortable'
            title={attr.label}>
            {attr.label}

            <OrderIcon
              key={attr.propName}
              property={attr.propName}
              sortConfig={sortConfig}
            />
          </div>
        ) : (
          <div key={attr.propName} style={attr.headerStyle} title={attr.label}>
            {attr.label}
          </div>
        )
      )}
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  listConfig: PropTypes.object,
  handleSort: PropTypes.func,
  sortConfig: PropTypes.object
}

OrderIcon.propTypes = {
  sortConfig: PropTypes.object,
  property: PropTypes.string
}

export default Header
