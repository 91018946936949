// @ts-check

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { customStyles } from './MSGraphSearch'

const DropDown = ({
  field,
  label,
  form: { setFieldValue, errors, touched },
  form,
  options = [],
  onChange = (a, b) => {},
  ...other
}) => {
  const [defaultLabel, setDefaultLabel] = useState('Select option')

  useEffect(() => {
    const optionSelected = options.find(
      item => item.value === field.value
    )?.label

    if (optionSelected !== undefined) {
      setDefaultLabel(optionSelected)
    } else {
      setDefaultLabel(options[0]?.label)
    }
  }, [options, field.value])

  const handleChange = item => {
    if (!item) {
      onChange(form, '')
      setFieldValue(field.name, '')
      return
    }

    const { value } = item
    onChange(form, value)
    setFieldValue(field.name, value)
  }

  const hasError = !!errors[field.name] && touched[field.name]

  return (
    <>
      {label && <label className='my-2'>{label}</label>}
      <div className='vf-input-container w-100'>
        <div className='vf-input' style={{ padding: '5px 10px' }}>
          <Select
            value={{ value: field.value, label: defaultLabel }}
            onChange={handleChange}
            options={options}
            isClearable
            styles={customStyles}
            {...other}
          />
        </div>
      </div>

      {hasError && (
        <div className='vf-utility-typo--error mt-2'>{errors[field.name]}</div>
      )}
    </>
  )
}

DropDown.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func
}

export default DropDown
