// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getAuthorFullName } from '../../../helpers/author'
import { getChallengeTheme } from '../../../helpers/color'
import { dateLocal } from '../../../helpers/date'

import {
  StarsPainter,
  LikesPainter,
  CommentsSummary,
  ViewsPainter,
  IdeaLink
} from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { icons } from '../../../assets/icons'

export const LargeItem = ({
  item,
  showViews = false,
  toggleEditForm = () => {}
}) => {
  const { t } = useTranslation()

  const {
    id,
    title,
    localizedChallengeInfo = {},
    published = '',
    author,
    description,
    starsCount = 0,
    commentsCount = 0,
    likesCount = 0,
    numberOfViews = 0,
    challenge: { id: challengeId },
    ideaState
  } = item

  const colors = getChallengeTheme(challengeId)

  return (
    <div className='vf-col-12 my-2'>
      <IdeaLink id={id} className='vf-card mb-0'>
        <div
          className='vf-row vf-card__body ideas-list__item px-2'
          style={{ borderColor: colors.bar }}>
          <div className='vf-col-lg-9'>
            <p className='ideas-list__item__top-info mb-0'>
              <span className='mr-1'>
                {t('common.challenge')}{' '}
                <b>
                  {localizedChallengeInfo.title ||
                    localizedChallengeInfo.titles?.EN}
                </b>
              </span>
            </p>

            {!!published && (
              <p className='ideas-list__item__top-info mb-0'>
                <span>
                  {t('common.published')} <b>{dateLocal(published)}</b>
                </span>
              </p>
            )}

            <p className='ideas-list__item__top-info mb-1'>
              <span>
                {t('common.status')} <b>{t('ideaStates.' + ideaState.toLowerCase())}</b>
              </span>
            </p>

            <h4 className='my-1'>{title}</h4>

            <p className='ideas-list__item__description mb-2'>
              {`${description.slice(0, 300)}...`}
            </p>
          </div>

          <div className='vf-col-lg-3 mb-2'>
            {author && (
              <p className='ideas-list__item__top-info my-0'>
                {t('common.contributor')} <b>{getAuthorFullName(author)}</b>
              </p>
            )}

            <div className='idea-opinions'>
              {showViews && <ViewsPainter count={numberOfViews} />}
              <StarsPainter rating={starsCount} />
              <LikesPainter count={likesCount} />
              <CommentsSummary count={commentsCount} t={t} />
              <VfButton
                className='mt-2'
                onClick={e => {
                  e.preventDefault()
                  toggleEditForm(item)
                }}
                color='tertiary'
                text={t('common.edit')}
              />
            </div>
          </div>
        </div>
      </IdeaLink>
    </div>
  )
}

LargeItem.propTypes = {
  item: PropTypes.object,
  showViews: PropTypes.bool,
  toggleEditForm: PropTypes.func
}

export const SmallItem = ({ item, toggleEditForm = () => {} }) => {
  const { t } = useTranslation()

  const {
    id,
    title,
    challenge: { id: challengeId }
  } = item

  const colors = getChallengeTheme(challengeId)

  return (
    <div className='vf-col-12 my-2'>
      <div className='vf-row'>
        <div className='vf-col-lg-9'>
          <IdeaLink id={id} className='vf-card mb-0'>
            <div
              className='vf-row vf-card__body ideas-list__item px-2'
              style={{ borderColor: colors.bar }}>
              <h4 className='mb-0 pl-1'>{title}</h4>
            </div>
          </IdeaLink>
        </div>
        <div className='vf-col-lg-3'>
          <div
            onClick={e => {
              e.preventDefault()
              toggleEditForm(item)
            }}
            className='edit-button__icon'>
            <img src={icons.Edit} alt={t('common.edit')} />
          </div>
        </div>
      </div>
    </div>
  )
}

SmallItem.propTypes = {
  item: PropTypes.object,
  toggleEditForm: PropTypes.func
}
