import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../Common'
import Item from './Item'
import InlineList from '../../Common/InlineList'
import CreateTag from './Form/Create'
import { useData } from '../../../hooks'
import { dataTypes } from '../../../configurations'
import {
  getTagsListUrl,
  getTagsList,
  isFetchingTags,
  getTagCategoriesListUrl,
  getTagCategoriesList,
  isFetchingTagCategories
} from '../../../selectors'
import actions from '../../../state/actions/data'
import {
  getSortedTags,
  getTagsByCategory,
  getUncategorizedTags
} from './helpers'
import {
  getCategoriesSortedByName,
  getFirstCategory,
  getOtherCategories
} from '../TagCategories/helpers'

const ListContainer = () => {
  const { t } = useTranslation()
  const { data, isFetching } = useData(
    dataTypes.tags.name,
    null,
    getTagsListUrl,
    getTagsList,
    actions.requestPreInit,
    isFetchingTags
  )

  const { data: tagCategories } = useData(
    dataTypes.tagCategories.name,
    null,
    getTagCategoriesListUrl,
    getTagCategoriesList,
    actions.requestPreInit,
    isFetchingTagCategories
  )

  const [tags, setTags] = useState([])

  useEffect(() => {
    if (!!data) {
      setTags(data)
    }
  }, [data])

  const createTagOptimisticUI = newTag => setTags(prev => [...prev, newTag])

  const editTagOptimisticUI = tag => {
    const newTags = tags.map(item => {
      if (item.id === tag.id) {
        return tag
      }

      return item
    })

    setTags(newTags)
  }

  const deleteTagOptimisticUI = tagId => {
    const newTags = tags.filter(item => item.id !== tagId)

    setTags(newTags)
  }

  if (!tags.length && isFetching && !tagCategories) {
    return <Loading />
  }

  const firstCategory = getFirstCategory(tagCategories)
  const otherCategories = getOtherCategories(tagCategories)

  const sortedCategories = getCategoriesSortedByName(otherCategories || [])

  const getSortedTagsForCategory = categoryId =>
    getSortedTags(getTagsByCategory(tags, categoryId))

  const uncategorizedTags = getSortedTags(getUncategorizedTags(tags))

  return (
    <>
      {!!firstCategory && (
        <div>
          <h4>{firstCategory.name}</h4>

          <CreateTag
            categoryId={firstCategory.id}
            createTagOptimisticUI={createTagOptimisticUI}
          />

          <InlineList
            items={getTagsByCategory(tags, firstCategory?.id)}
            Item={Item}
            editTagOptimisticUI={editTagOptimisticUI}
            deleteTagOptimisticUI={deleteTagOptimisticUI}
          />
        </div>
      )}

      {sortedCategories?.map(category => (
        <div key={category.id}>
          <h4 className='mt-3'>{category.name}</h4>

          <CreateTag
            categoryId={category.id}
            createTagOptimisticUI={createTagOptimisticUI}
          />

          <InlineList
            items={getSortedTagsForCategory(category.id)}
            Item={Item}
            editTagOptimisticUI={editTagOptimisticUI}
            deleteTagOptimisticUI={deleteTagOptimisticUI}
          />
        </div>
      ))}

      {!!uncategorizedTags.length && (
        <>
          <h3 className='mt-3'>{t('admin.tags.titles.uncategorized')}</h3>

          <InlineList
            items={uncategorizedTags}
            Item={Item}
            editTagOptimisticUI={editTagOptimisticUI}
            deleteTagOptimisticUI={deleteTagOptimisticUI}
          />
        </>
      )}
    </>
  )
}

export default ListContainer
