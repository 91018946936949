import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ActionButton from '../../../Common/ActionButton'
import { ReactComponent as ActivateIcon } from '../../../../assets/icons/add_circle.svg'
import { handleEdit } from '../Form/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getTagCategoriesListUrl, getTagsListUrl } from '../../../../selectors'

const ActivateBtn = ({ item, editCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const rawUrl = useSelector(getTagsListUrl)
  const tagCategoriesContext = useSelector(getTagCategoriesListUrl)

  const handleActivate = () =>
    handleEdit(
      item,
      dispatch,
      rawUrl,
      tagCategoriesContext,
      editCallback
    )({
      id: item.id,
      name: item.name,
      categoryId: item.categoryId,
      active: true
    })

  return (
    <ActionButton
      title={t('admin.tags.buttons.activate')}
      onClick={handleActivate}>
      <ActivateIcon height={16} />
    </ActionButton>
  )
}

ActivateBtn.propTypes = {
  item: PropTypes.object,
  editCallback: PropTypes.func
}

export default ActivateBtn
