// @ts-check
import { put, call, takeEvery, all } from 'redux-saga/effects'
import { updateData, invalidateData, updateFilters } from '../actions/dnd'
import api, { apiPut } from '../api'
/**
 * @param {Object} action
 * @param {Object} action.updatedItem - item to be replaced in store
 * @param {Object} action.apiItem - item to be sent to API
 * @param {string} action.url - URL to send API request to
 * @param {string[]} action.dataToInvalidate - data to invalidate
 * @param {{source: string, target: string}} action.filterUrls - filterUrls to move item beetween
 */
function* dndUpdateData({
  updatedItem,
  apiItem,
  url,
  dataToInvalidate,
  filterUrls
}) {
  try {
    yield put(updateData(updatedItem))

    if (filterUrls) {
      yield put(updateFilters('ideas', filterUrls, updatedItem.id))
    }

    const response = yield call(api, url, apiPut(apiItem))

    if (!response.error) {
      if (Array.isArray(dataToInvalidate)) {
        const invalidations = dataToInvalidate.map(item =>
          put(invalidateData(item.dataType, item.context))
        )

        yield all(invalidations)
      }
    }
  } catch (error) {
    console.log('SAGA ERROR', error)
  }
}

export default function* dndSaga() {
  yield takeEvery('DND/UPDATE_INIT', dndUpdateData)
}
