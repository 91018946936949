import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'

import DateRangePicker from '../SearchIdeas/DateRangePicker'
import { SubmitButton } from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { ResetButton } from '../SearchIdeas/Form'

const DateRangeForm = ({ setDateRange, isLoading, lng }) => {
  const { t } = useTranslation()

  const handleSubmit = values => {
    setDateRange(values?.dateRange ?? null)
  }

  return (
    <Formik initialValues={{ dateRange: null }} onSubmit={handleSubmit}>
      {({ dirty, resetForm }) => {
        return (
          <Form>
            <div className='vf-row my-3'>
              <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
                <Field
                  name='dateRange'
                  component={DateRangePicker}
                  language={lng}
                />
              </div>

              <div
                className='vf-col-sm-12 vf-col-md-6 vf-col-lg-8'
                style={{
                  display: 'flex',
                  gap: 10,
                  alignItems: 'flex-end'
                }}>
                <SubmitButton
                  button={<VfButton text={t('common.apply')} color='primary' />}
                  inProgress={isLoading}
                  inProgressText={t('common.searching')}
                  disabled={isLoading}
                />

                <ResetButton
                  t={t}
                  disabled={isLoading || !dirty}
                  handleClick={() => {
                    resetForm()
                    handleSubmit()
                  }}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

DateRangeForm.propTypes = {
  setDateRange: PropTypes.func,
  isLoading: PropTypes.bool,
  lng: PropTypes.string
}

export default DateRangeForm
