// @ts-check
import React from 'react'
import { useTranslation } from 'react-i18next'
import DropDown from '../DropDownv2'
import { useData, useLanguage } from '../../../hooks'
import actions from '../../../state/actions/data'
import {
  getChallengesList,
  getChallengesListUrl,
  getIsChallengesFetching
} from '../../../selectors'

const TeamsPicker = props => {
  const lng = useLanguage()
  const { t } = useTranslation()
  const params = { state: 'ACTIVE', lng }
  const { data: items } = useData(
    'challenges',
    params,
    getChallengesListUrl,
    getChallengesList,
    actions.requestPreInit,
    getIsChallengesFetching
  )

  const uiItems = items.map(({ id, title }) => ({
    value: id,
    label: title
  }))

  return (
    <DropDown
      options={[{ value: '', label: t('common.all') }, ...uiItems]}
      {...props}
    />
  )
}

export default TeamsPicker
