import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { getUsersPostUrl, getUrlFromConfig } from '../../../selectors'
import {
  Checkbox,
  MSGraphSearch,
  Message,
  SubmitButton,
  TextInput
} from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import actions from '../../../state/actions/data'
import {
  formModes,
  validationSchemaCreate,
  validationSchemaEdit
} from './helpers'

import './form.scss'

const FormComponent = ({ message, mode, ...formikProps }) => {
  const { t } = useTranslation()
  const { dirty, isSubmitting } = formikProps

  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'))

  return (
    <Form>
      <Field
        name='userName'
        label={t('admin.users.labels.modal.userName')}
        component={mode === formModes.create ? MSGraphSearch : TextInput}
        disabled={mode === formModes.edit}
        rawUrl={profileSearchUrl}
        isMulti={false}
      />
      <Field
        name='expertUser'
        label={t('admin.users.labels.modal.expertUser')}
        component={Checkbox}
      />

      {mode !== formModes.create && (
        <Field
          name='activeUser'
          label={t('admin.users.labels.modal.activeUser')}
          component={Checkbox}
        />
      )}

      <SubmitButton
        button={<VfButton text={t('common.submit')} className='mt-2' />}
        inProgress={isSubmitting}
        inProgressText={t('common.submitting')}
        disabled={!dirty || isSubmitting}
      />

      {message && <Message type={message.type}>{message.text}</Message>}
    </Form>
  )
}

FormComponent.propTypes = {
  message: PropTypes.object,
  mode: PropTypes.string,
  profileSearchUrl: PropTypes.string,
  handleMSGraphSearch: PropTypes.func
}

const FormContainer = ({ initialValues, closePanel, mode, refreshUrl }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const postUpdateUrl = useSelector(getUsersPostUrl)

  const [message, setMessage] = useState(null)

  const handleSubmit = (values, { setSubmitting }) => {
    const dataForPost = {
      ...values,
      id: values.id ? values.id : values.userName.value
    }
    delete dataForPost.userName

    const dataToInvalidate = [{ dataType: 'allUsers', context: refreshUrl }]
    const callback = res => {
      if (!!res.error) {
        setMessage({
          type: 'error',
          text: res.message || t('messages.somethingWentWrong')
        })
      } else {
        setMessage({
          type: 'success',
          text: t('messages.success')
        })
        closePanel()
      }
      setSubmitting(false)
    }

    dispatch(
      actions.createDataPreInit(
        postUpdateUrl,
        dataForPost,
        'allUsers',
        dataToInvalidate,
        callback,
        refreshUrl
      )
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        mode === formModes.create
          ? validationSchemaCreate(t)
          : validationSchemaEdit(t)
      }
      onSubmit={handleSubmit}>
      {formikProps => (
        <FormComponent message={message} mode={mode} {...formikProps} />
      )}
    </Formik>
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  closePanel: PropTypes.func,
  mode: PropTypes.string,
  refreshUrl: PropTypes.object
}

export default FormContainer
