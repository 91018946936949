// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import actions from '../../../state/actions/data'
import filesActions from '../../../state/actions/files'
import { removeDocumentsPreInit } from '../../../state/actions/other'
import { SubmitButton } from '../../Common'
import { VfButton, VfLinkButton } from '../../DesignSystem/Buttons'
import Form from './Form'
import { mergeUrlWithParams } from '../../../helpers/url'
import { useLanguage } from '../../../hooks'
import { getAttachmentsUploadUrl } from '../../../selectors'
import { formatAssignee } from '../Expert/IdeaForm/EditForm'
import { getDDStatuses } from '../../../configurations/ideaStates'
import { formatTagsForApi, validationSchema } from './helpers'
import { formatTagForUi } from '../../Common/TagsPicker'
import {
  getFirstCategoryTags,
  getTagsWithoutFirstCategory
} from '../../Admin/Tags/helpers'

const EditForm = ({ idea, url, notify, hideForm }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const lng = useLanguage()
  const { assignee, deadline, ideaState } = idea
  const statuses = getDDStatuses(t)
  const minDate = !deadline ? new Date() : new Date(deadline)

  const formatCoCreators = coCreators =>
    coCreators.map(item => ({
      value: item.id,
      label: item.displayName
    }))

  const initialValues = {
    assignee: formatAssignee(assignee),
    deadline: !deadline ? '' : new Date(deadline),
    ideaState: ideaState || 'NEW',
    title: idea.title || '',
    description: idea.description || '',
    activities: idea.activities || '',
    ideaValue: idea.ideaValue || '',
    targetGroup: idea.targetGroup || '',
    requiredResources: idea.requiredResources || '',
    summaryInEnglish: idea.summaryInEnglish || '',
    solution: idea.solution || '',
    coCreators: idea.coCreators ? formatCoCreators(idea.coCreators) : [],
    country: idea.country || '',
    attachments: idea.documents || [],
    tags: getTagsWithoutFirstCategory(idea.tags).map(formatTagForUi) || [],
    firstCategoryTags: getFirstCategoryTags(idea.tags).map(formatTagForUi) || []
  }

  const formatDataForApiCall = (values, { challenge }) => ({
    title: values.title,
    description: values.description,
    ideaValue: values.ideaValue,
    activities: values.activities,
    solution: values.solution,
    challengeId: challenge.id,
    createPublished: true,
    deadline: values.deadline || null,
    assignee: values.assignee.value || null,
    tags: formatTagsForApi(values),
    ideaState: values.ideaState,
    status: 'PUBLISHED'
  })
  const rawUploadUrl = useSelector(getAttachmentsUploadUrl)

  const updateIdea = (values, formActions) => {
    const data = formatDataForApiCall(values, idea)

    const attachmentsToUpload = values.attachments.filter(item => !item.fileUrl)
    const attachmentsToRemove = idea.documents.filter(
      item => !values.attachments.find(el => el.id === item.id)
    )

    delete data.attachments
    delete data.firstCategoryTags

    const ideaUrl = idea._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]

    const callback = (res, onSuccess = () => {}) => {
      if (!!res?.error) {
        notify('error')
        formActions.setSubmitting(false)
      } else {
        onSuccess()
      }
    }

    const finalCallback = res => {
      const onSuccess = () => {
        notify('success')
        hideForm()
      }
      callback(res, onSuccess)
    }

    const removeDocuments = documents => {
      const rawUrl = idea._links['remove-document'].href
      const data = documents.map(item => ({ documentId: item.id }))
      dispatch(removeDocumentsPreInit(rawUrl, data, finalCallback))
    }

    const removeFiles = () => {
      if (attachmentsToRemove.length > 0) {
        removeDocuments(attachmentsToRemove)
      } else {
        finalCallback()
      }
    }

    const uploadFiles = () => {
      if (attachmentsToUpload.length > 0) {
        const uploadUrl = mergeUrlWithParams({
          ideaId: idea.id
        })(rawUploadUrl)

        dispatch(
          filesActions.uploadRequestPreInit(
            uploadUrl,
            attachmentsToUpload,
            res => callback(res, removeFiles)
          )
        )
      } else {
        removeFiles()
      }
    }

    // update idea => upload new files => remove deleted files
    const updateIdeaCallback = res => {
      callback(res, uploadFiles)
    }

    dispatch(
      actions.updateDataPreInit(
        url,
        data,
        null,
        dataToInvalidate,
        updateIdeaCallback
      )
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateIdea}
      enableReinitialize // ab - sometimes idea data is refetching, form needs to be updated
      validationSchema={() => validationSchema(t, true)}>
      {({ handleSubmit, isSubmitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Form t={t} edit statuses={statuses} minDate={minDate} />

          <div className='vf-row my-3'>
            <VfLinkButton
              to={`/improvement-details/${idea.id}`}
              text={t('common.cancel')}
              size='large'
              color='secondary'
              className='vf-col m-1'
            />

            <SubmitButton
              button={
                <VfButton
                  className='vf-col m-1'
                  text={t('common.submit')}
                  size='large'
                />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

EditForm.propTypes = {
  idea: PropTypes.object,
  url: PropTypes.string,
  notify: PropTypes.func,
  hideForm: PropTypes.func
}

export default EditForm
