import React, { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '../Components/DesignSystem/Modal'
import { VfButton } from '../Components/DesignSystem/Buttons'

const ModalContext = createContext()

export default ModalContext

export const ConfirmModalContextProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [modalProps, setModalProps] = useState({
    title: 'Are you sure?',
    content: () => <></>,
    onCancel: () => {},
    onConfirm: () => {}
  })

  const toggleModal = () => setIsVisible(!isVisible)

  const showModal = useCallback(options => {
    setModalProps(prev => ({
      ...prev,
      ...options
    }))
    setIsVisible(true)
  }, [])

  return (
    <ModalContext.Provider value={showModal}>
      {children}

      <Modal hidden={!isVisible} closeModal={toggleModal}>
        <div className='mb-2'>
          <h4>{modalProps.title}</h4>

          {modalProps.content}

          <footer
            style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
            <VfButton
              text='Cancel'
              color='tertiary'
              onClick={() => {
                modalProps.onCancel()
                toggleModal()
              }}
              data-test='modal-cancel'
            />
            <VfButton
              text='Confirm'
              onClick={() => {
                modalProps.onConfirm()
                toggleModal()
              }}
              data-test='modal-confirm'
            />
          </footer>
        </div>
      </Modal>
    </ModalContext.Provider>
  )
}

ConfirmModalContextProvider.propTypes = {
  children: PropTypes.node
}
