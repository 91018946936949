// @ts-check

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const colors = {
  primary: 'primary',
  secondary: 'secondary'
}

const sizes = {
  regular: 'regular',
  large: 'large'
}

/* #region */
const RouterLink = ({ text, ...other }) => <Link {...other}>{text}</Link>

RouterLink.propTypes = {
  text: PropTypes.string
}
/* #endregion */

/* #region */
const Anchor = ({ text, to, ...other }) => (
  <a href={to} {...other}>
    {text}
  </a>
)

Anchor.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string
}
/* #endregion */

const basicCssClasses = {
  color: {
    primary: 'vf-btn--primary',
    secondary: 'vf-btn--secondary'
  }
}

const outlineCssClasses = {
  color: {
    primary: 'vf-btn--outline-dark',
    secondary: 'vf-btn--outline-secondary'
  }
}

const VfLinkButton = ({
  outlined = false,
  text = '',
  color = colors.primary,
  className = '',
  to = '#',
  disabled = false,
  size = sizes.regular,
  ...other
}) => {
  const cssClasses = outlined ? outlineCssClasses : basicCssClasses

  const allClassNames = `vf-btn active ${className}
  ${color === colors.primary ? cssClasses.color.primary : ''}
  ${color === colors.secondary ? cssClasses.color.secondary : ''} ${
    disabled && 'disabled'
  } ${size === sizes.large ? 'vf-btn--lg' : ''}`

  const props = {
    className: allClassNames,
    to,
    text,
    role: 'button',
    'aria-pressed': 'true'
  }

  if (to.includes('tel') || to.includes('mailto')) {
    return <Anchor {...other} {...props} />
  }
  return <RouterLink {...other} {...props} />
}

VfLinkButton.propTypes = {
  outlined: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string
}

export default VfLinkButton
