// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import actions from '../../../state/actions/data'
import filesActions from '../../../state/actions/files'
import { SubmitButton } from '../../Common'
import { VfButton, VfLinkButton } from '../../DesignSystem/Buttons'
import Form from './Form'
import { useLanguage } from '../../../hooks'
import { mergeUrlWithParams } from '../../../helpers/url'
import { getDDStatuses } from '../../../configurations/ideaStates'
import { getAttachmentsUploadUrl } from '../../../selectors'
import { formatTagsForApi, validationSchema } from './helpers'

const CreateForm = ({ t, challengeId, url, notify, hideForm }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()
  const statuses = getDDStatuses(t)
  const rawUploadUrl = useSelector(getAttachmentsUploadUrl)
  const minDate = new Date()

  const initialValues = {
    title: '',
    description: '',
    ideaValue: '',
    targetGroup: '',
    solution: '',
    activities: '',
    attachments: [],
    assignee: '',
    ideaState: 'NEW',
    deadline: '',
    tags: [],
    firstCategoryTags: []
  }

  const createIdea = (values, formActions) => {
    const data = {
      ...values,
      challengeId: challengeId,
      createPublished: true,
      status: 'PUBLISHED',
      tags: formatTagsForApi(values),
      assignee: values.assignee?.value ?? null
    }

    delete data.asDraft
    delete data.attachments
    delete data.targetGroup
    delete data.firstCategoryTags

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'challenges' },
      { dataType: 'dashboard' }
    ]

    const callback = () => {
      notify('success')
      hideForm()
    }

    const uploadFiles = idea => {
      if (values.attachments.length > 0) {
        const uploadUrl = mergeUrlWithParams({
          ideaId: idea.id
        })(rawUploadUrl)

        dispatch(
          filesActions.uploadRequestPreInit(
            uploadUrl,
            values.attachments,
            callback
          )
        )
      } else {
        callback()
      }
    }

    const createIdeaCallback = res => {
      if (res.error) {
        notify('error')
        formActions.setSubmitting(false)
      } else {
        uploadFiles(res)
      }
    }

    // create idea => upload new files
    dispatch(
      actions.createDataPreInit(
        mergeUrlWithParams({ lng })(url),
        data,
        'ideas',
        dataToInvalidate,
        createIdeaCallback
      )
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createIdea}
      validationSchema={() => validationSchema(t)}>
      {({ handleSubmit, isSubmitting, setFieldValue, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Form t={t} edit={false} minDate={minDate} statuses={statuses} />

          <div className='vf-row my-3'>
            <VfLinkButton
              to={`/team-details/${challengeId}`}
              text={t('common.cancel')}
              size='large'
              color='secondary'
              className='vf-col m-1'
            />

            <SubmitButton
              button={
                <VfButton
                  className='vf-col m-1'
                  text={
                    !isSubmitting ? t('common.submit') : t('common.submitting')
                  }
                  size='large'
                />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

CreateForm.propTypes = {
  t: PropTypes.func,
  challengeId: PropTypes.string,
  url: PropTypes.string,
  notify: PropTypes.func,
  hideForm: PropTypes.func
}

export default CreateForm
