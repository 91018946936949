import React from 'react'
import { useTranslation } from 'react-i18next'

import ChallengesList from '../Components/Challenges'
import { VfLinkButton } from '../Components/DesignSystem/Buttons'
import { useProfile } from '../hooks'

const OurChallenges = () => {
  const { user } = useProfile()
  const { t } = useTranslation()

  const isExpert = !!user?.expertUser

  return (
    <>
      {isExpert && (
        <div className='mb-2'>
          <VfLinkButton
            to='/cms/team/create'
            text={t('team.addNewTeam')}
            color='secondary'
            className='mr-1'
            style={{ minWidth: 'auto' }}
          />
        </div>
      )}

      <ChallengesList
        state='ACTIVE,PUBLISHED'
        titleKey='titles.activeChallenges'
      />

      {/* <ChallengesList state='CLOSED' titleKey='titles.completedChallenges' />

      {isExpert && (
        <ChallengesList state='DRAFT' titleKey='titles.draftChallenges' />
      )} */}
    </>
  )
}

export default OurChallenges
