import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getCurrentUserId } from '../../auth'
import { getUserDetails } from '../../selectors'
import userIcon from '../../assets/icons/VF_user_rgb.svg'

/** @typedef {import('../../swagger/types').ProfileResource} ProfileResource */

const sizes = {
  small: 32,
  medium: 64,
  large: 120
}

/**
 * @param {Object} props
 * @param {ProfileResource} [props.author] - author object
 * @param {string} props.size - size of the avatar
 * @param {string} props.alt - alt text for the image
 * @returns {JSX.Element}
 */
const Avatar = ({ author, size, alt }) => {
  const [imageSrc, setImageSrc] = useState(userIcon)
  const photoUrl = author?._links?.photo.href || ''

  useEffect(() => {
    if (!!photoUrl) {
      setImageSrc(photoUrl)
    }
  }, [photoUrl])

  return (
    <img
      src={imageSrc}
      alt={alt}
      style={{ width: sizes[size], borderRadius: '50%' }}
      onError={() => setImageSrc(userIcon)}
    />
  )
}

Avatar.propTypes = {
  size: PropTypes.string,
  alt: PropTypes.string,
  author: PropTypes.object
}

Avatar.defaultProps = {
  size: 'small',
  alt: 'User Avatar',
  photoUrl: ''
}

export const AvatarMedium = props => <Avatar size='medium' {...props} />
export const AvatarLarge = props => <Avatar size='large' {...props} />
export const AvatarCurrentUser = () => {
  const author = useSelector(getUserDetails(getCurrentUserId()))
  return <Avatar size='small' author={author} />
}

export default Avatar
