export const UPLOAD_FILE_INIT = 'FILE/UPLOAD_INIT'

export const uploadRequestInit = () => ({
  type: UPLOAD_FILE_INIT
})

export const UPLOAD_FILE_PRE_INIT = 'FILE/UPLOAD_PRE_INIT'

/**
 * @param {Object} payload
 * @param {function} [callback]
 */

export const uploadRequestPreInit = (url, payload, callback) => ({
  type: UPLOAD_FILE_PRE_INIT,
  url,
  payload,
  callback
})

export const UPLOAD_FILE_SUCCEEDED = 'FILE/UPLOAD_SUCCEEDED'

/**
 * @param {Object} payload
 */

export const uploadSucceeded = payload => ({
  type: UPLOAD_FILE_SUCCEEDED,
  payload
})

export const UPLOAD_FILE_FAILED = 'FILE/UPLOAD_FAILED'

/**
 * @param {Object} payload
 */

export const uploadFailed = payload => ({
  type: UPLOAD_FILE_FAILED,
  payload
})

export const REMOVE_FILE_INIT = 'FILE/REMOVE_INIT'

export const removeRequestInit = () => ({
  type: REMOVE_FILE_INIT
})

export const REMOVE_FILE_PRE_INIT = 'FILE/REMOVE_PRE_INIT'

/**
 * @param {Object} payload
 * @param {function} [callback]
 */

export const removeRequestPreInit = (payload, callback) => ({
  type: REMOVE_FILE_PRE_INIT,
  payload,
  callback
})

export const REMOVE_FILE_SUCCEEDED = 'FILE/REMOVE_SUCCEEDED'

/**
 * @param {Object} payload
 */

export const removeSucceeded = (payload) => ({
  type: REMOVE_FILE_SUCCEEDED,
  payload
})

export const REMOVE_FILE_FAILED = 'FILE/REMOVE_FAILED'

/**
 * @param {Object} payload
 */

export const removeFailed = (payload) => ({
  type: REMOVE_FILE_FAILED,
  payload
})

export const actionTypes = {
  UPLOAD_FILE_INIT,
  UPLOAD_FILE_PRE_INIT,
  UPLOAD_FILE_SUCCEEDED,
  UPLOAD_FILE_FAILED,
  REMOVE_FILE_INIT,
  REMOVE_FILE_PRE_INIT,
  REMOVE_FILE_SUCCEEDED,
  REMOVE_FILE_FAILED
}

const actionCreators = {
  uploadRequestInit,
  uploadRequestPreInit,
  uploadSucceeded,
  uploadFailed,
  removeRequestInit,
  removeRequestPreInit,
  removeSucceeded,
  removeFailed
}

export default actionCreators
