import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikForm } from 'formik'
import { useTranslation } from 'react-i18next'

import actions from '../../../../state/actions/data'
import Form from './Form'
import { SubmitButton } from '../../../Common'
import { VfButton } from '../../../DesignSystem/Buttons'
import { useVfAlchemyScript, useLanguage, useData } from '../../../../hooks'
import { getEnvVariable } from '../../../../.env.custom'
import { getDDStatuses } from '../../../../configurations/ideaStates'
import {
  getChallengesListUrl,
  getChallengesList,
  getIsChallengesFetching
} from '../../../../selectors'
import { mergeUrlWithParams } from '../../../../helpers/url'
import { validationSchema } from './helpers'
import { formatTagForUi } from '../../../Common/TagsPicker'

import './form.scss'
import {
  getFirstCategoryTags,
  getTagsWithoutFirstCategory
} from '../../../Admin/Tags/helpers'
import { formatTagsForApi } from '../../IdeaForm/helpers'

export const formatAssignee = assignee => {
  if (!assignee.id) {
    return ''
  }
  return {
    value: assignee.id,
    label: `${assignee.firstName} ${assignee.lastName}`
  }
}

// TODO: this is temporary and should get url from proper _link from be
/**
 *
 * @param {string} ideaId
 * @returns
 */
export const tmpGetUrl = ideaId => {
  const rootapi = getEnvVariable('REACT_APP_API_ROOT')
  const rawUrl = `${rootapi}ideas{?ideaId}`
  const url = mergeUrlWithParams({ ideaId })(rawUrl)
  return url
}

const EditForm = ({ idea = {}, refreshUrl }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()
  const { t } = useTranslation()

  const {
    id,
    assignee,
    activities,
    deadline,
    tags,
    ideaState,
    challenge,
    solution
  } = idea || {}

  const { data: teams } = useData(
    'challenges',
    { state: 'ACTIVE', lng },
    getChallengesListUrl,
    getChallengesList,
    actions.requestPreInit,
    getIsChallengesFetching
  )

  useVfAlchemyScript(teams)

  const initialValues = {
    assignee: formatAssignee(assignee), // {value: 'user graph id', label: 'Witold Paprotny'}
    deadline: !deadline ? '' : new Date(deadline),
    firstCategoryTags: getFirstCategoryTags(tags).map(formatTagForUi) || [],
    tags: getTagsWithoutFirstCategory(tags).map(formatTagForUi) || [],
    ideaState: ideaState || 'NEW',
    activities: activities || '',
    challenge: challenge.id,
    solution: solution || ''
  }
  const minDate = !deadline ? new Date() : new Date(deadline)
  const statuses = getDDStatuses(t)

  const formatDataForApiCall = (values, { description, ideaValue, title }) => ({
    activities: values.activities,
    assignee: values.assignee.value || null,
    challengeId: values.challenge,
    createPublished: true,
    deadline: values.deadline,
    description,
    ideaState: values.ideaState,
    ideaValue,
    solution: values.solution,
    status: 'PUBLISHED',
    tags: formatTagsForApi(values),
    title
  })

  const [message, setMessage] = useState(null)

  const handleSubmit = (values, formActions) => {
    setMessage(null)
    const data = formatDataForApiCall(values, idea)
    const ideaUrl = idea._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext },
      { dataType: 'dashboard' }
    ]

    const callback = res => {
      if (!!res.error) {
        setMessage({
          type: 'error',
          text: t('messages.somethingWentWrong')
        })
      } else {
        setMessage({
          type: 'success',
          text: t('messages.success')
        })
      }

      formActions.setSubmitting(false)
    }

    // TODO: url should be taken from idea._links.update.href
    const url = tmpGetUrl(id)

    dispatch(
      actions.updateDataPreInit(
        url,
        data,
        'ideas',
        dataToInvalidate,
        callback,
        refreshUrl
      )
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormikForm>
          <Form t={t} statuses={statuses} minDate={minDate} teams={teams} />
          {!!message && (
            <div className={`vf-utility-typo--${message.type} mt-2`}>
              {message.text}
            </div>
          )}
          <SubmitButton
            button={
              <VfButton
                className='vf-col mt-2'
                text={t('common.submit')}
                size='large'
              />
            }
            inProgress={isSubmitting}
            inProgressText={t('common.submitting')}
            disabled={isSubmitting}
          />
        </FormikForm>
      )}
    </Formik>
  )
}

EditForm.propTypes = {
  idea: PropTypes.object,
  refreshUrl: PropTypes.string || PropTypes.array
}

export default EditForm
