import { actionTypes } from '../actions/other'

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGINATION_CONFIG:
      return { ...state, [action.payload.storeKey]: action.payload }
    case actionTypes.RESET_PAGINATION_CONFIG:
      delete state[action.payload]
      return state
    default:
      return state
  }
}
