import { combineReducers } from 'redux'
import config from './config'
import data from './data'
import dataCommon from './dataCommon'
import users from './users'
import notifications from './notifications'
import searchForm from './searchForm'
import paginationConfig from './paginationConfig'

const appReducer = combineReducers({
  config,
  data,
  dataCommon,
  users,
  notifications,
  searchForm,
  paginationConfig
})

export default appReducer
