import React from 'react'
import PropTypes from 'prop-types'

import './tooltip.scss'

export const colors = {
  green: 'green',
  blue: 'blue',
  red: 'red',
  solidBlue: 'solidBlue',
  solidGreen: 'solidGreen',
  solidRed: 'solidRed',
  solidYellow: 'solidYellow'
}

const colorClasses = {
  [colors.green]: '', // default
  [colors.blue]: 'vf-tooltip--blue',
  [colors.red]: 'vf-tooltip--red',
  [colors.solidGreen]: 'vf-tooltip--green-solid',
  [colors.solidBlue]: 'vf-tooltip--blue-solid',
  [colors.solidRed]: 'vf-tooltip--red-solid',
  [colors.solidYellow]: 'vf-tooltip--yellow-solid'
}

export const positions = {
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom'
}

const positionsClasses = {
  [positions.top]: '', // default
  [positions.left]: 'vf-tooltip--left',
  [positions.right]: 'vf-tooltip--right',
  [positions.bottom]: 'vf-tooltip--bottom'
}

export const TooltipTrigger = ({ children }) => <>{children}</>

TooltipTrigger.propTypes = {
  children: PropTypes.node
}

export const TooltipContent = ({ children }) => (
  <div className='vf-tooltip-message'>{children}</div>
)

TooltipContent.propTypes = {
  children: PropTypes.node
}

const Tooltip = ({
  color = colors.green,
  position = positions.top,
  children
}) => {
  const className =
    'vf-tooltip ' + colorClasses[color] + ' ' + positionsClasses[position]

  return (
    <span className={className} data-test='tooltip'>
      {children}
    </span>
  )
}

Tooltip.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)),
  position: PropTypes.oneOf(Object.values(positions)),
  children: PropTypes.node
}

export default Tooltip
