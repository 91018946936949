import React from 'react'
import PropTypes from 'prop-types'

import ActionButton from '../../Common/ActionButton'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/VF_download_rgb.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/VF_trash_alt02_rgb.svg'

const AttachmentsList = ({
  t,
  items,
  canDownload,
  canDelete,
  downloadingFileId,
  handleDownload,
  handleRemove
}) => {
  return (
    <div className='vf-file-list mt-2'>
      {items.map(el => (
        <div
          key={el.id}
          className='vf-file-list__item'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div
            className={`vf-file-list__link ${
              canDownload ? 'vf-file-list__link--type-download' : ''
            }`}>
            {el.fileName}
          </div>

          <div style={{ display: 'flex', gap: 10 }}>
            {canDownload && (
              <ActionButton
                onClick={() => handleDownload(el)}
                disabled={downloadingFileId === el.id}>
                <DownloadIcon height={16} />
                {t('common.download')}
              </ActionButton>
            )}

            {canDelete && (
              <ActionButton onClick={() => handleRemove(el)}>
                <DeleteIcon height={16} />
                {t('common.delete')}
              </ActionButton>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

AttachmentsList.propTypes = {
  t: PropTypes.func,
  items: PropTypes.array,
  canDownload: PropTypes.bool,
  canDelete: PropTypes.bool,
  downloadingFileId: PropTypes.number,
  handleDownload: PropTypes.func,
  handleRemove: PropTypes.func
}

AttachmentsList.defaultProps = {
  canDownload: false,
  canDelete: false
}

export default AttachmentsList
