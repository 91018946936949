// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import VfHero from '../DesignSystem/Hero'
import { VfContainer } from '../DesignSystem/Utils'
import LinkToEditPage from '../cms/page/LinkToEditPage'

const InnerTemplate = ({ title, children, ...other }) => {
  const { draftId, description } = children.props

  return (
    <>
      <VfContainer>
        {!!draftId ? <LinkToEditPage pageId={draftId} /> : null}
        {!!title ? <h1 className='my-4'>{title}</h1> : <p className='my-4' />}
        {!!description ? (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {children}
      </VfContainer>
    </>
  )
}

InnerTemplate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

InnerTemplate.defaultProps = {
  title: '',
  heroImg: ''
}

export const InnerTemplateWithHeaderImg = ({ heroImg, ...other }) => {
  return (
    <>
      <VfHero imageUrl={heroImg} narrow />
      <InnerTemplate {...other} />
    </>
  )
}

InnerTemplateWithHeaderImg.propTypes = {
  heroImg: PropTypes.string
}

export default InnerTemplate
