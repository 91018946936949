import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import SaveBtn from '../Ctas/SaveBtn'
import { validationSchema } from './helpers'
import { TextInput } from '../../../Common'
import CategoryPicker from './CategoryPicker'

export const CreateFormFields = ({ t }) => (
  <>
    <Field
      id='name'
      name='name'
      type='text'
      placeholder={t('admin.tags.newTagPlaceholder')}
      autoComplete='off'
    />
  </>
)

CreateFormFields.propTypes = {
  t: PropTypes.func
}

export const EditFormFields = ({ t }) => (
  <>
    <label htmlFor='name' className='mt-2'>
      {t('admin.tags.labels.name')}
    </label>

    <Field id='name' name='name' component={TextInput} />

    <CategoryPicker name='categoryId' label={t('admin.tags.labels.category')} />
  </>
)

EditFormFields.propTypes = {
  t: PropTypes.func
}

const FormContainer = ({
  initialValues,
  handleSubmit,
  btnText = 'Save',
  CancelBtn,
  FormFields
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={() => validationSchema(t)}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}>
        {({ dirty, isSubmitting, values }) => (
          <Form>
            <FormFields t={t} />
            <div className='inline-list__form__ctas mt-1'>
              <SaveBtn
                btnText={isSubmitting ? t('common.submitting') : btnText}
                disabled={!dirty || isSubmitting}
              />

              {!!CancelBtn && <CancelBtn disabled={isSubmitting} />}
            </div>

            <ErrorMessage
              component='div'
              name='name'
              className='vf-utility-typo--error'
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  btnText: PropTypes.string,
  CancelBtn: PropTypes.func,
  FormFields: PropTypes.func
}

export default FormContainer
