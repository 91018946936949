// @ts-check
/**
 * @param {{ slice: (arg0: number, arg1: any) => void; lastIndexOf: (arg0: string) => number; }} url
 */
const clearUrl = (url: string) => {
  const index = url.lastIndexOf('{')

  if (index !== -1) {
    const connector = url.charAt(index + 1)
    const urlNoParams = url.slice(0, index)

    return { connector, urlNoParams }
  }

  return { urlNoParams: url, connector: '' }
}

/**
 * @param {object} objParams
 */
const mapParamsToString = (objParams) =>
  Object.keys(objParams)
    .map(item => `${item}=${objParams[item]}`)
    .join('&')

/**
 * @param {object} params
 */

export const mergeUrlWithParams = (params) =>
/**
 * @param {string} rawUrl
 */
  (rawUrl) => {
    const { urlNoParams, connector } = clearUrl(rawUrl)
    const paramsStr = params ? `${connector}${mapParamsToString(params)}` : ''
    return `${urlNoParams}${paramsStr}`
  }
