import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Rows from './Rows'
import './table.scss'

const Table = ({ items }) => {
  const { t } = useTranslation()
  const [visibleItems, setVisibleItems] = useState({})
  const toggleChildren = id => {
    const newVisibleItems = { ...visibleItems }

    if (newVisibleItems[id] === undefined) {
      newVisibleItems[id] = true
    } else {
      newVisibleItems[id] = !newVisibleItems[id]
    }

    setVisibleItems(newVisibleItems)
  }

  return (
    <div className='dashboard-table'>
      <div className='dashboard-table__header'>
        <div className='cell'>
          <span>{t('improvementsTable.name')}</span>
        </div>

        <div className='cell'>
          <span>{t('improvementsTable.implemented')}</span>
        </div>

        <div className='cell'>
          <span>{t('improvementsTable.interrupted')}</span>
        </div>

        <div className='cell'>
          <span>{t('improvementsTable.total')}</span>
        </div>
      </div>

      <div className='dashboard-table__body'>
        <Rows
          items={items}
          visibleItems={visibleItems}
          toggleChildren={toggleChildren}
        />
      </div>
    </div>
  )
}

Table.propTypes = {
  items: PropTypes.array
}

export default Table
