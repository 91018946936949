import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { truncateStr } from '../../../helpers/string'

import './card.scss'

const CardHeader = ({ bgColor, category, title, barColor }) => (
  <div
    className='vf-card__body card-header__container'
    style={{ backgroundColor: bgColor, color: '#eee' }}>
    {category && (
      <div
        className='card-header__category'
        style={{ backgroundColor: barColor }}>
        {category}
      </div>
    )}
    {title && <h4 className='mb-0 p-1 card-header__title'>{title}</h4>}
  </div>
)

CardHeader.propTypes = {
  bgColor: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  barColor: PropTypes.string
}

const VfCardBody = ({
  imageUrl,
  headerBgColor,
  barColor,
  withBorderBottom,
  headerTitle,
  headerCategory,
  caption,
  title,
  bodyText,
  children,
  topChildren,
  hoverText
}) => (
  <>
    <div
      className='vf-card__img-top'
      style={{
        borderBottom: withBorderBottom ? `6px solid ${barColor}` : 'none',
        position: 'relative'
      }}>
      {imageUrl ? (
        <div className={hoverText ? 'card__img-hover' : ''}>
          <img src={imageUrl} alt={title} />

          {hoverText && (
            <div className='card__img-hover__text'>
              {truncateStr(hoverText, 200)}
            </div>
          )}
        </div>
      ) : (
        <CardHeader
          bgColor={headerBgColor}
          category={headerCategory}
          title={headerTitle}
          barColor={barColor}
        />
      )}
      {topChildren}
    </div>

    <div className='vf-card__body'>
      {caption && (
        <p className='vf-card__text mb-1 card-content__caption'>{caption}</p>
      )}
      <h3 className='vf-card__title'>{title}</h3>
      {!!bodyText && <p className='vf-card__text'>{bodyText}</p>}
      {children}
    </div>
  </>
)

export const bodyPropTypes = {
  /** Header image url */
  imageUrl: PropTypes.string,
  /** Fallback if no image available */
  headerBgColor: PropTypes.string,
  /** Show title in header area, if no image */
  headerTitle: PropTypes.string,
  /** Bar at the top of header, if no image */
  headerCategory: PropTypes.string,
  /** Collored line just below image, if needed */
  barColor: PropTypes.string,
  /** Show/hide bar below image */
  withBorderBottom: PropTypes.bool,
  /** Custom, extra info below image */
  caption: PropTypes.string,
  /** Card title below image */
  title: PropTypes.string,
  /** Main Card content */
  bodyText: PropTypes.string,
  /** Custom, extra bit just below 'body text' */
  children: PropTypes.node,
  /** Custom, extra bit just below top image */
  topChildren: PropTypes.node,
  /** Custom, text displayed while hovering over image */
  hoverText: PropTypes.string
}

export const bodyDefaultProps = {
  headerBgColor: '#eee',
  barColor: '#ddd',
  withBorderBottom: false
}

VfCardBody.propTypes = bodyPropTypes
VfCardBody.defaultProps = bodyDefaultProps

export const VfCard = props => {
  return (
    <article className='vf-card reveal'>
      <VfCardBody {...props} />
    </article>
  )
}

VfCard.propTypes = bodyPropTypes
VfCard.defaultProps = bodyDefaultProps

const VfCardAsLink = ({ to, ...other }) => {
  return (
    <Link to={to} className='vf-card reveal'>
      <VfCardBody {...other} />
    </Link>
  )
}

VfCardAsLink.propTypes = {
  to: PropTypes.string,
  ...bodyPropTypes
}

VfCardAsLink.defaultProps = {
  to: '#',
  ...bodyDefaultProps
}

export default VfCardAsLink
