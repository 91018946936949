// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { useVfAlchemyScript } from '../../../hooks'
import {
  CreateForm as CreateCommentForm,
  List as CommentsList
} from '../Comments'
import Description from './Description'
import Attachments from './Attachments'

const TabContent = ({ idea, t }) => {
  useVfAlchemyScript()

  const { isExpert } = idea.userDecisions

  return (
    <div
      id='idea-description-panel'
      className='vf-panel'
      role='tabpanel'
      tabIndex={0}
      aria-labelledby='vf-tab-1'>
      <Description idea={idea} t={t} />

      {!!idea.documents.length && (
        <Attachments idea={idea} isExpert={isExpert} t={t} />
      )}

      <h3>{t('titles.comments')}</h3>
      <CreateCommentForm idea={idea} resetAfterSubmit />
      <div className='mt-3'>
        <CommentsList idea={idea} />
      </div>
    </div>
  )
}

TabContent.propTypes = {
  idea: PropTypes.object,
  isExpert: PropTypes.bool,
  // i18next
  t: PropTypes.func
}

export default TabContent
