import React from 'react'
import { useTranslation } from 'react-i18next'

import { Tags } from '../../Components/Admin'

const TagsAdmin = () => {
  const { t } = useTranslation()

  return (
    <>
      <h1>{t('titles.tags')}</h1>

      <Tags />
    </>
  )
}

export default TagsAdmin
