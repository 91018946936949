import React from 'react'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import { MentionsInput, Mention } from 'react-mentions'

import api from '../../../state/api'
import { mergeUrlWithParams } from '../../../helpers/url'

import './comment.scss'

const CommentField = ({
  field,
  form,
  mentionsFieldName = 'mentionedUsers',
  t,
  rawUrl
}) => {
  const fetchUsers = async (query, callback) => {
    if (!query) {
      return
    }

    const url = mergeUrlWithParams({ query })(rawUrl)

    const response = await api(url)

    const options =
      response?.map(user => ({
        display: `${user.displayName}`,
        id: user.id
      })) ?? []

    callback(options)
  }

  const handleChange = (_event, value, _newPlainTextValue, mentions) => {
    form.setFieldValue(field.name, value)
    form.setFieldValue(
      mentionsFieldName,
      mentions.map(item => item.id)
    )
  }

  return (
    <div className='comment-input-wrapper'>
      <MentionsInput
        value={field.value}
        onChange={handleChange}
        className='comment-input'
        placeholder={`${t('forms.comment.comment')} *`}>
        <Mention
          trigger='@'
          className='comment-input__mentioned-user'
          data={fetchUsers}
          appendSpaceOnAdd
        />
      </MentionsInput>

      <ErrorMessage name='content' component='p' />
    </div>
  )
}

CommentField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  t: PropTypes.func
}

export default CommentField
