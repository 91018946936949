import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import Picker from './Picker'
import { useData } from '../../hooks'
import {
  getTagCategoriesList,
  getTagCategoriesListUrl,
  getTagsList,
  getTagsListUrl,
  isFetchingTagCategories
} from '../../selectors'
import actions from '../../state/actions/data'
import { dataTypes } from '../../configurations'
import {
  getFirstCategoryTags,
  getTagsWithoutFirstCategory,
  getSortedTags,
  sortTagsByCategory
} from '../Admin/Tags/helpers'
import { TAG_SEARCH_KEY, TAG_SEARCH_TYPES } from '../Ideas/SearchIdeas/helpers'
import SwitchButtons, { SwitchButton } from './SwitchButton'
import Tooltip, {
  TooltipContent,
  TooltipTrigger,
  colors,
  positions
} from '../DesignSystem/Tooltip/Tooltip'
import { getFirstCategory } from '../Admin/TagCategories/helpers'

export const formatTagForUi = item => ({
  value: item.id,
  label: item.name,
  disabled: !item.active,
  backgroundColor: item.category?.color
})

const TagsPicker = ({
  field,
  label,
  form: { setFieldValue },
  tags = [],
  firstCategory,
  handleChange,
  handleBlur,
  onlyFirstCategory = false,
  withoutFirstCategory = false
}) => {
  const { t } = useTranslation()

  const getSortedAndFilteredTags = () => {
    let result = !!tags ? [...tags].sort(sortTagsByCategory) : []

    if (withoutFirstCategory) {
      result = getTagsWithoutFirstCategory(result)
    }

    if (onlyFirstCategory) {
      result = getFirstCategoryTags(result)
    }

    return result
  }

  const sortedTags = getSortedAndFilteredTags()

  const getOptions = () => {
    const activeTags = sortedTags?.filter(tag => tag.active) ?? []

    if (onlyFirstCategory) {
      return getSortedTags(activeTags).map(formatTagForUi)
    } else {
      const categorizeTags = (acc, tag) => {
        const categoryName =
          tag.category?.name || t('admin.tags.titles.uncategorized')
        return {
          ...acc,
          [categoryName]: acc[categoryName]
            ? [...acc[categoryName], tag]
            : [tag]
        }
      }

      const tagsByCategories = activeTags.reduce(categorizeTags, {})

      return Object.keys(tagsByCategories).map(category => ({
        label: category,
        options: tagsByCategories[category].map(formatTagForUi)
      }))
    }
  }

  const options = getOptions()

  // when tags are loaded from url params (as tags: '1,2'), we need to convert them to the format that the Picker component expects
  useEffect(() => {
    const convertTags = () => {
      if (typeof field.value === 'string' && !!field.value && !!tags?.length) {
        const localTags = field.value
          .split(',')
          .map(tag => formatTagForUi(tags?.find(t => t.id.toString() === tag)))

        setFieldValue(field.name, localTags)
      }
    }

    convertTags()
  }, [field.name, field.value, setFieldValue, tags])

  const getLabel = () => {
    if (onlyFirstCategory) {
      return firstCategory?.name
    }

    return label
  }

  if (onlyFirstCategory && !firstCategory) {
    return null
  }

  return (
    <Picker
      options={options}
      name={field.name}
      value={field.value}
      label={getLabel()}
      handleBlur={handleBlur}
      handleChange={handleChange}
      isMulti
      className='tags-picker'
    />
  )
}

TagsPicker.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func
  }),
  tags: PropTypes.array,
  firstCategory: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  onlyFirstCategory: PropTypes.bool,
  withoutFirstCategory: PropTypes.bool
}

const TagsPickerContainer = ({
  onlyFirstCategory = false,
  withoutFirstCategory = false,
  ...props
}) => {
  const { data } = useData(
    dataTypes.tags.name,
    null,
    getTagsListUrl,
    getTagsList,
    actions.requestPreInit
  )

  const { data: tagCategories } = useData(
    dataTypes.tagCategories.name,
    null,
    getTagCategoriesListUrl,
    getTagCategoriesList,
    actions.requestPreInit,
    isFetchingTagCategories
  )

  const emptyValue = []

  const {
    field,
    form: { setFieldValue, setFieldTouched }
  } = props

  const handleChange = value => {
    setFieldValue(field.name, value || emptyValue)
  }

  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }

  return (
    <TagsPicker
      {...props}
      tags={data}
      firstCategory={getFirstCategory(tagCategories)}
      handleChange={handleChange}
      handleBlur={handleBlur}
      onlyFirstCategory={onlyFirstCategory}
      withoutFirstCategory={withoutFirstCategory}
    />
  )
}

export default TagsPickerContainer

const SwitchButtonWithTooltip = ({ ...props }) => (
  <Tooltip position={positions.bottom} color={colors.blue}>
    <TooltipTrigger>
      <SwitchButton {...props} />
    </TooltipTrigger>

    <TooltipContent>{props.item.tooltip}</TooltipContent>
  </Tooltip>
)

export const TagsPickerWithSearchType = ({ ...props }) => {
  const { t } = useTranslation()
  const { setFieldValue, values } = props.form

  const handleTagSearchTypeChange = value => {
    setFieldValue(TAG_SEARCH_KEY, value)
  }

  const buttons = [
    {
      label: t('forms.searchIdeas.tags.orLabel'),
      value: TAG_SEARCH_TYPES.OR,
      tooltip: t('forms.searchIdeas.tags.orDescription')
    },
    {
      label: t('forms.searchIdeas.tags.andLabel'),
      value: TAG_SEARCH_TYPES.AND,
      tooltip: t('forms.searchIdeas.tags.andDescription')
    }
  ]

  return (
    <>
      <div className='tags_search_type'>
        {/* <div className='tags_search_type__label'>
          {t('forms.searchIdeas.tags.searchTypeLabel')}
        </div> */}

        <SwitchButtons
          buttons={buttons}
          handleButtonChange={handleTagSearchTypeChange}
          buttonSelected={values[TAG_SEARCH_KEY]}
          defaultButtonSelected={TAG_SEARCH_TYPES.OR}
          name={TAG_SEARCH_KEY}
          ButtonTemplate={props => <SwitchButtonWithTooltip {...props} />}
        />
      </div>

      <TagsPickerContainer {...props} />
    </>
  )
}
