// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ideaLayoutTypes } from '../../configurations'
import IdeasList from '../Ideas'
import VfHero from '../DesignSystem/Hero'
import { VfContainer } from '../DesignSystem/Utils'
// import TabBar from '../DesignSystem/TabBar'
import { VfLinkButton } from '../DesignSystem/Buttons'
import { ContactSection } from '../Common/Contact'
import { useVfAlchemyScript } from '../../hooks'
import SubscribeChallengeBtn from './SubscribeChallengeBtn'
import { AvatarMedium } from '../User/Avatar'
import teamBackgroundSrc from '../../assets/images/hero/challenges.jpg'
import { UserLink } from '../Common'
import { getPaginationConfig } from '../../selectors'

const Details = ({ challenge, ContextMenuSection }) => {
  useVfAlchemyScript()
  const { t } = useTranslation()

  // const image =
  //   challenge._links && challenge._links.heroImage
  //     ? challenge._links.heroImage.href
  //     : ''
  // const headers = [
  //   t('challenge-details-page.tab1'),
  //   t('challenge-details-page.tab2')
  // ]

  const {
    author,
    description
    // state
  } = challenge

  const propolsalsStoreKey = `${challenge.id}-ideas-proposals-list`
  const completedStoreKey = `${challenge.id}-ideas-completed-list`
  const popularStoreKey = `${challenge.id}-ideas-popular-list`

  const initialPropolsalsPaginationConfig = useSelector(
    getPaginationConfig(propolsalsStoreKey)
  )
  const initialCompletedPaginationConfig = useSelector(
    getPaginationConfig(completedStoreKey)
  )

  const initialPopularPaginationConfig = useSelector(
    getPaginationConfig(popularStoreKey)
  )

  const getInitialPage = initialPaginationConfig => {
    if (!!initialPaginationConfig?.currentPage) {
      return initialPaginationConfig.currentPage - 1
    }

    return 0
  }

  const urlParams = {
    challengeId: challenge.id,
    pageSize: 5,
    sort: 'LAST_PUBLISHED'
  }

  const propolsalsUrlParams = {
    ...urlParams,
    page: getInitialPage(initialPropolsalsPaginationConfig),
    pageSize: 10,
    status: ['NEW', 'ASSIGNED']
  }

  const completedUrlParams = {
    ...urlParams,
    page: getInitialPage(initialCompletedPaginationConfig),
    status: 'COMPLETED'
  }

  const popularUrlParams = {
    ...urlParams,
    page: getInitialPage(initialPopularPaginationConfig),
    sort: 'MOST_LIKES'
  }

  return (
    <>
      <VfHero imageUrl={teamBackgroundSrc} narrow />
      <VfContainer>
        <h1 className='my-4'>{challenge.title}</h1>
        <SubscribeChallengeBtn challengeId={challenge.id} />

        <div className='vf-row'>
          <div className='vf-col-md-8'>
            {/* <TabBar tabs={challenge.tabs} headers={headers} /> */}

            {!!description && (
              <>
                <h2 className='mt-2'>{t('titles.description')}</h2>
                <div>{description}</div>
              </>
            )}

            <h2 className='mt-2'>{t('titles.teamMembers')}</h2>

            {!challenge.members || challenge.members?.length === 0 ? (
              <p>{t('common.sorryNoItems')}</p>
            ) : (
              <>
                {challenge.members.map(item => (
                  <div key={item.id} className='my-1'>
                    <UserLink id={item.id}>{item.displayName}</UserLink>
                  </div>
                ))}
              </>
            )}
            <h2 className='mt-2'>{t('titles.improvementProposals')}</h2>
            <IdeasList
              layoutType={ideaLayoutTypes.side_item.type}
              showState
              urlParams={propolsalsUrlParams}
              showBottomPagination
              storeKey={propolsalsStoreKey}
            />
          </div>

          <div className='vf-col-md-4'>
            {/* {state === 'ACTIVE' && ( */}
            <p>
              <VfLinkButton
                styles={{ width: '100%' }}
                color='secondary'
                size='large'
                text={t('challenge-details-page.createIdea')}
                to={`/create-improvement/${challenge.id}`}
              />
            </p>
            {/* )} */}

            {ContextMenuSection}

            {!!author && !!author.id && (
              <div className='vf-row my-2'>
                <ContactSection
                  author={author}
                  avatar={<AvatarMedium author={author} />}
                  t={t}
                />
              </div>
            )}
            <h3>{t('titles.implementedImprovements')}</h3>
            <IdeasList
              layoutType={ideaLayoutTypes.side_item.type}
              showState
              urlParams={completedUrlParams}
              showBottomPagination
              storeKey={completedStoreKey}
            />
            <h3>{t('titles.popularIdeas')}</h3>
            <IdeasList
              layoutType={ideaLayoutTypes.side_item.type}
              urlParams={popularUrlParams}
              showBottomPagination
              storeKey={popularStoreKey}
            />
          </div>
        </div>
      </VfContainer>
    </>
  )
}

Details.propTypes = {
  challenge: PropTypes.object,
  ContextMenuSection: PropTypes.object
}

export default Details
