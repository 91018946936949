import React from 'react'
import {
  ActivateItem,
  EditItem,
  CloseItem,
  DeleteItem,
  OnHoldItem
} from '../Components/ctas/items'

export const ctas = (
  challengeId = '',
  { handleDelete, handleActivate, handleClose, handleOnHold }
) => ({
  DRAFT: {
    USER: [],
    EXPERT: [
      <EditItem key='EditItem' to={`/cms/team/${challengeId}/update`} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  PUBLISHED: {
    USER: [],
    EXPERT: [
      <EditItem key='EditItem' to={`/cms/team/${challengeId}/update`} />,
      <ActivateItem key='ActivateItem' handleClick={handleActivate} />,
      <OnHoldItem key='OnHoldItem' handleClick={handleOnHold} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  ACTIVE: {
    USER: [],
    EXPERT: [
      <EditItem key='EditItem' to={`/cms/team/${challengeId}/update`} />,
      <CloseItem key='CloseItem' handleClick={handleClose} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  CLOSED: {
    USER: [],
    EXPERT: [
      <ActivateItem key='ActivateItem' handleClick={handleActivate} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  DELETED: {
    USER: [],
    EXPERT: []
  }
})

export const ctasWithoutTeamState = (
  challengeId,
  { handleDelete, handleActivate, handleClose, handleOnHold },
  t
) => ({
  USER: [],
  EXPERT: [
    <EditItem key='EditItem' to={`/cms/team/${challengeId}/update`} t={t} />
  ]
})
