// @ts-check
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getActivityListUrl,
  getActivitiesList,
  getActivitiesPagePrevUrl,
  getActivitiesPageNextUrl,
  getActivitiesPaginationSetup,
  getIsActivityFetching,
  getPaginationConfig
} from '../../selectors'
import actions from '../../state/actions/data'
import { UserLink, IdeaLink, Pagination } from '../Common'
import { getActivityTKey } from '../../configurations'
import { useData, useLanguage } from '../../hooks'
import { dateLocal } from '../../helpers/date'

import './activity-feed.scss'
import { getAuthorFullName } from '../../helpers/author'

const Item = ({ item: { author, type, published, description, context } }) => {
  const { t } = useTranslation()

  return (
    <div className='vf-border-bottom-gray-200 py-2 activity-feed__item'>
      <p className='my-0 vf-intro-in-caps'>
        {t(getActivityTKey(type.toLowerCase()))}
      </p>
      <p className='my-0'>
        <UserLink id={author.id}> {getAuthorFullName(author)} </UserLink>
      </p>
      <p className='my-0'>{dateLocal(published)}</p>
      {!!context && (
        <IdeaLink id={context.id}>
          <h4 className='my-1'>{context.name}</h4>
          <p className='vf-text--black-50 my-0'>{description}</p>
        </IdeaLink>
      )}
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    author: PropTypes.object,
    context: PropTypes.object,
    type: PropTypes.string,
    details: PropTypes.string,
    published: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  })
}

const List = ({ items }) => items.map((item, i) => <Item key={i} item={item} />)

List.propTypes = {
  items: PropTypes.array
}

const ListContainer = ({ context, contextId }) => {
  const storeKey = !!context
    ? `activity-feed-${context}-${contextId}`
    : 'activity-feed'

  const initialPaginationConfig = useSelector(getPaginationConfig(storeKey))

  const getInitialPage = () => {
    if (!!initialPaginationConfig?.currentPage) {
      return initialPaginationConfig.currentPage - 1
    }

    return 0
  }

  const { t } = useTranslation()
  const [pageUrl, setPageUrl] = useState(null)
  const initialPageSettings = { page: getInitialPage(), pageSize: 5 }
  const params = contextId
    ? { context, contextId, ...initialPageSettings }
    : initialPageSettings
  const lng = useLanguage()

  const { data, url, isFetching } = useData(
    'activities',
    { ...params, lng },
    getActivityListUrl,
    getActivitiesList,
    actions.requestPreInit,
    getIsActivityFetching,
    pageUrl
  )

  // @ts-ignore
  const items = data.sort(
    (a, b) => new Date(b.published) - new Date(a.published)
  )
  const prevUrl = useSelector(getActivitiesPagePrevUrl(url))
  const nextUrl = useSelector(getActivitiesPageNextUrl(url))
  const paginationSetup = useSelector(getActivitiesPaginationSetup(url))

  const handlePageClick = url => setPageUrl(url)

  return (
    <>
      {!!items.length ? <List items={items} /> : null}
      {!items.length && !isFetching && <p>{t('activity.noActivity')}</p>}
      <Pagination
        storeKey={storeKey}
        totalPages={paginationSetup.totalPages}
        currentPage={paginationSetup.currentPage + 1}
        isFirst={!prevUrl}
        isLast={!nextUrl}
        handlePrevClick={() => handlePageClick(prevUrl)}
        handleNextClick={() => handlePageClick(nextUrl)}
      />
    </>
  )
}

ListContainer.propTypes = {
  contextId: PropTypes.string,
  context: PropTypes.string
}

ListContainer.defaultProps = {
  contextId: null,
  context: '',
  title: null
}

export default ListContainer
