// @ts-check
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { VfCardAsLink } from '../DesignSystem/Cards'
import { getChallengeTheme } from '../../helpers/color'
import defaultImage from '../../assets/images/hero/challenges.jpg'

const Item = ({
  item: { id, title, description, ideasCount: count, _links },
  imgNo
}) => {
  const colors = getChallengeTheme(id)
  const [image, setImage] = useState()

  useEffect(() => {
    const getImage = async () => {
      try {
        const image = await import(`../../assets/images/teams/${imgNo}.jpg`)
        setImage(image.default)
      } catch (e) {
        setImage(defaultImage)
      }
    }
    getImage()
  }, [imgNo])

  return (
    <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
      <VfCardAsLink
        imageUrl={image}
        to={`/team-details/${id}`}
        title={title}
        barColor={colors.bar}
        withBorderBottom={false}
        hoverText={description}>
        {/* <p className='vf-card__text'>{`${count} ${t('common.idea', {
          count
        })}`}</p> */}
      </VfCardAsLink>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    ideasCount: PropTypes.number,
    _links: PropTypes.object
  })
}

export default Item
