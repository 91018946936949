import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field, Form } from 'formik'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useVfAlchemyScript } from '../../../hooks'
import {
  TextInput,
  MSGraphSearch,
  SubmitButton,
  Message,
  TextArea
} from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { getUrlFromConfig } from '../../../selectors'

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...parentProps
}) => {
  useVfAlchemyScript()
  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'))

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}>
      {formikProps => {
        return (
          <FormComponent
            profileSearchUrl={profileSearchUrl}
            {...formikProps}
            {...parentProps}
          />
        )
      }}
    </Formik>
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.any,
  handleSubmit: PropTypes.func
}

const FormComponent = ({
  isValid,
  profileSearchUrl,
  isSubmitting,
  apiError
}) => {
  const { t } = useTranslation()

  return (
    <Form>
      <Field
        name='title'
        label={t('team.form.labels.name')}
        component={TextInput}
      />

      <Field
        name='description'
        label={t('team.form.labels.description')}
        component={TextArea}
        rows={3}
      />

      <Field
        name='members'
        label={t('team.form.labels.members')}
        component={MSGraphSearch}
        rawUrl={profileSearchUrl}
        isMulti
      />

      {apiError && <Message type='error'>{apiError}</Message>}

      <div className='vf-row'>
        <div className='mt-3 ml-auto'>
          <SubmitButton
            button={<VfButton text={t('team.form.save')} type='submit' />}
            inProgress={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </Form>
  )
}

FormComponent.propTypes = {
  isValid: PropTypes.bool,
  profileSearchUrl: PropTypes.string,
  isSubmitting: PropTypes.bool,
  apiError: PropTypes.string
}

export default FormContainer
