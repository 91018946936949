// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useTranslation } from 'react-i18next'

import api from '../../state/api'
import { mergeUrlWithParams } from '../../helpers/url'

export const ControlComponent = props => {
  const { hasError } = props.selectProps
  return (
    <div className='vf-input-container w-100'>
      <div
        className={`vf-input ${hasError ? 'vf-input--error' : ''}`}
        style={{
          padding: '5px 10px'
        }}>
        <components.Control {...props} />
      </div>
    </div>
  )
}
ControlComponent.propTypes = {
  selectProps: PropTypes.object
}

export const customStyles = {
  multiValue: (provided, state) => ({
    ...provided,
    textDecoration: state.data.active === false ? 'line-through' : 'none',
    opacity: state.data.active === false ? 0.5 : 1
  }),
  control: base => ({
    ...base,
    border: '0',
    boxShadow: 'none'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuIsOpen ? '#ccc' : '#333'
  })
}

const MSGraphSearch = ({
  field,
  label,
  form: { setFieldValue, setFieldTouched, errors, touched },
  rawUrl,
  isMulti = true,
  isClearable = false
}) => {
  const { t } = useTranslation()
  const formatResponse = users =>
    users.map(user => ({ value: user.id, label: user.displayName }))

  const fetchUsers = async (value, cbFunc) => {
    const url = mergeUrlWithParams({ query: value })(rawUrl)

    try {
      const response = await api(url)
      cbFunc(formatResponse(response))
    } catch (err) {
      cbFunc([
        {
          value: 'error',
          label: t('messages.error'),
          isDisabled: true
        }
      ])
    }
  }

  const throttledFetchUsers = throttle(fetchUsers, 500)

  const emptyValue = isMulti ? [] : ''

  const loadOptions = (input, callback) => {
    if (!input) {
      return emptyValue
    }

    throttledFetchUsers(input, callback)
  }

  const handleChange = value => {
    setFieldValue(field.name, value || emptyValue)
  }

  const hasError = !!errors[field.name] && touched[field.name]

  return (
    <>
      {label && (
        <label htmlFor={field.name} className='mt-2'>
          {label}
        </label>
      )}

      <AsyncSelect
        name={field.name}
        value={field.value}
        onChange={handleChange}
        isMulti={isMulti}
        cacheOptions
        loadOptions={loadOptions}
        noOptionsMessage={() => t('messages.noResults')}
        loadingMessage={() => t('common.loading')}
        placeholder=''
        components={{
          Control: ControlComponent,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        styles={customStyles}
        hasError={hasError}
        isClearable={isClearable}
      />

      {hasError && (
        <div className='vf-utility-typo--error mt-2'>{errors[field.name]}</div>
      )}
    </>
  )
}

MSGraphSearch.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  rawUrl: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool
}

export default MSGraphSearch
