import React from 'react'
import PropTypes from 'prop-types'

const sections = [
  'description',
  'summaryInEnglish',
  'ideaValue',
  'targetGroup',
  'requiredResources',
  'solution',
  'activities'
]

const Section = ({ section, content, t }) =>
  content ? (
    <>
      <h3>{t(`idea-details.sections.${section}.title`)}</h3>
      <p
        style={{ whiteSpace: 'pre-wrap' }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </>
  ) : null

Section.propTypes = {
  section: PropTypes.string,
  content: PropTypes.string,
  t: PropTypes.func
}

const IdeaSectionsList = ({ items, idea, t }) =>
  items.map(item => (
    <Section key={item} t={t} section={item} content={idea[item]} />
  ))

IdeaSectionsList.propTypes = {
  items: PropTypes.array,
  idea: PropTypes.object,
  t: PropTypes.func
}

const Description = ({ idea, t }) => (
  <IdeaSectionsList items={sections} idea={idea} t={t} />
)

Description.propTypes = {
  idea: PropTypes.object,
  // i18next
  t: PropTypes.func
}

export default Description
