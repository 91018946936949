import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CommentsCountContext } from '../Ideas/Details'

import eyeIcon from '../../assets/icons/VF_eye_rgb.svg'

/**
 * @param {Object} props
 * @param {number} props.rating
 * @returns {JSX.Element}
 */
export const StarsPainter = ({ rating }) => (
  <span>{`${'★'.repeat(rating)}${'☆'.repeat(5 - rating)}`}</span>
)
StarsPainter.propTypes = {
  rating: PropTypes.number
}

export const LikesPainter = ({ count }) => <span>♥&nbsp;{count}</span>
LikesPainter.propTypes = {
  count: PropTypes.number
}

export const CommentsWithContext = ({ t }) => {
  const { counter } = useContext(CommentsCountContext)
  return <span>{`${counter} ${t('common.comment', { count: counter })}`}</span>
}

CommentsWithContext.propTypes = {
  t: PropTypes.func
}

export const CommentsSummary = ({ count, t }) => {
  return (
    <span style={{ wordBreak: 'keep-all' }}>
      {count}&nbsp;{t('common.comment', { count })}
    </span>
  )
}

CommentsSummary.propTypes = {
  count: PropTypes.number,
  t: PropTypes.func
}

export const ViewsPainter = ({ count }) => (
  <span>
    <img src={eyeIcon} alt='views' style={{ width: 18, marginBottom: 2 }} />
    &nbsp;{count}
  </span>
)
ViewsPainter.propTypes = {
  count: PropTypes.number
}
