// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.ReactNode} props.children
 * @param {Object} props.other
 * @param {Object} props.title
 *
 * @returns {JSX.Element} }
 */
const UserLink = ({ id, children, ...other }) => (
  <Link to={`/user/${id}`} {...other}>
    {children}
  </Link>
)

UserLink.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  other: PropTypes.object,
  title: PropTypes.string
}

export default UserLink
