/**
 * test the solution
 * in VCS terminal run: ($env:REACT_APP_CUSTOM_ENV = "acc") -and (npm start)
 *
 * remember
 * to set in azure-pipelines.yml value for: REACT_APP_CUSTOM_ENV
 */

const envVariables = {
  dev: {
    REACT_APP_API_ROOT:
      'https://wind-dev-improvementportal-we-web-api.azurewebsites.net/iportal-api/',
    REACT_APP_FILES_UPLOADER: '',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      'cb18dda8-d33b-472a-933b-b81d86000cf4'
  },
  tst: {
    REACT_APP_API_ROOT:
      'https://wind-tst-improvementportal-we-web-api.azurewebsites.net/iportal-api/',
    REACT_APP_FILES_UPLOADER: '',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      'be1bf4b4-f9a2-4aa6-b231-de2853be52d8'
  },
  prd: {
    REACT_APP_API_ROOT:
      'https://wind-prd-improvementportal-we-web-api.azurewebsites.net/iportal-api/',
    REACT_APP_FILES_UPLOADER: '',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      'fedfd22d-f0fe-455b-af68-15c8def94ed3'
  },
  slot: {
    REACT_APP_API_ROOT:
      'https://wind-dev-improvementportal-we-web-api-slot0.azurewebsites.net/iportal-api/',
    REACT_APP_FILES_UPLOADER: '',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      'cb18dda8-d33b-472a-933b-b81d86000cf4'
  }
}

export const getEnvVariable = variable => {
  const customEnv = process.env.REACT_APP_CUSTOM_ENV

  try {
    return !!customEnv
      ? envVariables[customEnv][variable]
      : process.env[variable]
  } catch (err) {
    console.error(
      'Cannot get requested ENV VARIABLE, env:',
      customEnv,
      'var:',
      variable
    )

    return ''
  }
}
