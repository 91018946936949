import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Message } from '..'
import AttachmentsList from './AttachmentsList'

import './AttachmentsInput.css'

const Attachments = ({
  field,
  label,
  form: {
    setFieldValue,
    errors,
    touched,
    setFieldError,
    validateField,
    validateOnBlur
  },
  settings,
  imageSize: wantedImageSize
}) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const hasError = !!errors[field.name] && touched[field.name]

  const MAX_FILE_SIZE_BYTES = 10000000
  const MAX_NUMBER_OF_FILES = settings?.maxNumberOfFiles ?? 3
  const ACCEPTED_FILE_TYPES = settings?.acceptedFileTypes ?? [
    'text/plain',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    'image/jpg',
    'image/jpeg',
    'image/png'
  ]
  const ACCEPTED_FILE_TYPES_INFO =
    settings?.acceptedFileTypesInfo ??
    'txt, pdf, excel, word, powerpoint, jpg, jpeg, png'

  const getImageDimension = file => {
    return new Promise((resolve, reject) => {
      const fileUrl = URL.createObjectURL(file)
      const img = new Image()

      img.src = fileUrl
      img.onload = () => resolve(img)
    })
  }

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setMessage('')

      if (acceptedFiles.length + field.value.length > MAX_NUMBER_OF_FILES) {
        setMessage(t('forms.attachments.tooManyFiles'))
        return
      }

      const tooLargeFiles = rejectedFiles.filter(
        item => item.size > MAX_FILE_SIZE_BYTES
      )

      if (tooLargeFiles.length) {
        setMessage(t('forms.attachments.fileTooLarge'))
        return
      }

      if (rejectedFiles.length) {
        setMessage(t('forms.attachments.wrongExtension'))
        return
      }

      if (!!wantedImageSize) {
        const file = acceptedFiles[0]
        const delta = 10
        getImageDimension(file).then(result => {
          const { width, height } = result
          const { w, h } = wantedImageSize
          const isWidth = w - delta < width && width < w + delta
          const isHeight = h - delta < height && height < h + delta

          if (!isWidth || !isHeight) {
            setFieldValue(field.name, [])
            const message = `Please make sure the image is: ${w}px width and ${h}px height`
            setMessage(message)
          }
        })
      }

      acceptedFiles.forEach(file => {
        file.id = file.name
        file.fileName = file.name
      })

      setFieldValue(field.name, [...field.value, ...acceptedFiles])

      if (!!acceptedFiles.length && !validateOnBlur) {
        validateField(field.name)
      }
    },
    [
      MAX_NUMBER_OF_FILES,
      field.name,
      field.value,
      setFieldValue,
      t,
      validateField,
      validateOnBlur,
      wantedImageSize
    ]
  )

  const isDisabled = field.value.length >= MAX_NUMBER_OF_FILES

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE_BYTES,
    accept: ACCEPTED_FILE_TYPES.join(', '),
    disabled: isDisabled
  })

  const removeAttachment = file => {
    setFieldValue(
      field.name,
      field.value.filter(el => el.id !== file.id)
    )
  }

  return (
    <>
      <label htmlFor={field.name} className='mt-2'>
        {label}
      </label>

      <div
        {...getRootProps({
          className: `dropzone vf-bg--light-yellow ${
            isDisabled ? 'disabled' : ''
          }`
        })}>
        <input {...getInputProps()} />

        {!isDisabled && isDragActive && (
          <div>{t('forms.attachments.dropActive')}</div>
        )}

        {!isDisabled && !isDragActive && (
          <div>{t('forms.attachments.instructions')}</div>
        )}

        {isDisabled && <div>{t('forms.attachments.noMoreFilesAllowed')}</div>}

        <small>
          {t('common.max')} {MAX_NUMBER_OF_FILES}{' '}
          {t('common.file', { count: MAX_NUMBER_OF_FILES })};{' '}
          {t('common.maxSize')}: {MAX_FILE_SIZE_BYTES / 1000000} MB /{' '}
          {t('common.file')}; {t('common.allowed')}: {ACCEPTED_FILE_TYPES_INFO}
        </small>
      </div>

      {message && <Message type='error'>{message}</Message>}
      {hasError && (
        <div className='vf-utility-typo--error mt-1'>{errors[field.name]}</div>
      )}

      <AttachmentsList
        t={t}
        items={field.value}
        canDelete
        handleRemove={removeAttachment}
      />
    </>
  )
}

Attachments.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  settings: PropTypes.object,
  imageSize: PropTypes.object
}

export default Attachments
