import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ActionButton from '../../../Common/ActionButton'
import { ReactComponent as BookmarkIcon } from '../../../../assets/icons/bookmark.svg'
import { handleEdit } from '../Form/helpers'
import { getTagCategoriesListUrl, getTagsListUrl } from '../../../../selectors'

const MarkAsFirstBtn = ({ item, editCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const rawUrl = useSelector(getTagCategoriesListUrl)
  const tagsUrl = useSelector(getTagsListUrl)

  const handleClick = () =>
    handleEdit(
      item,
      dispatch,
      rawUrl,
      tagsUrl,
      editCallback
    )({
      ...item,
      firstCategory: true
    })

  return (
    <ActionButton
      title={t('admin.tagCategories.buttons.markAsFirst')}
      onClick={handleClick}>
      <BookmarkIcon height={16} />
    </ActionButton>
  )
}

MarkAsFirstBtn.propTypes = {
  item: PropTypes.object,
  editCallback: PropTypes.func
}

export default MarkAsFirstBtn
