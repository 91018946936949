import React from 'react'
import PropTypes from 'prop-types'

const Row = ({
  item: {
    id,
    name,
    children,
    implemented,
    interrupted,
    childrenImplemented = 0,
    childrenInterrupted = 0,
    customRowClassName = ''
  },
  level,
  parent,
  visibleItems,
  toggleChildren
}) => {
  const style = { display: 'contents' }
  const cellStyle = {}
  let className = 'dashboard-table__body__row'

  if (!!customRowClassName) {
    className += ` ${customRowClassName}`
  }

  if (level > 1) {
    cellStyle.paddingLeft = (level - 1) * 20 + 'px'
    className += ' child'
    if (!visibleItems[parent]) {
      style.display = 'none'
    }
  }

  const getImplementedSums = () => {
    if (!!children && !visibleItems[name]) {
      return parseInt(childrenImplemented) + parseInt(implemented)
    }

    return parseInt(implemented)
  }

  const getInterruptedSums = () => {
    if (!!children && !visibleItems[name]) {
      return parseInt(childrenInterrupted) + parseInt(interrupted)
    }

    return parseInt(interrupted)
  }

  return (
    <div style={style} className={className}>
      <div
        className='cell'
        style={cellStyle}
        onClick={e => {
          toggleChildren(id)
        }}>
        <span
          className={`cell__name ${!!children ? 'cell__name--clickable' : ''}`}>
          {name}
          {!!children && (
            <i
              className={`arrow ${visibleItems[id] ? 'arrow--rotated' : ''}`}
            />
          )}
        </span>
      </div>

      <div className='cell'>
        <span>{getImplementedSums()}</span>
      </div>

      <div className='cell'>
        <span>{getInterruptedSums()}</span>
      </div>

      <div className='cell'>
        <span>{getImplementedSums() + getInterruptedSums()}</span>
      </div>

      {children && (
        <Rows
          items={Object.values(children)}
          level={level + 1}
          visibleItems={visibleItems}
          toggleChildren={toggleChildren}
          parent={id}
        />
      )}
    </div>
  )
}

Row.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  parent: PropTypes.string,
  visibleItems: PropTypes.object,
  toggleChildren: PropTypes.func
}

Row.defaultProps = {
  level: 1
}

const Rows = ({ items = [], visibleItems, toggleChildren, level, parent }) =>
  items?.map(item => (
    <Row
      key={item.id}
      item={item}
      level={level}
      visibleItems={visibleItems}
      toggleChildren={toggleChildren}
      parent={parent}
    />
  ))

Rows.propTypes = {
  items: PropTypes.array,
  visibleItems: PropTypes.object,
  toggleChildren: PropTypes.func,
  level: PropTypes.number,
  parent: PropTypes.string
}

Rows.defaultProps = {
  level: 1
}

export default Rows
