const sortOptions = {
  en: [
    { value: 'LAST_PUBLISHED', label: 'Last published' },
    { value: 'LAST_UPDATED', label: 'Last updated' },
    { value: 'LAST_COMMENTED', label: 'Last commented' },
    { value: 'EARLIEST_PUBLISHED', label: 'Earliest published' },
    { value: 'MOST_COMMENTS', label: 'Most comments' },
    { value: 'MOST_LIKES', label: 'Most likes' },
    { value: 'EARLIEST_DEADLINE', label: 'Earliest deadline' },
    { value: 'MOST_POPULAR', label: 'Most popular' }
  ],
  sv: [
    { value: 'LAST_PUBLISHED', label: 'Senast publicerad' },
    { value: 'LAST_UPDATED', label: 'Senast uppdaterad' },
    { value: 'LAST_COMMENTED', label: 'Senast kommenterad' },
    { value: 'EARLIEST_PUBLISHED', label: 'Tidigast publicerad' },
    { value: 'MOST_COMMENTS', label: 'Flest kommentarer' },
    { value: 'MOST_LIKES', label: 'Mest gillar' },
    { value: 'EARLIEST_DEADLINE', label: 'Tidigast deadline' },
    { value: 'MOST_POPULAR', label: 'Mest populär' }
  ]
}

export const getSortOptions = lang => sortOptions[lang.toLowerCase()]
