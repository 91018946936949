export const DND_UPDATE_INIT = 'DND/UPDATE_INIT'

/** @typedef {import('../../swagger/types').IdeaResource} IdeaResource */
/** @typedef {import('../../swagger/types').UpdateIdeaRequest} UpdateIdeaRequest */

/**
 * @param {IdeaResource} updatedItem - item to be replaced in store
 * @param {UpdateIdeaRequest} apiItem - item to be sent to API
 * @param {string} url - URL to send API request to
 * @param {{dataType: string, context?: string}[]} dataToInvalidate - data to invalidate
 * @param {{source: string, target: string}} filterUrls - filterUrls to move item beetween
 * @returns {{type: string, updatedItem: IdeaResource, apiItem: UpdateIdeaRequest, dataToInvalidate:string[]}} action
 */
export const updateDataInit = (
  updatedItem,
  apiItem,
  url,
  dataToInvalidate,
  filterUrls
) => ({
  type: DND_UPDATE_INIT,
  updatedItem,
  apiItem,
  url,
  dataToInvalidate,
  filterUrls
})

export const DND_UPDATE_DATA = 'DND/UPDATE_DATA'

/**
 * @param {IdeaResource} updatedItem
 * @returns {{type: string, updatedItem: IdeaResource}} action
 */
export const updateData = updatedItem => ({
  type: DND_UPDATE_DATA,
  dataType: 'ideas',
  updatedItem
})

export const DND_INVALIDATE_DATA = 'DND/INVALIDATE_DATA'
/**
 * @param {string} dataType
 * @param {string} [context]
 */
export const invalidateData = (dataType, context) => ({
  type: DND_INVALIDATE_DATA,
  dataType,
  context
})

export const DND_UPDATE_FILTERS = 'DND/UPDATE_FILTERS'
/**
 * @param {string} dataType
 * @param {{source: string, target: string}} filters
 * @param {string} itemId - id of item to be moved beetween filters
 */
export const updateFilters = (dataType, filters, itemId) => ({
  type: DND_UPDATE_FILTERS,
  dataType,
  filters,
  itemId
})

export const actionTypes = {
  DND_UPDATE_DATA,
  DND_UPDATE_INIT,
  DND_INVALIDATE_DATA,
  DND_UPDATE_FILTERS
}

const actionCreators = {
  updateDataInit,
  updateData,
  invalidateData,
  updateFilters
}

export default actionCreators
