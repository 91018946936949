import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2'
import PropTypes from 'prop-types'

import { useData, useLanguage } from '../../../hooks'
import {
  getDashboardUrl,
  isFetchingDashboard,
  getDashboardData,
  getKpisUrl,
  getKpisList,
  isFetchingKpis
} from '../../../selectors'
import actions from '../../../state/actions/data'
import { getMonthsNamesArray } from './helpers'
import { Loading } from '../../Common'
import YearSelector from './YearSelector'

const ImprovementsPerEmployeeChart = ({
  dataForChart,
  lng,
  t,
  year,
  targetValue
}) => {
  const months = getMonthsNamesArray(lng)

  const isCurrentYear = year === new Date().getFullYear()
  const currentMonth = new Date().getMonth()

  const getBackgroundColor = idx =>
    isCurrentYear && idx > currentMonth
      ? 'rgba(255, 218, 0, 0.2)'
      : 'rgba(255, 218, 0, 0.6)'

  const getBorderColor = idx =>
    isCurrentYear && idx > currentMonth
      ? 'rgba(255, 218, 0, 0.6)'
      : 'rgba(255, 218, 0, 1)'

  const data = {
    labels: [...months],
    datasets: [
      {
        label: t('improvementsChart.labels.numOfImprovements'),
        backgroundColor: months.map((_, idx) => getBackgroundColor(idx)),
        borderColor: months.map((_, idx) => getBorderColor(idx)),
        borderWidth: 1,
        data: [...dataForChart]
      },
      {
        type: 'line',
        label: t('improvementsChart.labels.target'),
        data: new Array(12).fill(targetValue),
        backgroundColor: 'rgba(32, 113, 181, 1)',
        borderColor: 'rgba(32, 113, 181, 1)',
        pointRadius: 0
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true
      },
      title: {
        display: true,
        text: t('improvementsChart.title') + ` [${year}]`
      }
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: targetValue + 0.2,
        ticks: {
          stepSize: 0.2
        }
      }
    }
  }

  return <Bar data={data} options={options} />
}

ImprovementsPerEmployeeChart.propTypes = {
  dataForChart: PropTypes.arrayOf(PropTypes.number),
  lng: PropTypes.string,
  t: PropTypes.func,
  year: PropTypes.number,
  targetValue: PropTypes.number
}

const ImprovementsPerEmployeeChartContainer = () => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const [year, setYear] = useState(new Date().getFullYear())
  const [targetValue, setTargetValue] = useState()

  const { data, isFetching } = useData(
    'dashboard',
    {
      lng,
      year
    },
    getDashboardUrl,
    getDashboardData,
    actions.requestPreInit,
    isFetchingDashboard
  )

  const { data: kpisData } = useData(
    'kpis',
    null,
    getKpisUrl,
    getKpisList,
    actions.requestPreInit,
    isFetchingKpis
  )

  useEffect(() => {
    if (!!kpisData?.length) {
      const currentYear = new Date().getFullYear()
      const currentTarget =
        kpisData.find(item => item.year === currentYear)?.kpi ??
        kpisData.at(-1).kpi // if there is no data for current year, take the last year's data

      setYear(currentYear)
      setTargetValue(parseFloat(currentTarget))
    }
  }, [kpisData])

  const getYearOptions = (apiOptions = []) => {
    if (!!apiOptions?.length) {
      const currentYear = new Date().getFullYear()
      const currentYearIndex = apiOptions.findIndex(
        item => item.year === currentYear
      )

      return apiOptions
        .slice(Math.max(0, currentYearIndex - 5), currentYearIndex + 1)
        .map(item => ({
          label: item.year,
          value: item.year,
          target: parseFloat(item.kpi)
        }))
    }

    return []
  }

  const options = getYearOptions(kpisData)

  const handleYearChange = year => {
    setYear(year)

    const target = options.find(item => item.value === year).target
    setTargetValue(parseFloat(target))
  }

  const dataForChart = data?.map(item => parseFloat(item.number)) ?? []

  return (
    <>
      <YearSelector
        currentYear={year}
        setYear={handleYearChange}
        options={options}
        isLoading={isFetching}
      />

      {!data && isFetching ? (
        <Loading />
      ) : (
        <ImprovementsPerEmployeeChart
          dataForChart={dataForChart}
          lng={lng}
          t={t}
          year={year}
          targetValue={targetValue}
        />
      )}
    </>
  )
}

export default ImprovementsPerEmployeeChartContainer
