import React from 'react'

import './card.scss'

export const Card = ({ children, className = '', ...rest }) => (
  <div className={`card ${className}`} data-test='card' {...rest}>
    {children}
  </div>
)

export const CardActions = ({ children, className, ...rest }) => (
  <div
    className={`card__actions ${className}`}
    data-test='card-actions'
    {...rest}>
    {children}
  </div>
)

export const CardActionItem = ({ children, ...rest }) => (
  <div className='card__actions__item' {...rest}>
    {children}
  </div>
)

export const CardImage = ({ children }) => (
  <div className='card__image' data-test='card-img'>
    {children}
  </div>
)

export const CardContent = ({ title, body }) => (
  <div className='card__content'>
    <h4 className='card__content__title' data-test='card-title'>
      {title}
    </h4>
    {body && (
      <p className='card__content__body' data-test='card-body'>
        {body}
      </p>
    )}
  </div>
)

export const CardInfo = ({ children }) => (
  <div className='card__characteristics' data-test='card-characteristics'>
    {children}
  </div>
)

export default Card
