// @ts-check
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { KanbanBoard } from '../Components/KanbanBoard'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getFromSessionStorage } from '../Components/KanbanBoard/helpers'

const Page = () => {
  const history = useHistory()

  // on page load, check if there are any search params saved in session storage and load them if any (#61129).
  useEffect(() => {
    const searchParams = getFromSessionStorage()

    if (!searchParams) {
      return
    }

    const searchParamsMap = new Map(Object.entries(searchParams))
    const params = new URLSearchParams()

    for (const [key, value] of searchParamsMap) {
      params.set(key, value.toString())
    }

    // update URL without reloading the page (this will trigger the KanbanBoard component and SearchForm component to load with the search params).
    history.replace(`/kanban-board?${params.toString()}`)
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <KanbanBoard />
    </DndProvider>
  )
}

export default Page
