import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { useData, useLanguage } from '../../../hooks'
import { getPageUrl, getPageDetails } from '../../../selectors'
import actions from '../../../state/actions/data'
import { dataTypes } from '../../../configurations'
import Form from './Form'
import { languages } from '../challenge/Form'
import {
  handleDraftSubmitTemplate,
  convertFileForApi,
  handleSubmitTemplate
} from '../../../helpers/forms'
import { mergeUrlWithParams } from '../../../helpers/url'
import { ReactComponent as InfoIcon } from '../../../assets/icons/VF_light_bulb_rgb.svg'
import { getHomePageConfig } from '../../../routes'

const EditForm = ({ pageId, notificationsTrigger }) => {
  const dispatch = useDispatch()
  const defaultLanguage = useLanguage()
  const [lng, setLng] = useState(defaultLanguage)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: item } = useData(
    dataTypes.pageDetails.name,
    { lng },
    getPageUrl(pageId),
    getPageDetails,
    actions.requestPreInit
  )

  const validationSchema = Yup.object({
    title: Yup.string(),
    description: Yup.string(),
    language: Yup.string()
      .oneOf(languages.map(({ value }) => value))
      .required('required'),
    showVideo: Yup.bool(),
    // heroImage: Yup.array().required('Required')
    heroImage: Yup.array()
  })

  const initialValues = {
    title: item?.title || '',
    description: item?.description || '',
    showVideo: !!item?.showVideo,
    heroImage: [item?.heroImage].filter(item => item),
    language: item?.language || 'EN'
  }

  const handleCountryChange = (_, value) => setLng(value)

  const formatContext = (url, pageId, lng) => {
    const base = url.split('?')[0]
    const context = mergeUrlWithParams({ pageId, lng })(`${base}{?}`)

    return context
  }

  const sendDataToApi = data => {
    setIsSubmitting(true)
    const url = item._links?.update?.href || ''
    const context = formatContext(
      item._links.masterNotMaster?.href || '',
      item.masterId,
      lng
    )
    const contextDraft = formatContext(
      item._links.self.href || '',
      item.template,
      lng
    )

    const handleNotificationTrigger = res => {
      const id = res.dirtyContent ? res.template : res.masterId
      setIsSubmitting(false)
      notificationsTrigger({
        id
      })
    }

    const dataToInvalidate = [
      { dataType: 'page-details', context },
      { dataType: 'page-details', context: contextDraft }
    ]

    dispatch(
      actions.updateDataPreInit(
        url,
        data,
        'page-details',
        dataToInvalidate,
        handleNotificationTrigger
      )
    )
  }

  const isNewFile = file => file.constructor.name === 'File'

  async function formatData(values) {
    let heroImage = null
    let heroImageId = null

    if (!!values.heroImage.length) {
      if (isNewFile(values.heroImage[0])) {
        const heroImageFile = values.heroImage[0]
        heroImage = await convertFileForApi(heroImageFile)
      } else {
        heroImageId = values.heroImage[0].id
      }
    }

    return { ...values, heroImage, heroImageId }
  }

  const handleDraftSubmit = handleDraftSubmitTemplate(formatData, sendDataToApi)
  const handleSubmit = handleSubmitTemplate(formatData, sendDataToApi)
  const dirtyContent = item?.dirtyContent
  const home = getHomePageConfig()
  const isHome = item?.masterId === home.pageId

  return item ? (
    <>
      {dirtyContent && (
        <p style={{ margin: 0, padding: 20, borderLeft: '3px solid orange' }}>
          <InfoIcon
            width='35'
            style={{ verticalAlign: 'bottom', marginRight: 20 }}
          />
          There are unpublished changes on this page.
        </p>
      )}
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleDraftSubmit={handleDraftSubmit}
        handleCountryChange={handleCountryChange}
        showLanguageDdl
        inProgress={isSubmitting}
        isHome={isHome}
      />
    </>
  ) : null
}

EditForm.defaultProps = {
  notificationsTrigger: () => {}
}

EditForm.propTypes = {
  pageId: PropTypes.string,
  notificationsTrigger: PropTypes.func
}

export default EditForm
