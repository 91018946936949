import React from 'react'
import { VfContainer } from '../../Components/DesignSystem/Utils'
import { CreateForm } from '../../Components/cms/team'
import { WithNotification } from '../../Components/cms/challenge/Notifications'
import { useTranslation } from 'react-i18next'

const CreateChallenge = () => {
  const { t } = useTranslation()
  const FormWithNotifications = <WithNotification Component={CreateForm} />

  return (
    <VfContainer>
      <h1 className='my-4'>{t('titles.createTeam')}</h1>
      {FormWithNotifications}
    </VfContainer>
  )
}

export default CreateChallenge
