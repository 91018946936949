import { actionTypes } from '../actions/other'

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_FORM_PARAMS:
      return action.payload
    default:
      return state
  }
}
