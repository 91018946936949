import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const LengthCounter = ({ maxLength, currentLength, cssClass }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`${cssClass} ${currentLength > maxLength ? 'warning' : ''}`}>
      {currentLength}/{maxLength}
      {currentLength > maxLength && (
        <div>
          {t('common.textTooLong', {
            count: maxLength
          })}
        </div>
      )}
    </div>
  )
}

LengthCounter.propTypes = {
  maxLength: PropTypes.number,
  currentLength: PropTypes.number,
  cssClass: PropTypes.string
}

export default LengthCounter
