import React from 'react'
import PropTypes from 'prop-types'
import { VfContainer } from '../../Components/DesignSystem/Utils'
import { EditForm } from '../../Components/cms/team'
import { WithNotification } from '../../Components/cms/challenge/Notifications'
import { useTranslation } from 'react-i18next'

const EditChallenge = ({ computedMatch }) => {
  const { t } = useTranslation()
  const { challengeId } = computedMatch.params
  const FormWithNotifications = (
    <WithNotification Component={EditForm} challengeId={challengeId} />
  )
  return (
    <VfContainer>
      <h1 className='my-4'>{t('titles.editTeam')}</h1>
      {FormWithNotifications}
    </VfContainer>
  )
}

EditChallenge.propTypes = {
  // reactRouter
  computedMatch: PropTypes.object
}

export default EditChallenge
