import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './inline-list.scss'

export const NewItem = ({ children, className = '', ...props }) => (
  <div
    className={`inline-list__item inline-list__form ${className}`}
    {...props}>
    {children}
  </div>
)

NewItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export const InlineListItem = ({
  isEdited = false,
  EditForm,
  Ctas,
  children,
  className = '',
  customStyle = {},
  ...props
}) => (
  <div className={`inline-list__item ${className}`} style={customStyle}>
    {isEdited && !!EditForm ? (
      <div className='inline-list__form'>
        <EditForm {...props} />
      </div>
    ) : (
      children
    )}

    {!isEdited && !!Ctas && (
      <div className='inline-list__item__ctas'>
        <Ctas {...props} />
      </div>
    )}
  </div>
)

InlineListItem.propTypes = {
  isEdited: PropTypes.bool,
  EditForm: PropTypes.elementType,
  Ctas: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  customStyle: PropTypes.object
}

const InlineList = ({ items = [], Item, ...props }) => {
  const { t } = useTranslation()

  return !!items.length ? (
    <div className='mt-2 inline-list'>
      {items.map(item => (
        <Item key={item.id || item.name} item={item} {...props} />
      ))}
    </div>
  ) : (
    <p className='mt-2'>{t('common.sorryNoItems')}</p>
  )
}

InlineList.propTypes = {
  items: PropTypes.array,
  Item: PropTypes.elementType.isRequired
}

export default InlineList
