// @ts-check

import React from 'react'
import PropTypes from 'prop-types'

const TextInput = ({
  field,
  label,
  placeholder = '',
  className = '',
  form: { errors, touched, validateField },
  disabled = false,
  maxLength
}) => {
  const hasError = !!errors[field.name] && touched[field.name]

  return (
    <>
      {label && (
        <label htmlFor={field.name} className='mt-2'>
          {label}
        </label>
      )}

      <div className='vf-input-container'>
        <input
          type='text'
          disabled={disabled}
          id={field.name}
          placeholder={placeholder}
          className={`vf-input w-100 ${
            hasError ? 'vf-input--error' : ''
          } ${className}`}
          {...field}
          // wp: this needs to be last
          onBlur={() => validateField(field.name)}
          maxLength={maxLength}
        />
      </div>

      {hasError && (
        <div className='vf-utility-typo--error mt-1'>{errors[field.name]}</div>
      )}
    </>
  )
}

TextInput.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  className: PropTypes.string
}

export default TextInput
