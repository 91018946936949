import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import Picker from '../../Common/Picker'
import { useData } from '../../../hooks'
import { getDepartmentsList, getDepartmentsListUrl } from '../../../selectors'
import actions from '../../../state/actions/data'
import { dataTypes } from '../../../configurations'

const DepartmentsPickerContainer = ({
  field,
  label,
  form: { setFieldValue, setFieldTouched }
}) => {
  const { data } = useData(
    dataTypes.departments.name,
    null,
    getDepartmentsListUrl,
    getDepartmentsList,
    actions.requestPreInit
  )
  const [departments, setDepartments] = useState([])

  const formatDepartmentsForUi = (data = []) =>
    data.map(item => ({ value: item, label: item }))

  useEffect(() => {
    if (!!data) {
      const departments = formatDepartmentsForUi(data)
      setDepartments(departments)
    }
  }, [data])

  const emptyValue = []

  const handleChange = value => {
    setFieldValue(field.name, value || emptyValue)
  }

  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }

  return (
    !!departments && (
      <Picker
        options={departments}
        name={field.name}
        value={field.value}
        label={label}
        handleBlur={handleBlur}
        handleChange={handleChange}
        isMulti={false}
        isClearable
        className='department-picker'
      />
    )
  )
}

DepartmentsPickerContainer.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func
  })
}

export default DepartmentsPickerContainer
