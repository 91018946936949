// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  StarsPainter,
  LikesPainter,
  CommentsSummary,
  IdeaLink
} from '../../Common'
import { getChallengeTheme } from '../../../helpers/color'

const Item = ({
  item: {
    id,
    title,
    starsCount,
    likesCount,
    commentsCount,
    challenge: { id: challengeId },
    ideaState
  },
  showState
}) => {
  const { t } = useTranslation()
  const colors = getChallengeTheme(challengeId)

  return (
    <div
      className='vf-col-12 my-2 px-2 ideas-list__item'
      style={{ borderColor: colors.bar }}
    >
      <p className='vf-utility-typo--link mb-0'>
        <IdeaLink id={id}>{title}</IdeaLink>
      </p>

      {showState && (
        <span className='ideas-list__item__state mb-0'>
          {t('ideaStates.' + ideaState.toLowerCase())}
        </span>
      )}

      <div className='mt-1 mb-0 idea-opinions'>
        <StarsPainter rating={starsCount} />
        <LikesPainter count={likesCount} />
        <CommentsSummary count={commentsCount} t={t} />
      </div>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    starsCount: PropTypes.number,
    likesCount: PropTypes.number,
    commentsCount: PropTypes.number,
    challenge: PropTypes.object,
    ideaState: PropTypes.string
  }),
  showState: PropTypes.bool
}

Item.defaultProps = {
  showState: false
}

export default Item
