import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  getActiveCategories,
  getCategoriesSortedByName,
  getFirstCategory,
  getInactiveCategories
} from './helpers'
import InlineList from '../../Common/InlineList'
import Item from './Item'
import { Loading } from '../../Common'
import CreateNewCategory from './Form/Create'
import { useData } from '../../../hooks'
import { dataTypes } from '../../../configurations'
import {
  getTagCategoriesList,
  getTagCategoriesListUrl,
  isFetchingTagCategories
} from '../../../selectors'
import actions from '../../../state/actions/data'

const ListContainer = () => {
  const { t } = useTranslation()
  const [categories, setCategories] = useState([])

  const { data, isFetching } = useData(
    dataTypes.tagCategories.name,
    null,
    getTagCategoriesListUrl,
    getTagCategoriesList,
    actions.requestPreInit,
    isFetchingTagCategories
  )

  useEffect(() => {
    if (!!data) {
      setCategories(data)
    }
  }, [data])

  const createCategoryOptimisticUI = newCategory =>
    setCategories(prev => [...prev, newCategory])

  const editCategoryOptimisticUI = category => {
    const newCategories = categories.map(item => {
      if (item.id === category.id) {
        return category
      }

      return item
    })

    setCategories(newCategories)
  }

  if (!categories.length && isFetching) {
    return <Loading />
  }

  const firstCategory = getFirstCategory(categories)

  const activeCategories = getCategoriesSortedByName(
    getActiveCategories(categories)
  )
  const inactiveCategories = getCategoriesSortedByName(
    getInactiveCategories(categories)
  )

  return (
    <>
      <CreateNewCategory
        categories={categories}
        createCategoryOptimisticUI={createCategoryOptimisticUI}
      />

      {!!firstCategory && (
        <>
          <h3 className='mt-3'>{t('admin.tagCategories.titles.first')}</h3>

          <InlineList
            items={[firstCategory]}
            categories={categories}
            Item={Item}
            editCategoryOptimisticUI={editCategoryOptimisticUI}
          />
        </>
      )}

      {!!activeCategories.length && (
        <>
          <h3 className='mt-3'>{t('admin.tagCategories.titles.active')}</h3>

          <InlineList
            items={activeCategories}
            categories={categories}
            Item={Item}
            editCategoryOptimisticUI={editCategoryOptimisticUI}
          />
        </>
      )}

      {!!inactiveCategories.length && (
        <>
          <h3 className='mt-2'>{t('admin.tagCategories.titles.inactive')}</h3>

          <InlineList
            items={inactiveCategories}
            categories={categories}
            Item={Item}
            editCategoryOptimisticUI={editCategoryOptimisticUI}
          />
        </>
      )}
    </>
  )
}

export default ListContainer
