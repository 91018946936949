// @ts-check

// TO-DO move this component into DesignSystem folder

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * @deprecated Use DropDownv2 instead. Migration does not require any changes in the props.
 * @description Use with:
 * ```js
 * useVfAlchemyScript()
 * useDropDownFix('.search-form__container')
 * ```
 */
const DropDown = ({
  field,
  label,
  form: { setFieldValue, errors, touched },
  form,
  options = [],
  onChange = (a, b) => {}
}) => {
  const [defaultLabel, setDefaultLabel] = useState('Select option')

  useEffect(() => {
    const optionSelected = options.find(
      item => item.value === field.value
    )?.label

    if (optionSelected !== undefined) {
      setDefaultLabel(optionSelected)
    }
  }, [options, field.value])

  const handleChange = value => {
    onChange(form, value)
    setFieldValue(field.name, value)
  }

  const hasError = !!errors[field.name] && touched[field.name]

  return (
    <>
      {label && <label className='my-2'>{label}</label>}

      <div className='vf-dropdown w-100' data-control='vf-dropdown-data'>
        <label
          className={`vf-dropdown-label pr-4 ${
            hasError ? 'vf-dropdown-label--error' : ''
          }`}
          data-test='dropdown-label'>
          {defaultLabel}
        </label>

        <div
          className='vf-dropdown-list'
          style={{ zIndex: 1, overflowY: 'auto' }}>
          {options.map((option, idx) => (
            <div
              key={idx}
              className={`vf-dropdown-list-item ${
                option.value === field.value ? 'checked' : ''
              }`}
              onClick={() => handleChange(option.value)}>
              <label
                className='vf-dropdown-option'
                style={{
                  maxWidth: 'none',
                  wordBreak: 'break-word'
                }}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {hasError && (
        <div className='vf-utility-typo--error mt-2'>{errors[field.name]}</div>
      )}
    </>
  )
}

DropDown.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func
}

export default DropDown
