import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ActionButton from '../../../Common/ActionButton'
import { ReactComponent as DeactiveIcon } from '../../../../assets/icons/remove_circle.svg'
import { handleEdit } from '../Form/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getTagCategoriesListUrl, getTagsListUrl } from '../../../../selectors'

const DeactiveBtn = ({ item, editCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const rawUrl = useSelector(getTagsListUrl)
  const tagCategoriesContext = useSelector(getTagCategoriesListUrl)

  const handleDeactivate = () =>
    handleEdit(
      item,
      dispatch,
      rawUrl,
      tagCategoriesContext,
      editCallback
    )({
      id: item.id,
      name: item.name,
      categoryId: item.categoryId,
      active: false
    })

  return (
    <ActionButton
      title={t('admin.tags.buttons.deactivate')}
      onClick={handleDeactivate}>
      <DeactiveIcon height={16} />
    </ActionButton>
  )
}

DeactiveBtn.propTypes = {
  item: PropTypes.object,
  editCallback: PropTypes.func
}

export default DeactiveBtn
