// @ts-check
import { UserAgentApplication } from 'msal'
const clientId = 'dc1a40f8-bf6d-4671-b413-b35c4e60584c'

const msalConfig = {
  auth: {
    clientId, // This is your client ID
    authority:
      'https://login.microsoftonline.com/f8be18a6-f648-4a47-be73-86d6c5c6604d', // This is your tenant info
    postLogoutRedirectUri: window.location.origin, // wp: need this or log-out -> log-in scenario won't work for other url than '/'
    redirectUri: window.location.origin // wp: need this or log-out -> log-in scenario won't work for other url than '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
}

// create a request object for login or token request calls
// In scenarios with incremental consent, the request object can be further customized
const requestObj = {
  scopes: [clientId]
}

// @ts-ignore
const myMSALObj = new UserAgentApplication(msalConfig)

function authCallback(error, response) {
  if (error) {
    console.log(error)
  }
  // else {
  // console.log('token type is:' + response)
  // }
}

myMSALObj.handleRedirectCallback(authCallback)

function acquireTokenRedirect() {
  myMSALObj
    .acquireTokenSilent(requestObj)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      // console.log('Aquired', accessTokenResponse)
    })
    .catch(function (error) {
      // Acquire token silent failure, send an interactive request.
      // console.log('No aquired', error)
      if (error.errorMessage.indexOf('interaction_required') !== -1) {
        myMSALObj.acquireTokenRedirect(requestObj)
      }
    })
}

if (myMSALObj.getAccount() && !myMSALObj.isCallback(window.location.hash)) {
  // avoid duplicate code execution on page load in case of iframe and popup window.
  acquireTokenRedirect()
}

export const getToken = () => myMSALObj.acquireTokenSilent(requestObj)
export const login = () => myMSALObj.loginRedirect(requestObj)
export const logout = () => myMSALObj.logout()

export const getCurrentUserId = () => {
  const user = myMSALObj.getAccount()
  return !!user ? user.idToken.oid : null
}
