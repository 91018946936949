import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EditForm from './Form/Edit'
import { ActivateBtn, DeactivateBtn, DeleteBtn } from './Ctas'
import ActionButton from '../../Common/ActionButton'
import { ReactComponent as EditIcon } from '../../../assets/icons/VF_edit_rgb.svg'
import { getApiErrorMessage } from './Form/helpers'
import { InlineListItem } from '../../Common/InlineList'

const Ctas = ({
  item,
  setIsEdited,
  editCallback,
  deleteCallback,
  readOnly = false
}) => {
  const { t } = useTranslation()

  const isEditableAndActive = !readOnly && item.active
  const isEditableAndInactive = !readOnly && !item.active

  return (
    <>
      {isEditableAndActive && (
        <>
          <ActionButton
            title={t('common.edit')}
            onClick={() => setIsEdited(true)}>
            <EditIcon height={16} />
          </ActionButton>
          <DeactivateBtn item={item} editCallback={editCallback} />
        </>
      )}

      {isEditableAndInactive && (
        <ActivateBtn item={item} editCallback={editCallback} />
      )}

      <DeleteBtn item={item} deleteCallback={deleteCallback} />
    </>
  )
}

Ctas.propTypes = {
  item: PropTypes.shape({
    active: PropTypes.bool
  }),
  setIsEdited: PropTypes.func,
  editCallback: PropTypes.func,
  deleteCallback: PropTypes.func,
  readOnly: PropTypes.bool
}

const Item = ({
  item,
  apiError,
  setApiError,
  editCallback,
  handleCancelEdit,
  ...props
}) => (
  <InlineListItem
    EditForm={props => (
      <EditForm
        item={item}
        apiError={apiError}
        editCallback={editCallback}
        handleCancel={handleCancelEdit}
        {...props}
      />
    )}
    Ctas={props => <Ctas item={item} editCallback={editCallback} {...props} />}
    className={!item.active ? 'inline-list__item--inactive' : ''}
    {...props}>
    <div>
      <span>{item.name}</span>
      {apiError && (
        <div className='vf-utility-typo--error'>
          {apiError}{' '}
          <span style={{ cursor: 'pointer' }} onClick={() => setApiError(null)}>
            &times;
          </span>
        </div>
      )}
    </div>
  </InlineListItem>
)

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool
  }),
  apiError: PropTypes.string,
  setApiError: PropTypes.func,
  editCallback: PropTypes.func,
  handleCancelEdit: PropTypes.func
}

const ItemContainer = ({
  item,
  editTagOptimisticUI,
  deleteTagOptimisticUI,
  readOnly
}) => {
  const { t } = useTranslation()
  const [isEdited, setIsEdited] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleCancelEdit = () => {
    setApiError(null)
    setIsEdited(false)
  }

  const editCallback = (newItem, formikActions) => res => {
    if (!!res.error) {
      const errorMessage = getApiErrorMessage(res, t)

      if (!!formikActions) {
        // this callback can be called without formik actions (activate/deactivate buttons)
        formikActions.setSubmitting(false)
      }
      setApiError(errorMessage)
    } else {
      setApiError(null)
      editTagOptimisticUI({ ...item, ...newItem })
      setIsEdited(false)
    }
  }

  const deleteCallback = res => tagId => {
    if (!!res.error) {
      setApiError(getApiErrorMessage(res, t))
    } else {
      setApiError(null)
      deleteTagOptimisticUI(tagId)
    }
  }

  return (
    <Item
      item={item}
      isEdited={isEdited}
      setIsEdited={setIsEdited}
      apiError={apiError}
      setApiError={setApiError}
      editCallback={editCallback}
      deleteCallback={deleteCallback}
      handleCancelEdit={handleCancelEdit}
      readOnly={readOnly}
    />
  )
}

ItemContainer.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    _links: PropTypes.object
  }),
  editTagOptimisticUI: PropTypes.func,
  deleteTagOptimisticUI: PropTypes.func,
  readOnly: PropTypes.bool
}

export default ItemContainer
