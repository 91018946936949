import React from 'react'
import { useTranslation } from 'react-i18next'

import './not-aurhorized.scss'

const NotAuthorizedPage = () => {
  const { t } = useTranslation()
  return (
    <div className='page-not-authorized__container'>
      <h2>{t('not-authorized.title')}</h2>

      <h1>401</h1>

      <p>{t('not-authorized.message')}</p>
    </div>
  )
}

export default NotAuthorizedPage
