import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { onlyDateLocal } from '../../../helpers/date'
import { icons } from '../../../assets/icons'
import { CommentsSummary, IdeaLink, StarsPainter, Tags } from '../../Common'

import './table-item.scss'

const TableItem = ({ item, toggleEditForm = () => {} }) => {
  const { t } = useTranslation()

  const teamName =
    item.localizedChallengeInfo.title || item.localizedChallengeInfo.titles?.EN
  const status = item.ideaState
    ? t('ideaStates.' + item.ideaState.toLowerCase())
    : '-'

  const description = !!item.description ? item.description.slice(0, 200) : ''

  return (
    <div className='table-item'>
      <IdeaLink id={item.id}>
        <div className='table-item__title-container'>
          <div className='table-item__title'>{item.title}</div>
          <div className='mb-1 idea-opinions'>
            <StarsPainter rating={item.starsCount} />
            <CommentsSummary count={item.commentsCount} t={t} />
          </div>
        </div>

        <div className='table-item__characteristics'>
          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.improvementTeam')}
            </div>

            <div>{teamName}</div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.status')}
            </div>

            <div className='table-item__characteristics__status'>{status}</div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.author')}
            </div>

            <div>{item.author.displayName}</div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.assignee')}
            </div>

            <div>{item.assignee?.displayName ?? '-'}</div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.published')}
            </div>

            <div>{onlyDateLocal(item.published)}</div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.deadline')}
            </div>

            <div>{item.deadline ? onlyDateLocal(item.deadline) : '-'}</div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.completed')}
            </div>

            <div>
              {item.ideaState === 'COMPLETED'
                ? onlyDateLocal(item.statusChangedOn)
                : '-'}
            </div>
          </div>

          <div>
            <div className='table-item__characteristics__header'>
              {t('idea-table-view.headers.tags')}
            </div>

            <div className='table-item__characteristics__tags'>
              {item.tags?.length ? <Tags items={item.tags} /> : '-'}
            </div>
          </div>

          <div
            onClick={e => {
              e.preventDefault()
              toggleEditForm(item)
            }}
            className='edit-button__icon'>
            <img src={icons.Edit} alt={t('common.edit')} />
          </div>
        </div>
      </IdeaLink>
      <div className='table-item__more-info'>
        <div>
          <div className='table-item__characteristics__header'>
            {t('idea-table-view.headers.description')}
          </div>

          <div>{description}</div>
        </div>
      </div>
    </div>
  )
}

TableItem.propTypes = {
  item: PropTypes.object,
  toggleEditForm: PropTypes.func
}

export default TableItem
