import React from 'react'
import { useTranslation } from 'react-i18next'

import { VfContainer } from '../Components/DesignSystem/Utils'
import { VfTextButton } from '../Components/DesignSystem/Buttons'
import image from '../assets/images/404.svg'
import './404.scss'

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <VfContainer className='no-page__container'>
      <h1>{t('not-found.title')}</h1>
      <div className='vf-row no-page__row vf-row--vertical-center'>
        <div className='vf-col-sm mt-3'>
          <img className='no-page__container__image' src={image} alt='404' />
        </div>
        <div className='vf-col-sm mt-3'>
          <p>{t('not-found.subtitle')}</p>
          <p>{t('not-found.message')}</p>
          <VfTextButton to='/' text={t('not-found.backToHome')} />
        </div>
      </div>
    </VfContainer>
  )
}

export default PageNotFound
