import React from 'react'

const useVfAlchemyScript = dependency => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://cdn.jsdelivr.net/npm/@vf-alchemy/vattenfall-design-system@7.12.4/dist/js/horizon.min.js'
    script.async = true
    // script.crossOrigin = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [dependency])
}

export default useVfAlchemyScript
