import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { getKpisPostUrl } from '../../../selectors'
import { Message, SubmitButton, TextInput } from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import actions from '../../../state/actions/data'
import { mergeUrlWithParams } from '../../../helpers/url'
import { validationSchema } from './helpers'

export const formModes = {
  create: 'create',
  edit: 'edit'
}

const FormComponent = ({ initialValues, handleSubmit, message }) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <Field
            name='year'
            label={t('admin.kpis.labels.year')}
            component={TextInput}
          />
          <Field
            name='numberOfUsers'
            label={t('admin.kpis.labels.numberOfUsers')}
            component={TextInput}
          />
          <Field
            name='kpi'
            label={t('admin.kpis.labels.target')}
            component={TextInput}
          />

          <SubmitButton
            button={<VfButton text={t('common.submit')} className='mt-2' />}
            inProgress={isSubmitting}
            inProgressText={t('common.submitting')}
            disabled={!dirty || isSubmitting}
          />

          {message && <Message type={message.type}>{message.text}</Message>}
        </Form>
      )}
    </Formik>
  )
}

FormComponent.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  message: PropTypes.object
}

const FormContainer = ({ initialValues, closePanel, mode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const rawUrl = useSelector(getKpisPostUrl)

  const [message, setMessage] = useState(null)

  const handleSubmit = (values, { setSubmitting }) => {
    const url = mergeUrlWithParams(null)(rawUrl)
    const dataToInvalidate = [{ dataType: 'kpis', context: url }]

    const callback = res => {
      if (!!res.error) {
        setMessage({
          type: 'error',
          text: res.message || t('messages.somethingWentWrong')
        })
      } else {
        setMessage({
          type: 'success',
          text: t('messages.success')
        })

        if (mode === formModes.create) {
          closePanel()
          return
        }
      }

      setSubmitting(false)
    }

    dispatch(
      actions.createDataPreInit(
        url,
        values,
        'kpis',
        dataToInvalidate,
        callback,
        url
      )
    )
  }

  return (
    <FormComponent
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      message={message}
    />
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  closePanel: PropTypes.func,
  mode: PropTypes.string
}

export default FormContainer
