import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Card, CardImage, CardContent } from '../../Components/Common/Card'
import { ReactComponent as TagCategoriesIcon } from '../../assets/icons/VF_modules_rgb.svg'
import { ReactComponent as TagsIcon } from '../../assets/icons/VF_tag_rgb.svg'
import { ReactComponent as KpisIcon } from '../../assets/icons/VF_insight_rgb.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/VF_user_rgb.svg'

import './admin.scss'

const Admin = () => {
  const { t } = useTranslation()

  return (
    <>
      <h1>{t('titles.admin')}</h1>

      <div className='admin__links'>
        <Card className='admin__links__item'>
          <Link to='/admin/tag-categories'>
            <CardImage>
              <TagCategoriesIcon />
            </CardImage>

            <CardContent title={t('titles.tagCategories')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to='/admin/tags'>
            <CardImage>
              <TagsIcon />
            </CardImage>

            <CardContent title={t('titles.tags')} />
          </Link>
        </Card>

        <Card className='admin__links__item'>
          <Link to='/admin/kpis'>
            <CardImage>
              <KpisIcon />
            </CardImage>

            <CardContent title={t('titles.kpis')} />
          </Link>
        </Card>
        <Card className='admin__links__item'>
          <Link to='/admin/users'>
            <CardImage>
              <UserIcon />
            </CardImage>

            <CardContent title={t('titles.users')} />
          </Link>
        </Card>
      </div>
    </>
  )
}

export default Admin
