// @ts-check
import React from 'react'
import { useSelector } from 'react-redux'
import { getUrlFromConfig } from '../../../selectors'
import MSGraphSearch from '../MSGraphSearch'

const AssigneePicker = props => {
  const rawUrl = useSelector(getUrlFromConfig('profile-search'))

  return (
    <MSGraphSearch rawUrl={rawUrl} isMulti={false} isClearable {...props} />
  )
}

export default AssigneePicker
