// @ts-check
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  getChallengeDetailsById,
  getCreateIdeaUrl,
  getChallengeDetailsUrl
} from '../../selectors'
import { ChallengeLink } from '../Common'
import { CreateForm } from './IdeaForm'
import { mergeUrlWithParams } from '../../helpers/url'
import { useLanguage } from '../../hooks'

const Create = ({ selectedChallenge }) => {
  const { t } = useTranslation()
  const lng = useLanguage()

  const challengeRawUrl = useSelector(getChallengeDetailsUrl)
  const challengeUrl = mergeUrlWithParams({
    challengeId: selectedChallenge,
    lng
  })(challengeRawUrl)
  const challenge = useSelector(getChallengeDetailsById(challengeUrl))

  const createIdeaUrl = useSelector(getCreateIdeaUrl)

  const [formVisible, setFormVisible] = useState(true)
  const [messageType, setMessageType] = useState(null)

  const messages = {
    error: t('messages.somethingWentWrong'),
    success: (
      <>
        {t('messages.success')}
        {'.'} {t('messages.redirect')}{' '}
        <ChallengeLink id={selectedChallenge} title={t('common.challenge')} />.
      </>
    )
  }

  return (
    <>
      <h1 className='my-4'>{t('titles.createIdea')}</h1>
      <div className='vf-row'>
        <div className='vf-col-lg-8'>
          {formVisible && (
            <CreateForm
              t={t}
              challengeId={selectedChallenge}
              url={createIdeaUrl}
              notify={messageType => setMessageType(messageType)}
              hideForm={() => setFormVisible(false)}
            />
          )}

          {!!messageType && (
            <p className={`vf-utility-typo--${messageType} mt-3`}>
              {messages[messageType]}
            </p>
          )}
        </div>

        <div className='vf-col-lg-4'>
          {challenge && (
            <>
              <h3>{t('common.challenge')}</h3>
              <p>
                <ChallengeLink title={challenge.title} id={challenge.id} />
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

Create.propTypes = {
  selectedChallenge: PropTypes.string
}

export default Create
