import React from 'react'
import { PropTypes } from 'prop-types'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

export const selectSizes = {
  extraSmall: '30px',
  small: '40px',
  regular: '50px',
  auto: 'auto'
}

export const styles = {
  control: (styles, state) => {
    const { readOnly = false } = state.selectProps

    return {
      ...styles,
      height: state.isMulti ? selectSizes.auto : state.selectProps.size,
      borderColor: '#e8e8e9',
      minHeight: state.selectProps.size,
      minWidth:
        state.selectProps.size === selectSizes.small ? '130px' : '220px',
      cursor: readOnly ? 'auto' : 'pointer',
      boxShadow: readOnly ? 'none' : styles.boxShadow,
      '&:hover': {
        borderColor: readOnly ? '#e8e8e9' : styles.borderColor
      }
    }
  },
  valueContainer: (styles, state) => ({
    ...styles,
    padding:
      state.selectProps.size === selectSizes.extraSmall ? '0 8px' : '2px 8px'
  }),
  option: styles => ({
    ...styles,
    fontSize: '14px',
    cursor: 'pointer'
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    minHeight: state.selectProps.size,
    height: state.isMulti ? selectSizes.auto : state.selectProps.size
  }),
  menu: styles => ({ ...styles, zIndex: 9999 }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      opacity: data.disabled ? '0.5' : '1',
      textDecoration: data.disabled ? 'line-through' : 'none',
      ...(data.backgroundColor && { backgroundColor: data.backgroundColor })
    }
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
  })
}

const Picker = ({
  options = [],
  name,
  value,
  label,
  handleBlur,
  handleChange,
  isMulti = false,
  isClearable = false,
  className,
  size = selectSizes.regular
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {!!label && (
        <label htmlFor={name} className='mt-2'>
          {label}
        </label>
      )}

      <Select
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        placeholder={
          isMulti ? t('common.selectOptionMulti') : t('common.selectOption')
        }
        noOptionsMessage={() => t('messages.noResults')}
        styles={styles}
        size={size}
        className={className}
        isMulti={isMulti}
        isClearable={isClearable}
      />
    </div>
  )
}

Picker.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  label: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string
}

export default Picker
