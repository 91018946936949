// @ts-check

/**
 * @typedef {import('../../../types.d.ts').TagDto} TagDto
 */

/**
 * Filters tags by category ID.
 * @param {TagDto[]} items - The items to filter.
 * @param {number} categoryId - The ID of the category to filter by.
 * @returns {TagDto[]} The filtered items.
 */
export const getTagsByCategory = (items, categoryId) =>
  items.filter(item => item.category?.id === categoryId)

/**
 * Filters tags that do not have a category ID.
 * @param {TagDto[]} items - The items to filter.
 * @returns {TagDto[]} The filtered items.
 */
export const getUncategorizedTags = items =>
  items.filter(tag => !tag.category?.id)

/**
 * Sorts tags by their 'active' property and then by their 'name' property.
 * @param {TagDto[]} items - The items to sort.
 * @returns {TagDto[]} The sorted items.
 */
export const getSortedTags = items =>
  items.sort((a, b) => {
    if (Number(b.active) - Number(a.active) !== 0) {
      return Number(b.active) - Number(a.active)
    } else {
      return a.name.localeCompare(b.name)
    }
  })

/**
 * Sorts tags by their category ID. If tags have the same category ID, they are sorted by their name.
 * @param {TagDto} a - The first tag to compare.
 * @param {TagDto} b - The second tag to compare.
 * @returns {number} The sort order.
 */
export const sortTagsByCategory = (a, b) => {
  // Check if either tag is in the first category, prioritize them
  if (a.category?.firstCategory && !b.category?.firstCategory) {
    return -1
  }

  if (!a.category?.firstCategory && b.category?.firstCategory) {
    return 1
  }

  if (a.category?.id === b.category?.id) {
    return a.name.localeCompare(b.name)
  }

  if (a.category === null) {
    return 1
  }

  if (b.category === null) {
    return -1
  }

  return a.category?.name.localeCompare(b.category?.name)
}

/**
 * Filters tags that are in the first category.
 * @param {TagDto[]} items - The items to filter.
 * @returns {TagDto[]} The filtered items.
 */
export const getFirstCategoryTags = items =>
  items.filter(tag => tag.category?.firstCategory)

/**
 * Filters tags that are not in the first category.
 * @param {TagDto[]} items - The items to filter.
 * @returns {TagDto[]} The filtered items.
 */
export const getTagsWithoutFirstCategory = items =>
  items.filter(tag => !tag.category?.firstCategory)
