// @ts-check
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as BookmarkIcon } from '../../../../assets/icons/VF_trash_alt02_rgb.svg'
import { eventKey, getBookmarks, removeBookmark } from './helpers'

/** @typedef {import('./types').Bookmark} Bookmark */

/**
 * @param {Object} props
 * @param {Bookmark} props.item
 * @returns {JSX.Element}
 */
const Item = ({ item }) => {
  const history = useHistory()
  const location = useLocation()
  const { name, params } = item
  const isActive = location.search === `?${params}`

  /** @param {Bookmark} item  */
  const setParams = item => {
    // get params from item
    const { params } = item
    // set params to URL
    history.push(`/kanban-board?${params}`)
  }

  /** @param {Bookmark} item  */
  const clearParams = item => {
    // clear all params from params variable
    const { params } = item
    const tmp = new URLSearchParams(params)

    tmp.forEach((_, key) => tmp.set(key, ''))

    // set params to URL
    history.push(`/kanban-board?${tmp.toString()}`)
  }

  const handleClick = item => (isActive ? clearParams(item) : setParams(item))

  return (
    <button
      className={`bookmark ${isActive ? 'active' : ''}`}
      onClick={() => handleClick(item)}>
      <span className='bookmark__text'>{name}</span>
      <span
        className='bookmark__delete-btn'
        onClick={e => {
          e.stopPropagation()
          removeBookmark(item.name)
          clearParams(item)
        }}>
        <BookmarkIcon height={14} />
      </span>
    </button>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    params: PropTypes.string.isRequired
  }).isRequired
}

const List = () => {
  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)

  // listen to storage event - refresh List when bookmarks are updated in localStorage
  useEffect(() => {
    window.addEventListener(eventKey, toggleRefresh)
    return () => {
      window.removeEventListener(eventKey, toggleRefresh)
    }
  })

  // get bookmarks from localStorage
  const bookmarks = getBookmarks()

  return bookmarks.map(item => <Item key={item.name} item={item} />)
}

export default List
