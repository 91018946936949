import switchLargeIcon from '../assets/icons/switch-large.png'
import switchMediumIcon from '../assets/icons/switch-medium.png'
import switchSmallIcon from '../assets/icons/switch-small.png'

const layoutTypes = {
  table: { type: 'table', icon: switchMediumIcon, inSwitch: true },
  box: { type: 'box', icon: switchLargeIcon, inSwitch: true },
  large_item: { type: 'large_list', icon: switchMediumIcon, inSwitch: false },
  small_item: { type: 'small_list', icon: switchSmallIcon, inSwitch: false },
  side_item: { type: 'side_item' }
}

const layoutTypesArr = Object.keys(layoutTypes).map(item => layoutTypes[item])

export const switchLayoutTypes = layoutTypesArr.filter(
  ({ inSwitch }) => inSwitch
)

export default layoutTypes
