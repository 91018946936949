// @ts-check
import PropTypes from 'prop-types'

const ContentPage = ({ description, draftId }) => {
  // wp: description is now being displayed in template
  return null
}

ContentPage.propTypes = {
  description: PropTypes.string,
  draftId: PropTypes.string
}

ContentPage.defaultProps = {
  description: ''
}
export default ContentPage
