// @ts-check
import * as Yup from 'yup'

import { mergeUrlWithParams } from '../../../../helpers/url'
import actions from '../../../../state/actions/data'
import { dataTypes } from '../../../../configurations'

/**
 * @typedef {import('../../../../types.d.ts').TagCategoryDto} TagCategoryDto
 */

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .trim() // to ensure that the string is not only whitespaces
      .required(t('admin.tagCategories.validation.nameRequired')),
    color: Yup.string().required(
      t('admin.tagCategories.validation.colorRequired')
    ),
    active: Yup.bool()
  })

/**
 * Returns an error message based on the response code.
 * If the response code is 'ALREADY_EXIST', it returns a specific message indicating that the category already exists.
 * Otherwise, it returns a generic error message.
 * @param {object} res - The response object.
 * @param {string} res.code - The response code.
 * @param {string} res.message - The response message.
 * @param {Function} t - The translation function.
 * @returns {string} The error message.
 */
export const getApiErrorMessage = (res, t) => {
  return res.code === 'ALREADY_EXIST'
    ? t('admin.tagCategories.validation.alreadyExists')
    : t('messages.error')
}

/**
 * Handles the edit operation for a given item. It dispatches an action to update the data.
 * If the item has an update URL, it dispatches the updateDataPreInit action with the necessary parameters.
 * @param {Object} item - The tag to be edited.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {string} rawUrl - The raw URL for the list.
 * @param {string} tagsUrl - The URL for the tags list (used to invalidate the cache after the update).
 * @param {Function} callback - The callback function to be executed after the update.
 * @returns {Function} A function that takes the new values and form actions, and dispatches the update action.
 */
export const handleEdit =
  (item, dispatch, rawUrl, tagsUrl, callback) => (values, formActions) => {
    const updateUrl = item._links.update.href
    const listUrl = mergeUrlWithParams(null)(rawUrl)

    const dataToInvalidate = [
      { dataType: dataTypes.tagCategories.name, context: listUrl },
      { dataType: dataTypes.tags.name, context: tagsUrl },
      { dataType: dataTypes.ideas.name }
    ]

    if (!!updateUrl) {
      dispatch(
        actions.updateDataPreInit(
          updateUrl,
          values,
          dataTypes.tagCategories.name,
          dataToInvalidate,
          callback(values, formActions),
          listUrl
        )
      )
    }
  }

export const colorOptions = [
  '#BCC4CE',
  '#CAEFCA',
  '#D1E4DD',
  '#C5E4FF',
  '#ADF9FF',
  '#F1F8FF',
  '#E3D5EF',
  '#FFB2A4',
  '#FED8D1',
  '#EEC8C5',
  '#F3BCD2',
  '#FFDEA8',
  '#FCE4BD',
  '#FDF4CE'
]

/**
 * Checks if a color is used in categories or initial values.
 * @param {string} color - The color to check.
 * @param {TagCategoryDto[]} categories - The categories to check the color against.
 * @param {Object} initialValues - The initial values to check the color against.
 * @returns {boolean} - Returns true if the color is used in categories and not in initial values, false otherwise.
 */
export const isColorUsed = (color = '', categories = [], initialValues) => {
  const isUsedInCategories =
    categories?.some(
      category => category.color.toLowerCase() === color.toLowerCase()
    ) ?? false

  const isUsedInInitialValues =
    initialValues?.color.toLowerCase() === color.toLowerCase()

  return isUsedInCategories && !isUsedInInitialValues
}
