import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useData } from '../../../hooks'
import actions from '../../../state/actions/data'
import { getKpisUrl, getKpisList, isFetchingKpis } from '../../../selectors'
import { dataTypes } from '../../../configurations'
import { Loading } from '../../Common'
import { SlidePanel } from '../../Common/SlidePanel'
import Form, { formModes } from './Form'
import ActionButton from '../../Common/ActionButton'
import { ReactComponent as EditIcon } from '../../../assets/icons/VF_edit_rgb.svg'

import './kpis.scss'

const ListContainer = () => {
  const { t } = useTranslation()
  const [showSlidePanel, setShowSlidePanel] = useState(false)
  const [panelTitle, setPanelTitle] = useState('')
  const [panelContent, setPanelContent] = useState(null)

  const { data, isFetching } = useData(
    dataTypes.kpis.name,
    null,
    getKpisUrl,
    getKpisList,
    actions.requestPreInit,
    isFetchingKpis
  )

  const handleClosePanel = () => {
    setShowSlidePanel(false)
    setPanelTitle('')
    setPanelContent(null)
  }

  const handleForm = (item = null) => {
    // item is null for create
    const initialValues = {
      year: item?.year ?? '',
      kpi: item?.kpi ?? '',
      numberOfUsers: item?.numberOfUsers ?? ''
    }

    setPanelTitle(
      item ? t('admin.kpis.modal.edit') : t('admin.kpis.modal.create')
    )
    setPanelContent(
      <Form
        initialValues={initialValues}
        closePanel={handleClosePanel}
        mode={!!item ? formModes.edit : formModes.create}
      />
    )
    setShowSlidePanel(true)
  }

  if (!data && isFetching) {
    return <Loading />
  }

  return (
    <div className='kpis'>
      {data?.map(item => (
        <div key={item.year} className='kpis__item'>
          <div>
            <div className='kpis__item__label'>
              {t('admin.kpis.labels.year')}
            </div>
            <div>{item.year}</div>
          </div>

          <div>
            <div className='kpis__item__label'>
              {t('admin.kpis.labels.numberOfUsers')}
            </div>
            <div>{item.numberOfUsers}</div>
          </div>

          <div>
            <div className='kpis__item__label'>
              {t('admin.kpis.labels.target')}
            </div>
            <div>{item.kpi}</div>
          </div>

          <div className='kpis__item__ctas'>
            <ActionButton onClick={() => handleForm(item)}>
              <EditIcon width={15} />
            </ActionButton>
          </div>
        </div>
      ))}

      <ActionButton
        onClick={() => handleForm(null)}
        className='action-btn--blue'>
        {t('admin.kpis.labels.create')}
      </ActionButton>

      <SlidePanel
        show={showSlidePanel}
        title={panelTitle}
        closeBtn
        noFooter
        handleClose={handleClosePanel}>
        {panelContent}
      </SlidePanel>
    </div>
  )
}

export default ListContainer
