import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '../../hooks'
import { dateLocal } from '../../helpers/date'
import NotificationCountContext from './NotificationCountContext'

const Item = ({ id, readBefore, occuredOn, content, contextId }) => (
  <div className='vf-notification-module-body-item'>
    <div className='vf-notification-module-body-item-col2'>
      <div
        className={`vf-notification-module-body-item-time ${
          !readBefore ? 'vf-text--secondary' : ''
        }`}>
        {dateLocal(occuredOn)}
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className='vf-text--body mt-1'
        style={{ fontWeight: 'normal', lineHeight: '22px' }}
      />
    </div>
  </div>
)

Item.propTypes = {
  id: PropTypes.string,
  readBefore: PropTypes.bool,
  occuredOn: PropTypes.string,
  content: PropTypes.string,
  contextId: PropTypes.string
}

const List = ({ t, items }) => {
  return !!items.length ? (
    <>
      {items.slice(0, 3).map(item => (
        <Item key={item.id} {...item} />
      ))}
    </>
  ) : (
    <div className='vf-notification-module-body-item'>
      {t('common.sorryNoItems')}
    </div>
  )
}

List.propTypes = {
  t: PropTypes.func,
  items: PropTypes.array
}

const NotificationModule = ({ closeModule }) => {
  const { t } = useTranslation()
  const { data: items, isFetching } = useNotifications()

  useEffect(() => {
    const handleClose = e => {
      const notificationModule = document.querySelector(
        '.vf-notification-module-container'
      )
      if (!notificationModule.contains(e.target)) {
        closeModule()
      }
    }

    document.addEventListener('click', handleClose)

    return () => {
      document.removeEventListener('click', handleClose)
    }
  }, [closeModule])

  const { resetCounter } = useContext(NotificationCountContext)

  useEffect(() => {
    if (!!items.length) {
      resetCounter()
    }
  }, [items, resetCounter])

  return (
    <div className='vf-notification-module-container' style={{ width: 'auto' }}>
      <div
        className='vf-notification-module'
        style={{
          zIndex: 10,
          top: 20,
          right: -10,
          transform: 'none',
          opacity: 1,
          width: 340
        }}>
        <div className='vf-notification-module-header'>
          {t('titles.notifications')}
          <Link
            to='/settings'
            className='vf-notification-module-header-settings vf-icon-settings'
          />
        </div>

        <div className='vf-notification-module-body'>
          {isFetching && (
            <div className='vf-notification-module-body-item'>
              {t('common.loading')}
            </div>
          )}

          {!isFetching && (
            <>
              <List t={t} items={items} />

              <div className='vf-notification-module-footer py-2'>
                <Link to='/user'>{t('notifications.seeMore')}</Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

NotificationModule.propTypes = {
  closeModule: PropTypes.func
}

export default NotificationModule
