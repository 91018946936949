import React from 'react'
import { PropTypes } from 'prop-types'
import './items.scss'

import { icons } from '../../assets/icons'
import { Link } from 'react-router-dom'

// #region PublishItem
export const ActivateItem = ({ handleClick }) => (
  <Item
    event='Activate this Challenge'
    time='Allow to create Ideas in here'
    icon={icons.Publish}
    handleClick={handleClick}
  />
)
ActivateItem.defaultProps = {
  handleClick: () =>
    console.warn('No click handler implemented for ActivateItem')
}
ActivateItem.propTypes = {
  handleClick: PropTypes.func
}
// #endregion

// #region EditItem
export const EditItem = ({ to, t }) => (
  <LinkItem
    event={t('titles.editTeam')}
    time={t('team.editTeamDescription')}
    icon={icons.Edit}
    to={to}
  />
)
EditItem.defaultProps = {
  to: ''
}
EditItem.propTypes = {
  to: PropTypes.string,
  t: PropTypes.func
}
// #endregion

// #region OnHoldItem
export const OnHoldItem = ({ handleClick }) => (
  <Item
    event='Back to Draft'
    time='Hide the Challenge and take a break.'
    icon={icons.Pause}
    handleClick={handleClick}
  />
)
OnHoldItem.defaultProps = {
  handleClick: () => console.warn('No Click Handler implemented for OnHoldItem')
}
OnHoldItem.propTypes = {
  handleClick: PropTypes.func
}
// #endregion

// #region ResumeItem
export const ResumeItem = ({ handleClick }) => (
  <Item
    event='Resume this Challenge'
    time='Make your post public again.'
    icon={icons.Play}
    handleClick={handleClick}
  />
)
ResumeItem.defaultProps = {
  handleClick: () => console.warn('No Click Handler implemented for ResumeItem')
}
ResumeItem.propTypes = {
  handleClick: PropTypes.func
}
// #endregion

// #region CloseItem
export const CloseItem = ({ handleClick }) => (
  <Item
    event='Close this Challenge'
    time='Another year passed!'
    icon={icons.Close}
    handleClick={handleClick}
  />
)
CloseItem.defaultProps = {
  handleClick: () => console.warn('No Click Handler implemented for CloseItem')
}
CloseItem.propTypes = {
  handleClick: PropTypes.func
}
// #endregion

// #region DeleteItem
export const DeleteItem = ({ handleClick }) => (
  <Item
    event='Delete the Challenge'
    time='Sorry, but... bye, bye...'
    icon={icons.Delete}
    handleClick={handleClick}
  />
)
DeleteItem.defaultProps = {
  handleClick: () => console.warn('No Click Handler implemented for DeleteItem')
}
DeleteItem.propTypes = {
  handleClick: PropTypes.func
}
// #endregion

const ItemInner = ({ event, time, icon }) => (
  <>
    <div
      className='notification__item-image'
      style={{
        backgroundImage: `url(${icon})`,
        backgroundSize: 'contain'
      }}
    />

    <div className='notification__item-col2'>
      <div className='notification__item-event'>{event}</div>
      <div className='notification__item-time'>{time}</div>
    </div>
  </>
)

ItemInner.defaultProps = {
  event: '',
  time: '',
  icon: ''
}

ItemInner.propTypes = {
  event: PropTypes.string,
  time: PropTypes.string,
  icon: PropTypes.string
}

// #region Item
const Item = ({ handleClick, ...props }) => {
  return (
    <div onClick={handleClick} className='notification__item'>
      <ItemInner {...props} />
    </div>
  )
}

Item.defaultProps = {
  handleClick: () => {}
}

Item.propTypes = {
  event: PropTypes.string,
  time: PropTypes.string,
  icon: PropTypes.string,
  handleClick: PropTypes.func
}
// #endregion

// #region LinkItem
const LinkItem = ({ to, ...props }) => {
  return (
    <Link to={to} className='notification__item'>
      <ItemInner {...props} />
    </Link>
  )
}

LinkItem.defaultProps = {
  to: ''
}

LinkItem.propTypes = {
  event: PropTypes.string,
  time: PropTypes.string,
  icon: PropTypes.string,
  to: PropTypes.string
}
// #endregion

const ItemContainer = ({ children }) => (
  <div className='notification mb-3' aria-label='Notifications'>
    {children}
  </div>
)

export default ItemContainer

// </a>

// <div href="" className="vf-notification-module-body-item">

//   <div className="vf-notification-module-body-item-col1">

//     <div className="vf-notification-module-body-item-image"
//       style="background-image: url('../../assets/icons/pause_rgb.png')">

//     </div>

//   </div>

//   <div className="vf-notification-module-body-item-col2">

//     <div className="vf-notification-module-body-item-event">
//       Set <span className="vf-notification-module-body-item-event-action">On Hold</span> your post
//     </div>

//     <div className="vf-notification-module-body-item-time">
//       Hide your post and take a break.
//     </div>

//   </div>

// </div>

// <div href="" className="vf-notification-module-body-item">

//   <div className="vf-notification-module-body-item-col1">

//     <div className="vf-notification-module-body-item-image"
//       style="background-image: url('../../assets/icons/play_rgb.png')">

//     </div>

//   </div>

//   <div className="vf-notification-module-body-item-col2">

//     <div className="vf-notification-module-body-item-event">
//       Resume <span className="vf-notification-module-body-item-event-action">your Recruit</span>
//     </div>

//     <div className="vf-notification-module-body-item-time">
//       Make your post public again.
//     </div>

//   </div>

// </div>

// <div className="vf-notification-module-body-item">

//   <div className="vf-notification-module-body-item-col1">

//     <div className="vf-notification-module-body-item-image" style="background-image: url('../../assets/icons/off_rgb.png')">

//     </div>

//   </div>

//   <div className="vf-notification-module-body-item-col2">

//     <div className="vf-notification-module-body-item-event">
//       Close <span className="vf-notification-module-body-item-event-action">your Recruit</span>
//     </div>

//     <div className="vf-notification-module-body-item-time">
//       We hope you found the right person.
//     </div>

//   </div>

// </div>

// <div className="vf-notification-module-body-item">

//   <div className="vf-notification-module-body-item-col1">

//     <div className="vf-notification-module-body-item-image"
//       style="background-image: url('../../assets/icons/trash_rgb.png')">

//     </div>

//   </div>

//   <div className="vf-notification-module-body-item-col2">

//     <div className="vf-notification-module-body-item-event">
//       Delete <span className="vf-notification-module-body-item-event-action">the Position</span>
//     </div>

//     <div className="vf-notification-module-body-item-time">
//       Bye, bye...
//     </div>

//   </div>

// </div>

// <div className="vf-notification-module-body-item">

//   <div className="vf-notification-module-body-item-col1">

//     <div className="vf-notification-module-body-item-image"
//       style="background-image: url('../../assets/icons/modules_rgb.png')">

//     </div>

//   </div>

//   <div className="vf-notification-module-body-item-col2">

//     <div className="vf-notification-module-body-item-event">
//       Copy <span className="vf-notification-module-body-item-event-action">this Entry</span>
//     </div>

//     <div className="vf-notification-module-body-item-time">
//       And open more positions like the one.
//     </div>

//   </div>

// </div>
