import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Field, useFormikContext } from 'formik'

import {
  DatePicker,
  DropDown,
  MSGraphSearch,
  TagsPicker,
  TextArea
} from '../../../Common'
import { getUrlFromConfig } from '../../../../selectors'
import { useLanguage, useProfile } from '../../../../hooks'
import {
  getLabel,
  obligatoryStatusesForAssignee,
  obligatoryStatusesForDeadline
} from '../../IdeaForm/helpers'
import ideaStates from '../../../../configurations/ideaStates'
import { getAssignee } from './helpers'

const Form = ({ t, statuses = [], minDate, teams = [] }) => {
  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'))
  const lng = useLanguage()

  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const teamsOptions = teams.map(item => ({
    value: item.id,
    label: item.title
  }))
  const { user } = useProfile()
  const assignee = getAssignee(user)

  const handleStatusChange = (_, status) => {
    const isStatusComplted = status === ideaStates.completed.apiValue
    const isStatusIterrupted = status === ideaStates.interrupted.apiValue
    if (isStatusComplted || isStatusIterrupted) {
      if (!values.assignee) {
        setFieldValue('assignee', assignee)
        setFieldTouched('assignee', false)
      }
      if (!values.deadline) {
        setFieldValue('deadline', new Date())
        setFieldTouched('deadline', false)
      }
    }
  }

  return (
    <>
      <Field
        name='assignee'
        label={getLabel(
          t,
          'assignee',
          obligatoryStatusesForAssignee.includes(values.ideaState)
        )}
        component={MSGraphSearch}
        rawUrl={profileSearchUrl}
        isMulti={false}
        isClearable
      />
      <Field
        name='deadline'
        label={getLabel(
          t,
          'deadline',
          obligatoryStatusesForDeadline.includes(values.ideaState)
        )}
        component={DatePicker}
        minDate={minDate}
        language={lng}
      />
      <Field
        name='firstCategoryTags'
        // label is set in TagsPicker component
        component={TagsPicker}
        onlyFirstCategory
      />
      <Field
        name='tags'
        label={t('idea-details.form.labels.tags')}
        component={TagsPicker}
        withoutFirstCategory
      />
      <Field
        name='ideaState'
        label={t('idea-details.form.labels.status')}
        component={DropDown}
        onChange={handleStatusChange}
        options={statuses}
      />

      <Field
        name='challenge'
        label={t('idea-details.form.labels.team')}
        component={DropDown}
        options={teamsOptions}
      />

      <Field
        name='activities'
        label={t('idea-details.form.labels.activities')}
        component={TextArea}
        maxLength={500}
      />
    </>
  )
}

Form.propTypes = {
  t: PropTypes.func.isRequired,
  statuses: PropTypes.array,
  minDate: PropTypes.instanceOf(Date),
  teams: PropTypes.array
}

export default Form
