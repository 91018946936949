import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from './VF_close_rgb.svg'
import './slidePanel.scss'

export const directions = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
}

const SlidePanel = ({
  children,
  size = 25,
  direction = directions.left,
  title = '',
  intro = '',
  show,
  handleClose = () => {},
  closeBtn = false,
  noFooter = false,
  parentRef,
  ...props
}) => {
  let style = {}
  let hiddenStyle = {}
  let common = {}
  const mainNavWidthPx = 57
  const headerHeightPx = 0

  useEffect(() => {
    if (show && size === 100) {
      // ab - disable background scroll
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [show, size])

  switch (direction) {
    case directions.left:
      common = {
        top: `${headerHeightPx}px`,
        height: `calc(100% - ${headerHeightPx}px)`,
        width: `${size}%`
      }
      style = {
        right: 0,
        maxWidth: `calc(100% - ${mainNavWidthPx}px)`,
        borderLeft: '1px solid #ebebeb',
        ...common
      }
      hiddenStyle = {
        right: `-${size}%`,
        ...common
      }
      break
    case directions.bottom:
      common = {
        height: `${size}%`,
        maxHeight: `calc(100% - ${headerHeightPx}px)`,
        width: `calc(100% - ${mainNavWidthPx}px)`,
        left: mainNavWidthPx
      }
      style = {
        right: 0,
        bottom: 0,
        overflowY: 'auto',
        ...common
      }
      hiddenStyle = {
        bottom: `-${size}%`,
        ...common
      }
      break
    default:
      style = {}
  }

  const styles = show ? style : hiddenStyle

  return (
    <div className='slide-panel' style={styles} ref={parentRef} {...props}>
      {closeBtn && (
        <div className='slide-panel__close'>
          <button onClick={handleClose} data-test='slide-panel-close'>
            <CloseIcon width={20} />
          </button>
        </div>
      )}
      {!!title.length && <h4>{title}</h4>}
      {!!intro.length && <p className='vf-typo-p-secondary'>{intro}</p>}
      {children}
      {!noFooter && (
        <footer>
          <span className='confirm-selection-btn mt-2' onClick={handleClose}>
            Done&nbsp;&raquo;
          </span>
        </footer>
      )}
    </div>
  )
}

SlidePanel.propTypes = {
  children: PropTypes.any,
  // size in percents
  size: PropTypes.number,
  direction: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  closeBtn: PropTypes.bool,
  noFooter: PropTypes.bool,
  parentRef: PropTypes.any
}

export default React.forwardRef((props, ref) => (
  <SlidePanel {...props} parentRef={ref} />
))
