// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {string} [props.className]
 * @param {React.ReactNode} [props.children]
 * @returns {JSX.Element}
 */
const IdeaLink = ({ id, children, ...other }) => (
  <Link to={`/improvement-details/${id}`} {...other}>
    {children}
  </Link>
)

IdeaLink.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  other: PropTypes.object
}

export default IdeaLink
