import * as yup from 'yup'

import ideaStates from '../../../configurations/ideaStates'
import { getEnvVariable } from '../../../.env.custom'

export const getIdeaSearchUrl = () => {
  return `${getEnvVariable('REACT_APP_API_ROOT')}ideas/{?lng,sort,containsText}`
}

export const obligatoryStatusesForAssignee = [
  ideaStates.assigned.apiValue,
  ideaStates.completed.apiValue,
  ideaStates.interrupted.apiValue
]

export const obligatoryStatusesForDeadline = [
  ideaStates.completed.apiValue,
  ideaStates.interrupted.apiValue
]

export const checkIfObligatory = (value, { parent }, obligatoryStatuses) => {
  const isStatusObligatory = obligatoryStatuses.includes(parent.ideaState)

  if (isStatusObligatory && !value) {
    return false
  }
  return true
}

export const getLabel = (t, label, required = false) => {
  const translatedLabel = t(`idea-details.form.labels.${label}`)
  return required ? translatedLabel + ' *' : translatedLabel
}

export const validationSchema = (t, edit) =>
  yup.object({
    title: yup
      .string()
      .required(t('idea-details.form.validation.titleRequired')),
    description: yup
      .string()
      .required(t('idea-details.form.validation.descriptionRequired')),
    ideaValue: yup.string(),
    activities: yup
      .string()
      .max(
        500,
        t('idea-details.form.validation.tooLongMessage', { count: 500 })
      ),
    targetGroup: yup.string(),
    requiredResources: yup.string(),
    summaryInEnglish: yup.string(),
    solution: yup.string(),
    coCreators: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string()
      })
    ),
    assignee: yup
      .mixed()
      .test(
        'checkIfAssineeObligatory',
        `${t('idea-details.form.validation.chooseAssignee')}`,
        (value, state) =>
          checkIfObligatory(value, state, obligatoryStatusesForAssignee)
      ),
    deadline: yup
      .mixed()
      .test(
        'checkIfDeadlineObligatory',
        `${t('idea-details.form.validation.deadlineRequired')}`,
        (value, state) =>
          checkIfObligatory(value, state, obligatoryStatusesForDeadline)
      )
  })

/**
 * Concats tags from first category and tags to one array. Formats tags for API.
 * @param {Object} values - form values
 * @returns {Array} - tags array
 */
export const formatTagsForApi = values =>
  [...values.firstCategoryTags, ...values.tags].map(item => item.value) || []
