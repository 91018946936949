// @ts-check
import React from 'react'
import { Formik, Field, Form } from 'formik'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { queryParamsToObject } from '../List'
import ActionButton from '../../Common/ActionButton'
import { AssigneePicker } from '../../Common/AssigneePicker'
import {
  DatePicker,
  DropDownv2 as DropDown,
  SubmitButton,
  TeamsPicker,
  TextInput
} from '../../Common'
import { TagsPickerWithSearchType as TagsPicker } from '../../Common/TagsPicker'
import { useLanguage, useProfile } from '../../../hooks'
import { BookmarksForm } from '../Bookmarks'
import { VfButton } from '../../DesignSystem/Buttons'
import {
  formValuesToLocalStorage,
  getNonEmptyParamsSize
} from '../SearchBar/Bookmarks/helpers'
import { removeFromSessionStorage, saveToSessionStorage } from '../helpers'

import './searchForm.scss'
import { getSortOptions } from './options'

const SearchFormContent = formikProps => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const { isSubmitting, dirty } = formikProps

  return (
    <div className='kanban-board__filters search-form__container'>
      <Field
        name='q'
        label={t('kanban-board.search-form.labels.searchText')}
        component={TextInput}
        className='search-form__input'
      />
      <Field
        name='challengeId'
        label={t('kanban-board.search-form.labels.team')}
        component={TeamsPicker}
      />

      <Field
        name='tags'
        label={t('kanban-board.search-form.labels.tags')}
        component={TagsPicker}
      />

      <Field
        name='assigneeId'
        label={t('kanban-board.search-form.labels.assignee')}
        component={AssigneePicker}
      />

      <Field
        name='sort'
        label={t('kanban-board.search-form.labels.sort')}
        component={DropDown}
        options={getSortOptions(lng)}
      />

      <Field
        name='deadline'
        label={t('kanban-board.search-form.labels.deadline')}
        component={DatePicker}
        language={lng}
      />

      <Field
        name='ranking'
        label={t('kanban-board.search-form.labels.ranking')}
        component={DropDown}
        options={[
          { value: '', label: t('common.all') },
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' }
        ]}
      />

      <div style={{ textAlign: 'right' }} className='mt-2'>
        <SubmitButton
          button={<VfButton text={t('common.search')} className='mt-2' />}
          inProgress={isSubmitting}
          inProgressText={t('common.searching')}
          disabled={!dirty || isSubmitting}
        />
      </div>
    </div>
  )
}

const emptyValues = {
  challengeId: '',
  tags: '',
  assigneeId: '',
  sort: '',
  deadline: '',
  ranking: '',
  q: ''
}

export const formValidParams = Object.keys(emptyValues)

const SearchForm = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)
  const queryParamsObj = queryParamsToObject(queryParams)

  // from url getting assigneeId - need to transform it to { value: id, label: displayName }
  const assignee = useProfile(queryParamsObj?.assigneeId)
  const transformedAssignee =
    queryParamsObj?.assigneeId !== undefined
      ? { value: assignee?.user?.id, label: assignee?.user?.displayName }
      : { value: '', label: '' }

  // deadline is a string, need to transform it to a Date object
  if (queryParamsObj?.deadline) {
    queryParamsObj.deadline = new Date(queryParamsObj.deadline)
  }

  // set initial values for the form if there are any query params
  const initialValues =
    {
      ...queryParamsObj,
      assigneeId: transformedAssignee
    } || emptyValues

  // check if any data from Bookmarks is present to enable/disable the Bookmark and Clear buttons

  const isEmpty = !getNonEmptyParamsSize(queryParamsObj, formValidParams)

  const handleClearFilters = resetForm => {
    // clear query params
    formValidParams.forEach(key => queryParams.set(key, ''))

    // update URL without reloading the page
    history.replace(`${location.pathname}?${queryParams.toString()}`)

    // clear Formik form
    resetForm()

    // clear session storage not to restore filters on page reload (on back to Kanban page).
    removeFromSessionStorage()
  }

  /**
   * Want to add/update query params in the URL without reloading the page.
   * @param { Object } values
   */
  const handleSubmit = values => {
    const formattedValues = formValuesToLocalStorage(values)
    const { pathname: pathName } = location
    const formData = new Map(Object.entries(formattedValues))
    // get current query params
    const params = new URLSearchParams(location.search)

    // update (override) query params with form data
    for (const [key, value] of formData) {
      params.set(key, value.toString())
    }

    // save form data to session storage in case of navigating to another page (#61129).
    saveToSessionStorage(formattedValues)

    // update URL without reloading the page
    // window.history.replaceState(null, '', '?' + params.toString()) // not sure how to listen this event
    history.replace(`${pathName}?${params.toString()}`)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize // reinitialize form when initialValues change on Bookmark click
      >
        {formikProps => {
          const { resetForm, dirty, values } = formikProps

          return (
            <>
              <div className='ctas kanban-board__search-form__ctas'>
                <BookmarksForm
                  filtersFormIsDirty={dirty || !isEmpty}
                  filters={values}
                />
                <ActionButton
                  disabled={!dirty && isEmpty}
                  onClick={() => handleClearFilters(resetForm)}>
                  X {t('kanban-board.buttons.clearSearch')}
                </ActionButton>
              </div>
              <Form>
                <SearchFormContent {...formikProps} />
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default SearchForm
