import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker'

const CustomDatePicker = ({
  field,
  label,
  form: { setFieldValue, errors, touched },
  minDate,
  language
}) => {
  const handleChange = value => {
    setFieldValue(field.name, value)
  }

  const hasError = !!errors[field.name] && touched[field.name]
  return (
    <>
      {label && (
        <label htmlFor={field.name} className='mt-2'>
          {label}
        </label>
      )}

      <div className='vf-input-container'>
        <DatePicker
          name={field.name}
          dayPlaceholder='dd'
          monthPlaceholder='mm'
          yearPlaceholder='yyyy'
          showLeadingZeros
          value={field.value}
          onChange={handleChange}
          minDate={minDate}
          className={hasError ? 'error' : ''}
          locale={language}
        />
      </div>
      {hasError && (
        <div className='vf-utility-typo--error mt-2'>{errors[field.name]}</div>
      )}
    </>
  )
}

CustomDatePicker.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  minDate: PropTypes.instanceOf(Date),
  language: PropTypes.string
}

CustomDatePicker.defaultProps = {
  language: 'en'
}

export default CustomDatePicker
