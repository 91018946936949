// @ts-check
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { InputSearch } from './InputSearch'
import { List as Bookmarks } from './Bookmarks'
// import SearchForm from './SearchForm'
import ActionButton from '../../Common/ActionButton'
import { ReactComponent as FilterIcon } from '../../../assets/icons/VF_filter_rgb.svg'
import { getNonEmptyParamsSize } from './Bookmarks/helpers'

import './searchBar.scss'
import { formValidParams } from '../SearchForm/SearchForm'
import { queryParamsToObject } from '../List'

// code reffering to SearchForm commented out for now

const SearchBar = ({ handleFilterClick }) => {
  const { t } = useTranslation()
  // const refForm = createRef()
  const location = useLocation()
  // const history = useHistory()

  // get current query params
  const params = new URLSearchParams(location.search)
  // const q = params.get('q')

  // calculate number of non-empty params

  const queryParamsObj = queryParamsToObject(params)
  const paramsCount = getNonEmptyParamsSize(queryParamsObj, formValidParams)

  // const handleClear = e => {
  //   // clear input field
  //   const form = refForm.current
  //   form.q.value = ''

  //   // remove 'q' from URL
  //   params.delete('q')
  //   history.replace(`${location.pathname}?${params.toString()}`)
  // }

  return (
    <div className='search-bar'>
      {/* below code commented out for now */}
      {/* <SearchForm refForm={refForm}>
        <InputSearch
          inputProps={{
            placeholder: t('admin.users.common.search'),
            id: 'q',
            name: 'q',
            defaultValue: q
          }}
          handleClear={handleClear}
        />
      </SearchForm> */}
      <div className='search-bar__bookmarks'>
        <Bookmarks />
      </div>
      <ActionButton onClick={handleFilterClick}>
        <FilterIcon height={16} />
        {t('common.filter')} {paramsCount ? `(${paramsCount})` : ''}
      </ActionButton>
    </div>
  )
}

export default SearchBar
