// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { VfContainer } from '../Utils'

import './modal.scss'

const Modal = ({ hidden, fullScreen, closeModal, children }) =>
  !hidden && (
    <>
      <div
        className='modal__background'
        style={{
          display: hidden ? 'none' : 'block'
        }}
      />

      <div
        className={`modal__container ${
          fullScreen ? 'modal__container--full-screen' : ''
        }`}
        style={{
          display: hidden ? 'none' : 'block'
        }}>
        <VfContainer>
          <div className='modal__close-button'>
            <button onClick={closeModal}>&times;</button>
          </div>

          {children}
        </VfContainer>
      </div>
    </>
  )

Modal.propTypes = {
  hidden: PropTypes.bool,
  fullScreen: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.node
}

Modal.defaultProps = {
  hidden: true,
  fullScreen: false,
  closeModal: () => {},
  children: null
}

export default Modal
