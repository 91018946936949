// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import actions from '../../state/actions/dnd'
import Avatar from '../User/Avatar'
import { UserLink, IdeaLink, Tags } from '../Common'
import { StarsPainter } from '../Common/DetailsHelpers'
import { truncateStr } from '../../helpers/string'
import { useDrag } from 'react-dnd'
import { mergeUrlWithParams } from '../../helpers/url'
import { useLanguage } from '../../hooks'
import { tmpGetUrl } from '../Ideas/Expert/IdeaForm/EditForm'
import { icons } from '../../assets/icons'
import { onlyDateLocal } from '../../helpers/date'

/** @typedef {import('../../swagger/types').IdeaResource} IdeaResource */
/** @typedef {import('../../swagger/types').IdeaStates} IdeaStates */
/** @typedef {import('../../swagger/types').UpdateIdeaRequest} UpdateIdeaRequest */

/**
 * @param {Object} props
 * @param {IdeaResource} props.item
 * @param {Function} props.editIdea
 * @returns {JSX.Element}
 */
const Item = ({ item, editIdea }) => {
  const { t } = useTranslation()

  const {
    id: ideaId = '',
    deadline,
    title,
    starsCount = 0,
    tags = [],
    assignee,
    activities
  } = item
  const { id: assigneeId = '', displayName: assigneeName = '' } = assignee || {}
  const safeTitle = truncateStr(title, 120)
  const hasAssignee = !!Object.keys(assignee || {}).length

  return (
    <>
      <div className='kanban-board__item__top'>
        <div className='kanban-board__item__top-wrapper'>
          <p className='kanban-board__item__title'>{safeTitle}</p>
          {!!deadline && (
            <p className='kanban-board__item__deadline'>
              {t('common.deadline')}: {onlyDateLocal(deadline)}
            </p>
          )}
        </div>
        {hasAssignee && (
          <div className='kanban-board__item__profile-container'>
            {/* @ts-ignore */}
            <UserLink id={assigneeId} title={assigneeName}>
              <Avatar author={assignee} />
            </UserLink>
          </div>
        )}
      </div>
      {/* /top */}

      <div className='kanban-board__item__rating'>
        {/* @ts-ignore */}
        <StarsPainter rating={starsCount} />
        <div
          onClick={e => {
            e.preventDefault()
            editIdea(item)
          }}
          className='edit-button__icon'>
          <img src={icons.Edit} alt={t('common.edit')} />
        </div>
      </div>
      {/* /rating */}

      <div className='kanban-board__item__tags'>
        {/* @ts-ignore */}
        <Tags items={tags} />
      </div>
      {/* /tags */}
      {!!activities && (
        <div className='kanban-board__item__activities'>{activities}</div>
      )}
      {/* @ts-ignore */}
      <IdeaLink id={ideaId} className='kanban-board__item__link' />
    </>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  editIdea: PropTypes.func.isRequired
}

/**
 * Turn IdeaResource to UpdateIdeaRequest that can be send to API.
 * @param {IdeaResource} item
 * @returns {UpdateIdeaRequest} Item in format that can be send to API
 */
const uiItemToApiItem = item => {
  const {
    activities = '',
    assignee: { id: assignee = '' } = {},
    challenge,
    deadline = '',
    description = '',
    ideaState,
    ideaValue = '',
    solution = '',
    tags: tagsObj = [],
    title
  } = item

  const challengeId = challenge?.id || ''
  const createPublished = false
  const tags = tagsObj.map(tag => tag.id)

  return {
    activities,
    assignee,
    challengeId,
    createPublished,
    deadline,
    description,
    ideaState,
    ideaValue,
    solution,
    tags,
    title
  }
}

/**
 * @param {Object} props
 * @param {Function} props.editIdea
 * @param {string} props.filterUrl
 * @param {IdeaResource} props.item
 * @returns {JSX.Element}
 */
const Container = ({ editIdea, filterUrl: sourceFilterUrl, item }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()

  /**
   * @param {IdeaResource} item - id of item to be updated
   * @param {Object} dropResult
   * @param {IdeaStates} dropResult.name - new state of item
   */
  const handleDrop = (item, { name: newState }) => {
    const { id = '', _links: links, ideaState: oldState } = item

    /** @type {IdeaResource} */
    const updatedItem = { ...item, ideaState: newState }
    /** @type {UpdateIdeaRequest} */
    const apiItem = uiItemToApiItem(updatedItem)
    const ideaUrl = links?.self?.href || ''
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)
    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext },
      { dataType: 'dashboard' }
    ]

    // TODO: url should be taken from idea._links.update.href
    const url = tmpGetUrl(id)

    const source = item.filterUrls?.target || sourceFilterUrl
    const filterUrls = {
      source,
      target: source
        // update target filter with new state
        .replace(oldState, newState)
        // reset page to 0 when changing state to avoid pagination issues (#61198)
        .replace(/(page=)[0-9]+/, '$1' + '0')
    }

    dispatch(
      actions.updateDataInit(
        // @ts-ignore
        // pass filterUrls to item into state as cannot find another way to track source and target filters
        { ...updatedItem, filterUrls },
        apiItem,
        url,
        dataToInvalidate,
        filterUrls
      )
    )
  }

  const [, drag] = useDrag(() => ({
    type: 'box',
    item,
    end: (item, monitor) => {
      /** @type {{name: IdeaStates} | null} */
      const dropResult = monitor.getDropResult()

      if (item && dropResult) {
        handleDrop(item, dropResult)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  return (
    <div ref={drag} className='kanban-board__item'>
      {/* @ts-ignore */}
      <Item item={item} editIdea={editIdea} />
    </div>
  )
}

Container.propTypes = {
  editIdea: PropTypes.func.isRequired,
  filterUrl: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
}

export default Container
