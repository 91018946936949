// @ts-check
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { UserLink } from '../../Common'
import { Likes } from '../Details/IdeaRates'
import { CreateForm } from './'
import { dateLocal } from '../../../helpers/date'
import List from './List'
import { getCurrentUserId } from '../../../auth'
import {
  requestPreInit,
  invalidateComments
} from '../../../state/actions/other'

const Item = ({
  t,
  item: { author, createdOn: published, content, comments },
  item,
  idea,
  handleEdit,
  handleDelete,
  setLike,
  userLiked,
  likesCount
}) => {
  const commentsArr = Object.keys(comments).map(item => comments[item])
  const currentUserId = getCurrentUserId()
  const [subComment, setSubComment] = React.useState(false)
  const switchCreateForm = () => setSubComment(!subComment)
  // TO-DO take it from BE
  const canEdit = !!author && author.id === currentUserId
  const hasChild = !!Object.keys(item.comments).length
  const canDelete = !!author && author.id === currentUserId && !hasChild

  const formatContent = content =>
    content.replace(
      /@\[([^@]*)\]\(([^@]*)\)/g,
      '<span class="idea-details__comment__content__mentioned_user">$1</span>'
    )

  return (
    <div className='vf-border-bottom-gray-200 py-2 idea-details__comment'>
      <div className='mb-2 idea-details__comment__top'>
        <UserLink id={author.id}>{author.displayName}</UserLink>

        <div className='idea-details__comment__top__date'>
          {dateLocal(published)}
        </div>
      </div>

      <div
        className='idea-details__comment__content vf-text--black-50 mb-1'
        dangerouslySetInnerHTML={{ __html: formatContent(content) }}
      />

      <div className='idea-details__comment__bottom mb-0'>
        <Likes
          userLiked={userLiked}
          handleClick={setLike}
          likesCount={likesCount}
        />

        <button
          className='idea-details__action-button ml-2'
          onClick={switchCreateForm}>
          {t('common.reply')}
        </button>

        {canEdit && (
          <button
            className='idea-details__action-button'
            onClick={() => handleEdit(item)}>
            {t('common.edit')}
          </button>
        )}

        {canDelete && (
          <button
            className='idea-details__action-button'
            onClick={() => handleDelete(item)}>
            {t('common.delete')}
          </button>
        )}
      </div>

      {subComment && (
        <CreateForm
          idea={idea}
          className='ml-3'
          to={`@[${author.displayName}](${author.id})`}
          parent={item}
          handleCancelClick={switchCreateForm}
        />
      )}

      {!!commentsArr.length && <List children={commentsArr} idea={idea} />}
    </div>
  )
}

Item.propTypes = {
  t: PropTypes.func,
  item: PropTypes.object,
  idea: PropTypes.object,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  setLike: PropTypes.func,
  userLiked: PropTypes.bool,
  likesCount: PropTypes.number
}

const ItemContainer = ({ item, handleEdit, handleDelete, ...other }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [likes, setLikes] = useState(item.likedBy.map(item => item.id))
  const currentUserId = getCurrentUserId()
  const currentUserLiked = likes.includes(currentUserId)

  const addLike = () => {
    const newLikes = [...likes, currentUserId]
    setLikes(newLikes)
  }

  const removeLike = () => {
    const newLikes = likes.filter(item => item !== currentUserId)
    setLikes(newLikes)
  }

  const setLike = () => {
    // wp: optimistic ui in action
    const action = currentUserLiked ? removeLike : addLike
    action()

    const urlKey = currentUserLiked ? 'comment-dislike' : 'comment-like'
    const url = item._links[urlKey].href
    dispatch(requestPreInit(url))
    dispatch(invalidateComments())
  }

  return (
    <Item
      t={t}
      item={item}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      setLike={setLike}
      userLiked={currentUserLiked}
      likesCount={likes.length}
      {...other}
    />
  )
}

ItemContainer.propTypes = {
  item: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func
}

export default ItemContainer
