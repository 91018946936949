// @ts-check
import { put, call, all } from 'redux-saga/effects'
import actionCreators from '../../actions/data'
import api, { apiDelete } from '../../api'

function* deleteData({
  url,
  data,
  dataType,
  dataToInvalidate,
  refreshUrl = '',
  callback
}) {
  try {
    const response = yield call(api, url, apiDelete(data))

    if (Array.isArray(dataToInvalidate)) {
      const invalidations = dataToInvalidate.map(item =>
        put(actionCreators.invalidateData(item.dataType, item.context))
      )

      yield all(invalidations)
    }

    if (!!refreshUrl) {
      yield put(actionCreators.requestPreInit(refreshUrl, dataType))
    }

    if (typeof callback === 'function') {
      yield call(callback, response)
    }
  } catch (err) {
    // yield put(actionCreators.createDataFailed(err))
    console.log('error', err)
  }
}

export default deleteData
