// @ts-check
import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ChallengesList from '../Components/Challenges'
import IdeasList from '../Components/Ideas'
import { ideaLayoutTypes } from '../configurations'
import LinkToEditPage from '../Components/cms/page/LinkToEditPage'
import { getPaginationConfig } from '../selectors'

import './overview.scss'

function OverviewPage({ description, draftId, ...props }) {
  const { t } = useTranslation()

  const newIdeasStoreKey = 'new-ideas-list'

  const initialNewIdeasPaginationConfig = useSelector(
    getPaginationConfig(newIdeasStoreKey)
  )

  const getInitialPage = initialPaginationConfig => {
    if (!!initialPaginationConfig?.currentPage) {
      return initialPaginationConfig.currentPage - 1
    }

    return 0
  }

  const urlParams = {
    pageSize: 10,
    sort: 'LAST_PUBLISHED'
  }

  const newIdeasUrlParams = {
    ...urlParams,
    page: getInitialPage(initialNewIdeasPaginationConfig),
    status: ['NEW', 'ASSIGNED']
  }

  return (
    <div className='overview'>
      <LinkToEditPage pageId={draftId} />
      {/* Introduction section */}
      <section
        className='my-4 vf-text--center intro'
        dangerouslySetInnerHTML={{ __html: description }}
      />

      {/* Active challenges */}
      <h2>{t('titles.activeChallenges')}</h2>
      <ChallengesList />

      <div className='vf-row'>
        <div className='vf-col-sm-12 vf-col-lg-8'>
          {/* New improvements */}
          <h3>{t('titles.newIdeas')}</h3>
          <IdeasList
            layoutType={ideaLayoutTypes.side_item.type}
            showState
            showBottomPagination
            urlParams={newIdeasUrlParams}
            storeKey={newIdeasStoreKey}
          />
        </div>

        <div className='vf-col-sm-12 vf-col-lg-4'>
          {/* Popular improvements */}
          <h3>{t('titles.popularIdeas')}</h3>
          <IdeasList
            layoutType={ideaLayoutTypes.side_item.type}
            urlParams={{ sort: 'MOST_LIKES', pageSize: 5, page: 0 }}
          />
          <h3>{t('titles.implementedImprovements')}</h3>
          <IdeasList
            layoutType={ideaLayoutTypes.side_item.type}
            showState
            urlParams={{
              status: 'COMPLETED',
              pageSize: 5,
              page: 0,
              sort: 'LAST_PUBLISHED'
            }}
          />
        </div>
      </div>
    </div>
  )
}

OverviewPage.propTypes = {
  description: PropTypes.string,
  draftId: PropTypes.string
}

export default OverviewPage
