import {
  InnerTemplate,
  InnerTemplateWithHeaderImg,
  NotificationBadge,
  OverviewTemplate
} from './Components/Template'
import { AvatarCurrentUser } from './Components/User/Avatar'
// @ts-check
import * as pages from './pages'
import * as cmsPages from './pages/cms'
import * as adminPages from './pages/admin'

const routes = [
  {
    path: '/admin',
    component: adminPages.Admin,
    titleTkey: 'mainNav.admin',
    exact: true,
    template: {
      component: InnerTemplate
    },
    nav: {
      parent: 0,
      templatedItem: null
    },
    expertRoute: true
  },
  {
    path: '/admin/tag-categories',
    component: adminPages.TagCategories,
    exact: true,
    template: {
      component: InnerTemplate
    },
    expertRoute: true
  },
  {
    path: '/admin/tags',
    component: adminPages.Tags,
    exact: true,
    template: {
      component: InnerTemplate
    },
    expertRoute: true
  },
  {
    path: '/admin/kpis',
    component: adminPages.Kpis,
    exact: true,
    template: {
      component: InnerTemplate
    },
    expertRoute: true
  },
  {
    path: '/admin/users',
    component: adminPages.Users,
    exact: true,
    template: {
      component: InnerTemplate
    },
    expertRoute: true
  },
  {
    id: 1,
    pageId: 'overview',
    titleTkey: 'mainNav.overview',
    path: '/',
    component: pages.Overview,
    exact: true,
    nav: {
      parent: 0,
      templatedItem: null
    },
    template: {
      component: OverviewTemplate
    }
  },
  {
    id: 2,
    pageId: 'challenges',
    titleTkey: 'mainNav.challenges',
    path: '/improvement-teams',
    component: pages.Challenges,
    nav: {
      parent: 0,
      templatedItem: null
    },
    template: {
      component: InnerTemplateWithHeaderImg
    },
    expertRoute: true
  },

  {
    id: 2.1,
    pageId: 'ideas',
    titleTkey: 'mainNav.kanban',
    path: '/kanban-board',
    component: pages.KanbanBoard,
    nav: {
      parent: 0,
      templatedItem: null
    }
  },

  {
    id: 3,
    titleTkey: 'Team Details',
    path: '/team-details/:challengeId',
    component: pages.ChallengeDetails
  },
  {
    id: 4,
    pageId: 'ideas',
    titleTkey: 'mainNav.searchIdeas',
    path: '/search',
    component: pages.SearchIdeas,
    nav: {
      parent: 0,
      templatedItem: null
    },
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    id: 4.5,
    titleTkey: 'Edit Improvement',
    path: '/improvement-details/:ideaId/edit',
    component: pages.IdeaEdit,
    template: {
      component: InnerTemplate
    }
  },
  {
    id: 5,
    titleTkey: 'Improvement Details',
    path: '/improvement-details/:ideaId',
    component: pages.IdeaDetails
  },
  {
    id: 14,
    pageId: 'dso-dashboard',
    titleTkey: 'mainNav.dsoDashboard',
    path: '/dso-dashboard',
    component: pages.Dashboard,
    nav: {
      parent: 0,
      templatedItem: null
    },
    template: {
      component: InnerTemplate
    }
  },
  {
    id: 6,
    pageId: 'about-us',
    titleTkey: 'mainNav.vtfInnovation',
    path: '/read-me',
    component: pages.ContentPage,
    nav: {
      parent: 0,
      templatedItem: null
    },
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    id: 13,
    pageId: 'hall-of-fame',
    titleTkey: 'mainNav.vtfHallOfFame',
    path: '/hall-of-fame',
    component: pages.HallOfFame
  },
  {
    id: 7,
    pageId: 'terms',
    titleTkey: 'Terms of agreement',
    path: '/terms-of-agreement',
    component: pages.ContentPage,
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    id: 8,
    pageId: 'user',
    titleTkey: 'mainNav.user',
    path: '/user/:userId',
    component: pages.User,
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    id: 9,
    pageId: 'user',
    titleTkey: 'mainNav.user',
    navIcon: AvatarCurrentUser,
    path: '/user',
    component: pages.User,
    nav: {
      parent: 0,
      templatedItem: NotificationBadge
    },
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    id: 10,
    pageId: 'user-settings',
    titleTkey: 'mainNav.settings',
    path: '/settings',
    component: pages.Settings,
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    id: 11,
    pageId: 'create-idea',
    titleTkey: 'Create Improvement',
    path: '/create-improvement/:challengeId',
    component: pages.IdeaCreate,
    template: {
      component: InnerTemplateWithHeaderImg
    }
  },
  {
    path: '/cms/team/create',
    component: cmsPages.CreateChallenge
  },
  {
    path: '/cms/team/:challengeId/update',
    component: cmsPages.EditChallenge
  },
  // http://localhost:3000/cms/page/about-us/update
  {
    path: '/cms/page/:pageId/update',
    component: cmsPages.EditPage
  },

  // 404 needs to be last
  {
    id: 12,
    titleTkey: '404 Page',
    component: pages.PageNotFound
  }
]

/**
 * @param {number} parentId
 */
export const getNavigationItemsByParentId = (parentId = 0) =>
  routes.filter(({ nav }) => nav && nav.parent === parentId)

/**
 * @returns {string | undefined}
 */
export const getHomePageConfig = () => routes.find(item => item.path === '/')

export default routes
