import React from 'react'
import PropTypes from 'prop-types'

import { sortTagsByCategory } from '../Admin/Tags/helpers'

import './tags.scss'

const Tags = ({ items = [] }) => {
  const tagsSortedByCategory = [...items].sort(sortTagsByCategory)

  return (
    <span className='idea-tags'>
      {tagsSortedByCategory?.map(item => (
        <span
          className='idea-tags__item'
          key={item.id}
          title={item.category?.name}
          style={{
            backgroundColor: item.category?.color || undefined
          }}>
          {item.name}
        </span>
      ))}
    </span>
  )
}

Tags.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      active: PropTypes.bool
    })
  )
}

export default Tags
