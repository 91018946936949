import { all, call } from 'redux-saga/effects'

import config from './config'
import data from './data'
import users from './users'
import other from './other'
import notifications from './notifications'
import files from './files'
import dnd from './dnd'

function* mainSaga(getState) {
  yield all([
    call(config),
    call(data),
    call(users),
    call(other),
    call(notifications),
    call(files),
    call(dnd)
  ])
}

export default mainSaga
