import { put, call, takeEvery } from 'redux-saga/effects'

import actionCreators from '../actions/files'
import api, { apiPost } from '../api'

const formatBody = files => {
  const formData = new FormData()

  files.forEach(file => {
    formData.append('files', file)
  })

  return formData
}

function* uploadFiles({ url, payload, callback }) {
  try {
    yield put(actionCreators.uploadRequestInit())

    const response = yield call(
      api,
      url,
      apiPost(formatBody(payload), 'UPLOAD')
    )

    if (response) {
      yield put(actionCreators.uploadSucceeded(response))

      if (typeof callback === 'function') {
        yield call(callback, response)
      }
    }
  } catch (err) {
    console.log('upload files error', err)

    yield put(actionCreators.uploadFailed(err))
  }
}

function* removeFiles({ payload, callback }) {
  // TODO:
  // const url = const url = getEnvVariable('REACT_FILES_UPLOADER')

  try {
    yield put(actionCreators.removeRequestInit())

    // const apiCalls = payload.map(item => call(api, url, apiDelete(item.id)))

    // const response = yield all(apiCalls)

    // if (response) {
    //   yield put(actionCreators.removeSucceeded(response))

    // if (typeof callback === 'function') {
    //   yield call(callback, response)
    // }
    // }
  } catch (err) {
    console.log('remove files error', err)

    yield put(actionCreators.removeFailed(err))
  }
}

export default function* filesSaga() {
  yield takeEvery('FILE/UPLOAD_PRE_INIT', uploadFiles)
  yield takeEvery('FILE/REMOVE_PRE_INIT', removeFiles)
}
