import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import FileSaver from 'file-saver'

import Modal from '../../DesignSystem/Modal'
import { AttachmentsList } from '../../Common/Attachments'
import { VfButton } from '../../DesignSystem/Buttons'
import { removeDocumentsPreInit } from '../../../state/actions/other'
import { useLanguage } from '../../../hooks'
import { mergeUrlWithParams } from '../../../helpers/url'
import { getEnvVariable } from '../../../.env.custom'
import { getToken } from '../../../auth'

const Attachments = ({ idea, isExpert = false, t }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)
  const [downloadingFileId, setDownloadingFileId] = useState(null)

  const switchModal = () => setDeleteModalOpen(!deleteModalOpen)

  const saveFile = file => {
    const downloadUrl =
      getEnvVariable('REACT_APP_API_ROOT').replace(/\/$/, '') + file.fileUrl

    setDownloadingFileId(file.id)

    return (
      getToken().then(token => {
        fetch(downloadUrl, {
          headers: {
            Authorization: 'Bearer ' + token.idToken.rawIdToken
          }
        })
          .then(res => res.blob())
          .then(blob => FileSaver.saveAs(blob, file.fileName))
          .catch(console.log)
          .finally(() => setDownloadingFileId(null))
      }),
      error => {
        throw new Error('Authentication error when calling api: ' + error)
      }
    )
  }

  const handleDeleteInit = file => {
    setFileToDelete(file)
    switchModal()
  }

  const handleDelete = () => {
    const rawUrl = idea._links['remove-document'].href
    const ideaUrl = idea._links.self.href
    const refreshUrl = mergeUrlWithParams({ lng })(ideaUrl)
    const data = [{ documentId: fileToDelete.id }]

    dispatch(removeDocumentsPreInit(rawUrl, data, switchModal, refreshUrl))
  }

  return (
    <div>
      <h3>{t('titles.attachments')}</h3>

      <AttachmentsList
        t={t}
        items={idea.documents}
        canDownload
        handleDownload={saveFile}
        downloadingFileId={downloadingFileId}
        canDelete={isExpert}
        handleRemove={handleDeleteInit}
      />

      {isExpert && (
        <Modal hidden={!deleteModalOpen} closeModal={switchModal}>
          <p>{t('common.deleteConfirm')}</p>

          <div className='vf-row mb-2'>
            <VfButton
              text={t('common.no')}
              color='secondary'
              onClick={switchModal}
              className='vf-col m-1'
            />

            <VfButton
              text={t('common.yes')}
              color='primary'
              onClick={handleDelete}
              className='vf-col m-1'
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

Attachments.propTypes = {
  idea: PropTypes.object,
  isExpert: PropTypes.bool,
  t: PropTypes.func
}

export default Attachments
