export const formatDate = date => (date ? new Date(date).toUTCString() : '')

export const convertFormValuesToParams = values => {
  if (!values) {
    return {}
  }

  const [dateFrom, dateTo] = values.publicationDate || []
  const [statusDateFrom, statusDateTo] = values.statusDate || []
  const tags = values.tags || []
  const statuses = values.status || []
  const department = values.department.value || ''
  const rankings = values.ranking || []
  const assigneeId = values.assigneeId?.value ?? ''

  const params = {
    ...values,
    status: statuses?.map(item => item.value),
    ranking: rankings?.map(item => item.value),
    dateFrom: formatDate(dateFrom),
    dateTo: formatDate(dateTo),
    statusDateFrom: formatDate(statusDateFrom),
    statusDateTo: formatDate(statusDateTo),
    deadline: formatDate(values.deadline),
    tags: tags?.map(item => item.value),
    department,
    assigneeId
  }

  delete params.publicationDate
  delete params.statusDate

  return params
}

export const TAG_SEARCH_KEY = 'tagSearchType'

export const TAG_SEARCH_TYPES = {
  AND: 'AND',
  OR: 'OR'
}
