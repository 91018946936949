import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import './Notifications.scss'
import { useTranslation } from 'react-i18next'

const Success = ({ itemId, url }) => {
  const location = useLocation()
  const returnUrl = new URLSearchParams(location.search).get('returnTo') || url
  const { t } = useTranslation()

  return (
    <p className='vf-utility-typo--success notification-container mt-3'>
      {t('notifications.success')}{' '}
      {!!returnUrl && (
        <>
          <Link to={returnUrl}>{t('notifications.goToItem')}</Link> or{' '}
        </>
      )}
      <Link to='/'>{t('notifications.goHome')}</Link>.
    </p>
  )
}

Success.propTypes = {
  itemId: PropTypes.string,
  url: PropTypes.string
}

const Error = () => (
  <p className='vf-utility-typo--error notification-container'>Error</p>
)

export const getNotification = (itemId, url) =>
  !!itemId ? <Success itemId={itemId} url={url} /> : <Error />

export const WithNotification = ({ Component, ...props }) => {
  const [Notification, setNotification] = useState(null)
  const [showForm, setShowForm] = useState(true)

  const hideForm = () => setShowForm(false)

  const trigger = res => {
    const Notification = getNotification(res.id, res.url)

    setNotification(Notification)
    hideForm()
  }
  return (
    <>
      {showForm && <Component notificationsTrigger={trigger} {...props} />}
      {Notification}
    </>
  )
}

WithNotification.propTypes = {
  Component: PropTypes.any
}
