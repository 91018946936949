// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import LengthCounter from './LengthCounter'

import './textarea.scss'

const TextArea = ({
  field,
  maxLength,
  label,
  form: { errors, touched, validateField },
  rows
}) => {
  const hasError = !!errors[field.name] && touched[field.name]
  const currentLength = !!field.value ? field.value.length : 0

  return (
    <>
      <label htmlFor={field.name} className='mt-2'>
        {label}
      </label>

      <div className='vf-input-container'>
        <textarea
          name={field.name}
          rows={rows || 8}
          {...field}
          className={`vf-input w-100 vf_textarea_input ${
            hasError ? 'vf-input--error' : ''
          }`}
          onBlur={() => validateField(field.name)}
        />
        {!!maxLength && (
          <LengthCounter
            currentLength={currentLength}
            maxLength={maxLength}
            cssClass='textarea__counter'
          />
        )}
      </div>

      {hasError && (
        <div className='vf-utility-typo--error mt-1'>{errors[field.name]}</div>
      )}
    </>
  )
}

TextArea.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  rows: PropTypes.number,
  maxLength: PropTypes.number
}

export default TextArea
