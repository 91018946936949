import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import ColorPicker from '../../../Common/ColorPicker'
import SaveBtn from '../../Tags/Ctas/SaveBtn'
import { validationSchema, colorOptions, isColorUsed } from './helpers'

export const CreateFormFields = ({ t, colorOptions }) => (
  <>
    <Field
      id='name'
      name='name'
      type='text'
      placeholder={t('admin.tagCategories.newCategoryPlaceholder')}
      autoComplete='off'
    />

    <ColorPicker
      name='color'
      label={t('admin.tagCategories.labels.color')}
      options={colorOptions}
    />
  </>
)

CreateFormFields.propTypes = {
  t: PropTypes.func,
  colorOptions: PropTypes.arrayOf(PropTypes.string)
}

export const EditFormFields = ({ t, colorOptions }) => (
  <>
    <Field id='name' name='name' type='text' />

    <ColorPicker
      name='color'
      label={t('admin.tagCategories.labels.color')}
      options={colorOptions}
    />
  </>
)

EditFormFields.propTypes = {
  t: PropTypes.func,
  colorOptions: PropTypes.arrayOf(PropTypes.string)
}

const FormContainer = ({
  initialValues,
  handleSubmit,
  btnText = 'Save',
  CancelBtn,
  FormFields,
  categories
}) => {
  const { t } = useTranslation()

  const filteredColors = colorOptions.filter(
    color => !isColorUsed(color, categories, initialValues)
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={() => validationSchema(t)}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}>
        {({ dirty, isSubmitting, ...formikProps }) => (
          <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormFields
                t={t}
                colorOptions={filteredColors}
                {...formikProps}
              />

              <div className='inline-list__form__ctas mt-1'>
                <SaveBtn
                  btnText={isSubmitting ? t('common.submitting') : btnText}
                  disabled={!dirty || isSubmitting}
                />

                {!!CancelBtn && <CancelBtn disabled={isSubmitting} />}
              </div>
            </div>

            <ErrorMessage
              component='div'
              name='name'
              className='vf-utility-typo--error'
            />

            <ErrorMessage
              component='div'
              name='color'
              className='vf-utility-typo--error'
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  btnText: PropTypes.string,
  CancelBtn: PropTypes.func,
  FormFields: PropTypes.func,
  categories: PropTypes.array
}

export default FormContainer
