import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import useModalContext from '../../../../hooks/useModalContext'
import actions from '../../../../state/actions/data'
import { getTagCategoriesListUrl, getTagsListUrl } from '../../../../selectors'
import { mergeUrlWithParams } from '../../../../helpers/url'
import ActionButton from '../../../Common/ActionButton'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/VF_trash_alt02_rgb.svg'
import { dataTypes } from '../../../../configurations'

const DeleteModalContent = ({ t, name }) => (
  <>
    <p className='mb-1'>
      {t('admin.tags.modal.confirmDelete')}
      <strong style={{ fontWeight: 500 }}> {name}</strong>?
    </p>

    <p>{t('admin.tags.modal.beAware')}</p>
  </>
)

DeleteModalContent.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string
}

const DeleteBtn = ({ item, deleteCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const openModal = useModalContext()

  const rawUrl = useSelector(getTagsListUrl)
  const tagCategoriesContext = useSelector(getTagCategoriesListUrl)

  const [inProgress, setInProgress] = useState(false)

  const handleDelete = () => {
    const listUrl = mergeUrlWithParams(null)(rawUrl)
    const deleteUrl = item._links?.delete?.href ?? null
    const dataToInvalidate = [
      { dataType: dataTypes.tags.name, context: listUrl },
      {
        dataType: dataTypes.tagCategories.name,
        context: tagCategoriesContext
      }
    ]

    if (!!deleteUrl) {
      setInProgress(true)

      dispatch(
        actions.deleteDataPreInit(
          deleteUrl,
          null,
          dataTypes.tags.name,
          dataToInvalidate,
          listUrl,
          res => {
            setInProgress(false)
            deleteCallback(res)(item.id)
          }
        )
      )
    }
  }

  const handleClick = () => {
    openModal({
      title: t('admin.tags.modal.title'),
      content: <DeleteModalContent t={t} name={item.name} />,
      onConfirm: handleDelete
    })
  }

  return (
    <ActionButton
      title={t('common.delete')}
      onClick={handleClick}
      disabled={inProgress}>
      <DeleteIcon height={16} />
    </ActionButton>
  )
}

DeleteBtn.propTypes = {
  item: PropTypes.object,
  deleteCallback: PropTypes.func
}

export default DeleteBtn
