import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { useVfAlchemyScript } from '../../../hooks'
import {
  DropDown,
  RichTextEditor,
  TextInput,
  SubmitButton,
  Checkbox
} from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { ImageInput } from '../../Common/Attachments'
import { languages } from '../challenge/Form'

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  handleDraftSubmit,
  ...parentProps
}) => {
  useVfAlchemyScript()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}>
      {formikProps => {
        return (
          <FormComponent
            handleDraftSubmit={handleDraftSubmit}
            {...formikProps}
            {...parentProps}
          />
        )
      }}
    </Formik>
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.any,
  handleSubmit: PropTypes.func,
  handleDraftSubmit: PropTypes.func,
  handleCountryChange: PropTypes.func,
  showLanguageDdl: PropTypes.bool
}

const FormComponent = ({
  handleDraftSubmit,
  handleCountryChange,
  showLanguageDdl,
  isValid,
  inProgress,
  isHome,
  ...props
}) => {
  const { t } = useTranslation()
  const imageSize = isHome ? { w: 1660, h: 634 } : { w: 1280, h: 340 }
  const imageSizeLabel = `Hero Image (${imageSize.w}px x ${imageSize.h}px)`

  return (
    <Form>
      {showLanguageDdl && (
        <Field
          name='language'
          label={t('forms.page.labels.language')}
          component={DropDown}
          onChange={handleCountryChange}
          options={languages.map(item => ({
            ...item,
            label: item.label
          }))}
        />
      )}

      <Field name='title' label={t('forms.page.labels.title')} component={TextInput} />

      <Field
        name='description'
        label={t('forms.page.labels.description')}
        component={RichTextEditor}
      />

      {isHome && (
        <Field name='showVideo' label={t('forms.page.labels.showVideo')} component={Checkbox} />
      )}

      <Field
        name='heroImage'
        label={imageSizeLabel}
        imageSize={imageSize}
        component={ImageInput}
      />

      {!isValid && (
        <p className='vf-utility-typo--error mt-3'>
          {t('forms.page.messages.error')}
        </p>
      )}
      <div className='vf-row'>
        <div className='mt-3 ml-auto'>
          <SubmitButton
            button={
              <VfButton
                text={t('forms.page.buttons.draft')}
                color='secondary'
                onClick={() => handleDraftSubmit(props)}
              />
            }
            inProgress={inProgress}
            disabled={inProgress}
          />{' '}
          <SubmitButton
            button={<VfButton text={t('forms.page.buttons.save')} type='submit' />}
            inProgress={inProgress}
            disabled={inProgress}
          />
        </div>
      </div>
    </Form>
  )
}

FormComponent.propTypes = {
  handleDraftSubmit: PropTypes.func,
  handleCountryChange: PropTypes.func,
  validateForm: PropTypes.func,
  values: PropTypes.any,
  setFieldTouched: PropTypes.func,
  setFieldError: PropTypes.func,
  showLanguageDdl: PropTypes.bool,
  isValid: PropTypes.bool,
  inProgress: PropTypes.bool,
  isHome: PropTypes.bool
}

export default FormContainer
