// #region Session Storage

const sessionKey = 'kanban-board-search'

/**
 * @param {Object} value
 * @param {string} [key]
 * @returns {void}
 */
export const saveToSessionStorage = (value, key = sessionKey) => {
  sessionStorage
    ? sessionStorage.setItem(key, JSON.stringify(value))
    : console.error('Session Storage not supported')
}

/**
 * @param {string} [key]
 * @returns {Object} value as Object
 */
export const getFromSessionStorage = (key = sessionKey) => {
  return sessionStorage ? JSON.parse(sessionStorage.getItem(key)) : null
}

/**
 * @param {string} [key]
 * @returns {void}
 */
export const removeFromSessionStorage = (key = sessionKey) => {
  sessionStorage.removeItem(key)
}
