// @ts-check
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Column from './List'
import ideaStates from '../../configurations/ideaStates'
import { SearchBar } from './SearchBar'
import { SlidePanel } from '../Common/SlidePanel'
import SearchForm from './SearchForm/SearchForm'
import { ExpertIdeaForm } from '../Ideas/Expert/IdeaForm'

import './kanbanBoard.scss'

const KanbanBoard = () => {
  const [showSlidePanel, setShowSlidePanel] = useState(false)
  const togglePannel = () => setShowSlidePanel(!showSlidePanel)
  const [panelForm, setPanelForm] = useState(null)
  const [panelTitle, setPanelTitle] = useState('')

  const openFilter = () => {
    setPanelTitle('Filter')
    setPanelForm(<SearchForm />)
    togglePannel()
  }

  // get filters from store as they are needed for update data on save
  const fetchedFilters = useSelector(state => state.dataCommon.ideas.filters)
  const arrayOfUrlsToRefreshData = Object.keys(fetchedFilters)

  const editIdea = item => {
    setPanelTitle(item.title)
    setPanelForm(
      <ExpertIdeaForm
        key={item.id}
        idea={item}
        refreshUrl={arrayOfUrlsToRefreshData}
      />
    )
    togglePannel()
  }

  return (
    <>
      <div className='kanban-board mt-2'>
        <SearchBar handleFilterClick={openFilter} />

        <div className='vf-row mt-2'>
          <Column ideaState={ideaStates.new} editIdea={editIdea} />
          <Column ideaState={ideaStates.assigned} editIdea={editIdea} />
          <Column ideaState={ideaStates.completed} editIdea={editIdea} />
          <Column ideaState={ideaStates.interrupted} editIdea={editIdea} />
        </div>
      </div>
      <SlidePanel
        closeBtn
        handleClose={togglePannel}
        noFooter
        show={showSlidePanel}
        size={33}
        title={panelTitle}>
        {panelForm}
      </SlidePanel>
    </>
  )
}

export default KanbanBoard
