import { formatDate } from '../../../Ideas/SearchIdeas/helpers'

const localStorageKey = 'kanbanBoardBookmarks'
export const eventKey = 'storage'

/** @typedef {import('./types').Bookmark} Bookmark */

/**
 * Get bookmarks from localStorage
 * @returns {Bookmark[]}
 */
export const getBookmarks = () => {
  return JSON.parse(localStorage.getItem(localStorageKey)) || []
}

/**
 * @param {Bookmark[]} bookmarks
 * @returns {void}
 */
export const setBookmarks = bookmarks => {
  localStorage.setItem(localStorageKey, JSON.stringify(bookmarks))
  window.dispatchEvent(new Event(eventKey))
}

/**
 * Add bookmark
 * @param {Bookmark} bookmark
 * @returns {void}
 */
export const addBookmark = bookmark => {
  const bookmarks = getBookmarks()
  bookmarks.push(bookmark)

  setBookmarks(bookmarks)
}

/**
 * Remove bookmark by id
 * @param {string} id
 * @returns {void}
 */
export const removeBookmark = id => {
  const bookmarks = getBookmarks()
  const newBookmarks = bookmarks.filter(bookmark => bookmark.name !== id)

  setBookmarks(newBookmarks)
}

/**
 * Update values from form to fit localStorage format: [{name: string, params: string}]
 * Various form components returns data in different formats (strings, objects, etc.)
 * @param {Object} values - form values (from Formik)
 */
export const formValuesToLocalStorage = values => {
  if (!values) {
    return values
  }

  // make sure deadline is a string and not undefined
  const { deadline = '' } = values

  /** @typedef {{value: string, label: string}} DdlData */

  /**
   * @param {DdlData[]} tags
   * @returns {string[]} tags as an array of strings: ['1', '2', '3']
   */
  const handleTags = tags => {
    if (!tags || !tags.filter(item => item).length) {
      return []
    }

    return tags.map(({ value }) => value)
  }

  /**
   * @param {DdlData} assigneeId
   * @returns {string}
   */
  const handleAssigneeId = assigneeId => {
    const { value } = assigneeId
    if (value === null || value === undefined) {
      return ''
    }

    return value
  }

  const tags = handleTags(values.tags)
  const assigneeId = handleAssigneeId(values.assigneeId)

  return {
    ...values,
    deadline: formatDate(deadline),
    tags,
    assigneeId
  }
}

/**
 * Calculate number of non-empty params
 * @param {Object} params
 * @returns {number} non-empty params count
 */
export const getNonEmptyParamsSize = (params, formValidParams) => {
  const localParams = { ...params }

  // remove from local params if it's not in formValidParams
  for (const key of Object.keys(localParams)) {
    if (!formValidParams.includes(key)) {
      delete localParams[key]
    }
  }
  // calculate number of non-empty params
  return Object.values(localParams).filter(Boolean).length
}
