// @ts-check

/**
 * @typedef {import('../../../types.d.ts').TagCategoryDto} TagCategoryDto
 */

/**
 * Returns an array of active categories.
 * @param {TagCategoryDto[]} items - The items to filter.
 * @return {TagCategoryDto[]} The active items.
 */
export const getActiveCategories = items =>
  items?.filter(item => item.active && !item.firstCategory) ?? []

/**
 * Returns an array of inactive categories.
 * @param {TagCategoryDto[]} items - The items to filter.
 * @return {TagCategoryDto[]} The inactive items.
 */
export const getInactiveCategories = items =>
  items?.filter(item => !item.active && !item.firstCategory) ?? []

/**
 * Sorts categories based on their name property.
 * @param {TagCategoryDto[]} items - The items to sort.
 * @returns {TagCategoryDto[]} The sorted items.
 */
export const getCategoriesSortedByName = items =>
  items?.sort((a, b) => a.name.localeCompare(b.name)) ?? []

/**
 * Returns the first category in the list.
 * @param {TagCategoryDto[]} items - The items to search.
 * @returns {TagCategoryDto | undefined} The first category.
 */
export const getFirstCategory = items => items?.find(item => item.firstCategory)

/**
 * Returns the other categories in the list.
 * @param {TagCategoryDto[]} items - The items to filter.
 * @returns {TagCategoryDto[]} The other categories.
 */
export const getOtherCategories = items =>
  items?.filter(item => !item.firstCategory) ?? []
