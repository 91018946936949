import React from 'react'
import * as Yup from 'yup'

import { ReactComponent as EditIcon } from '../../../assets/icons/VF_edit_rgb.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/VF_check_rgb.svg'
import ActionButton from '../../Common/ActionButton'

export const userStatus = {
  active: 'ACTIVE',
  inactive: 'DISABLED'
}

export const formModes = {
  create: 'create',
  edit: 'edit'
}

export const validationSchemaCreate = t =>
  Yup.object().shape({
    userName: Yup.object().required(t('admin.users.validation.userName')),
    expertUser: Yup.boolean(),
    activeUser: Yup.boolean()
  })

export const validationSchemaEdit = t =>
  Yup.object().shape({
    expertUser: Yup.boolean(),
    activeUser: Yup.boolean()
  })

export const listConfig = (t, handleForm) => ({
  gridTemplateColumns: '0.7fr 0.7fr 1.2fr 0.5fr 0.5fr 0.5fr 0.5fr',
  itemsWithTitle: [
    'firstName',
    'lastName',
    'email',
    'department',
    'activeUser'
  ],
  attributes: [
    {
      label: t('admin.users.labels.list.firstName'),
      propName: 'firstName',
      sortable: false
    },
    {
      label: t('admin.users.labels.list.lastName'),
      propName: 'lastName',
      sortable: false
    },
    {
      label: t('admin.users.labels.list.email'),
      propName: 'email',
      sortable: false
    },
    {
      label: t('admin.users.labels.list.department'),
      propName: 'department',
      sortable: false
    },
    {
      label: t('admin.users.labels.list.expertUser'),
      propName: 'expertUser',
      sortable: false,
      getClassName: () => 'expert-user',
      formatFn: item => (item.expertUser ? <CheckIcon width={15} /> : '')
    },
    {
      label: t('admin.users.labels.list.activeUser'),
      propName: 'activeUser',
      sortable: false,
      getClassName: item => (item.activeUser ? 'active' : 'disabled'),
      formatFn: item =>
        item.activeUser
          ? t('admin.users.common.active')
          : t('admin.users.common.disabled')
    },
    {
      label: t('admin.users.labels.list.edit'),
      propName: 'edit',
      formatFn: item => (
        <ActionButton onClick={() => handleForm(item)}>
          <EditIcon width={15} />
        </ActionButton>
      )
    }
  ]
})
