import * as Yup from 'yup'

const isPositiveInteger = value => {
  if (value) {
    return Number.isInteger(value) && value > 0
  }
  return true
}

const isPositiveFloat = value => {
  if (value) {
    return !isNaN(value) && value > 0
  }
  return true
}

export const validationSchema = t =>
  Yup.object().shape({
    year: Yup.number()
      .test('is-valid-year', t('admin.kpis.validation.yearInvalid'), value => {
        if (value) {
          return /^\d{4}$/.test(value.toString())
        }
        return true
      })
      .required(t('admin.kpis.validation.yearRequired')),
    numberOfUsers: Yup.number()
      .test(
        'is-positive-integer',
        t('admin.kpis.validation.numberOfUsersInvalid'),
        isPositiveInteger
      )
      .required(t('admin.kpis.validation.numberOfUsersRequired')),
    kpi: Yup.number()
      .test(
        'is-positive-float',
        t('admin.kpis.validation.targetInvalid'),
        isPositiveFloat
      )
      .required(t('admin.kpis.validation.targetRequired'))
  })
