// @ts-check
import React, { useState, lazy, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { SearchIdeasForm } from '../Components/Ideas'
import { Loading } from '../Components/Common'
import layoutTypes from '../configurations/ideaLayoutTypes'
import { searchFormParams, getPaginationConfig } from '../selectors'
import { convertFormValuesToParams } from '../Components/Ideas/SearchIdeas/helpers'
import actions from '../state/actions/other'

const IdeasList = lazy(() => import('../Components/Ideas'))

const SearchIdeas = () => {
  const [layoutType, setLayoutType] = useState(layoutTypes.table.type)

  const storeKey = 'search-ideas-list'
  const initialSearchFormParams = useSelector(searchFormParams)
  const initialPaginationConfig = useSelector(getPaginationConfig(storeKey))

  const getInitialPage = () => {
    if (!!initialPaginationConfig?.currentPage) {
      return initialPaginationConfig.currentPage - 1
    }

    return 0
  }

  const [params, setParams] = useState({
    pageSize: 30,
    page: getInitialPage(),
    sort: 'LAST_PUBLISHED',
    ...convertFormValuesToParams(initialSearchFormParams)
  })
  const [isSearching, setIsSearching] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [sortedByTrendiest, setSortedByTrendiest] = useState(false)

  const dispatch = useDispatch()

  const handleSearch = (newParams, isFiltered) => {
    dispatch(actions.resetPaginationConfig(storeKey))

    setParams({
      ...params,
      page: 0,
      ...newParams
    })

    setFiltered(isFiltered)

    if (newParams.sort === 'TRENDIEST') {
      setSortedByTrendiest(true)
    } else {
      setSortedByTrendiest(false)
    }
  }

  return (
    <>
      <SearchIdeasForm handleSearch={handleSearch} isSearching={isSearching} />

      <Suspense fallback={<Loading />}>
        <IdeasList
          filtered={filtered}
          layoutType={layoutType}
          setIsSearching={setIsSearching}
          setLayoutType={setLayoutType}
          showBottomPagination
          showHeader
          showTopPagination
          sortedByTrendiest={sortedByTrendiest}
          storeKey={storeKey}
          urlParams={params}
        />
      </Suspense>
    </>
  )
}

export default SearchIdeas
