/** @typedef {import('../swagger/types').IdeaStates} IdeaStates  */

/** @type {{[key: string]: {labelTKey: string, apiValue: IdeaStates }}} */
export const ideaStates = {
  new: { labelTKey: 'ideaStates.new', apiValue: 'NEW' },
  assigned: { labelTKey: 'ideaStates.assigned', apiValue: 'ASSIGNED' },
  completed: { labelTKey: 'ideaStates.completed', apiValue: 'COMPLETED' },
  interrupted: { labelTKey: 'ideaStates.interrupted', apiValue: 'INTERRUPTED' }
}

export const getStateTKey = type =>
  !!ideaStates[type] ? ideaStates[type].labelTkey : ''

export const getDDStatuses = t =>
  Object.keys(ideaStates).map(key => ({
    value: ideaStates[key].apiValue,
    label: t(ideaStates[key].labelTKey)
  }))

export default ideaStates
