import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useData, useLanguage } from '../../../hooks'
import {
  getDepartmentsDashboardUrl,
  getDashboardData,
  isFetchingDashboard
} from '../../../selectors'
import actions from '../../../state/actions/data'
import {
  getStartYearDate,
  getEndYearDate,
  getStructuredDepartments
} from '../Dashboard/helpers'
import { Loading } from '../../Common'
import DateRangeForm from './DateRangeForm'
import Table from './Table'

const PerOLCTable = () => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const [dateRange, setDateRange] = useState(null)

  const formatDate = date => (date ? new Date(date).toUTCString() : '')

  const { data: items, isFetching } = useData(
    'dashboard',
    {
      lng,
      statusDateFrom: !!dateRange
        ? formatDate(dateRange[0])
        : getStartYearDate(),
      statusDateTo: !!dateRange ? formatDate(dateRange[1]) : getEndYearDate()
    },
    getDepartmentsDashboardUrl,
    getDashboardData,
    actions.requestPreInit,
    isFetchingDashboard
  )

  const [itemsForTable, setItemsForTable] = useState([])

  useEffect(() => {
    if (!!items?.length) {
      const getTotalNumbers = (items, paramName) => {
        return items.reduce((total, item) => total + item[paramName], 0)
      }

      const itemsSortedByName = [...items].sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      )

      const totalRow = {
        id: 'total',
        name: t('improvementsTable.total'),
        implemented: getTotalNumbers(items, 'implemented'),
        interrupted: getTotalNumbers(items, 'interrupted'),
        customRowClassName: 'dashboard-table__body__row--total'
      }

      setItemsForTable([
        ...getStructuredDepartments(itemsSortedByName),
        totalRow
      ])
    }
  }, [items, t])

  return (
    <>
      <DateRangeForm
        setDateRange={setDateRange}
        isLoading={isFetching}
        lng={lng}
      />

      {!items && isFetching ? <Loading /> : <Table items={itemsForTable} />}
    </>
  )
}

export default PerOLCTable
