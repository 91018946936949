import React from 'react'
import PropTypes from 'prop-types'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const RichTextEditor = ({
  label,
  field: { value, name },
  form: { touched, errors, validateField, setFieldValue },
  ...props
}) => {
  const hasError = !!errors[name] && touched[name]
  return (
    <div className='my-2'>
      {label && <label className='my-2'>{label}</label>}
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            'blockQuote',
            'alignment',
            '|',
            'undo',
            'redo'
          ]
        }}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData()
          setFieldValue(name, data)
        }}
        onBlur={(event, editor) => validateField(name)}
      />
      {hasError && (
        <div className='vf-utility-typo--error mt-1'>{errors[name]}</div>
      )}
    </div>
  )
}

RichTextEditor.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object
}

export default RichTextEditor
