// @ts-check
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import callApi from '../state/api'
import cmsHero from './cmsBlocks/cmsHero'
import cmsImageBlock from './cmsBlocks/cmsImageBlock'
import cmsImagesSection from './cmsBlocks/cmsImagesSection'
import cmsTitle from './cmsBlocks/cmsTitle'

// WIP, to be moved to global map
const cmsBlocks = {
  'blocks.hero': cmsHero,
  'blocks.title': cmsTitle,
  'blocks.images': cmsImagesSection,
  'blocks.image-block': cmsImageBlock
}

const block = block => {
  // component does exist
  if (typeof cmsBlocks[block.__component] !== 'undefined') {
    return React.createElement(cmsBlocks[block.__component], {
      key: block.id,
      block
    })
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    {
      key: block.id
    }
  )
}

function HallOfFamePage({ description, draftId, ...props }) {
  const [page, setPage] = useState({})

  useEffect(() => {
    callApi(process.env.REACT_APP_CMS_ROOT + 'private-pages/1').then(
      content => {
        setPage(content)
      }
    )
  }, [])

  return page.contentBlocks ? (
    page.contentBlocks.map(block)
  ) : (
    <div className='vf-container'>loading...</div>
  )
}

HallOfFamePage.propTypes = {
  description: PropTypes.string,
  draftId: PropTypes.string
}

export default HallOfFamePage
