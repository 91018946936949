import React from 'react'
import PropTypes from 'prop-types'

import VfHero from '../../Components/DesignSystem/Hero'

const CmsHero = ({ block }) => {
  return (
    <VfHero
      imageUrl={block.image?.url}
      headline={block.heading}
      narrow={block.contentElement}
    />
  )
}

CmsHero.propTypes = {
  block: PropTypes.object
}

CmsHero.defaultProps = {
  block: {}
}

export default CmsHero
