import React from 'react'

import searchIconSrc from '../../../assets/icons/VF_search_rgb.svg'

import './search-box.scss'

const SearchBox = ({
  query = '',
  clearSearchButton = false,
  placeholder = 'Search...',
  handleSearch = () => {},
  onChange = () => {},
  handleClearSearch = () => {},
  searchIcon = searchIconSrc,
  searchButtonText = '',
  ...props
}) => {
  const handleChange = e => {
    onChange(e.target.value)
  }
  return (
    <div className='search-box__container'>
      <div className='search-box__wrapper'>
        <input
          type='text'
          className='search-box__input'
          placeholder={placeholder}
          value={query}
          onKeyDown={e => e.key === 'Enter' && handleSearch()}
          onChange={handleChange}
          {...props}
        />

        {!!clearSearchButton && !!query && (
          <div className='search-box__clear-btn' onClick={handleClearSearch}>
            &times;
          </div>
        )}
      </div>

      <div className='search-box__search-btn'>
        <button onClick={handleSearch}>
          <img src={!!searchButtonText ? '' : searchIcon} alt='' />
          {searchButtonText}
        </button>
      </div>
    </div>
  )
}

export default SearchBox
