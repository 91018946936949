import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { DropDown, SubmitButton } from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { ResetButton } from '../SearchIdeas/Form'
import { useVfAlchemyScript } from '../../../hooks'

const YearSelector = ({
  currentYear = new Date().getFullYear(),
  options,
  setYear,
  isLoading
}) => {
  const { t } = useTranslation()

  const handleSubmit = values => {
    setYear(values?.year ?? currentYear)
  }

  useVfAlchemyScript(options)

  return (
    <Formik initialValues={{ year: currentYear }} onSubmit={handleSubmit}>
      {({ dirty, resetForm, initialValues }) => (
        <Form>
          <div className='vf-row my-3'>
            <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
              <Field name='year' component={DropDown} options={options} />
            </div>

            <div
              className='vf-col-sm-12 vf-col-md-6 vf-col-lg-8'
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'flex-end'
              }}>
              <SubmitButton
                button={<VfButton text={t('common.apply')} color='primary' />}
                inProgress={isLoading}
                inProgressText={t('common.searching')}
                disabled={isLoading}
              />

              <ResetButton
                t={t}
                disabled={isLoading || !dirty}
                handleClick={() => {
                  resetForm({ year: currentYear })
                  handleSubmit(initialValues)
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

YearSelector.propTypes = {
  currentYear: PropTypes.number,
  options: PropTypes.array,
  setYear: PropTypes.func,
  isLoading: PropTypes.bool
}

export default YearSelector
