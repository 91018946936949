import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useData, useLanguage } from '../../../hooks'
import {
  getChallengesDashboardUrl,
  getDashboardData,
  isFetchingDashboard
} from '../../../selectors'
import actions from '../../../state/actions/data'
import { getStartYearDate, getEndYearDate } from '../Dashboard/helpers'
import { Loading } from '../../Common'
import DateRangeForm from './DateRangeForm'
import Table from './Table'

const PerTeamTableContainer = () => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const [dateRange, setDateRange] = useState(null)

  const formatDate = date => (date ? new Date(date).toUTCString() : '')

  const { data: items, isFetching } = useData(
    'dashboard',
    {
      lng,
      statusDateFrom: !!dateRange
        ? formatDate(dateRange[0])
        : getStartYearDate(),
      statusDateTo: !!dateRange ? formatDate(dateRange[1]) : getEndYearDate()
    },
    getChallengesDashboardUrl,
    getDashboardData,
    actions.requestPreInit,
    isFetchingDashboard
  )

  const [itemsForTable, setItemsForTable] = useState([])

  useEffect(() => {
    if (!!items?.length) {
      const formatForTable = item => ({
        id: item.challangeId,
        name: item.challengeName,
        ...item
      })

      const getTotalNumbers = (items, paramName) => {
        return items.reduce((total, item) => total + item[paramName], 0)
      }

      const formattedItems = items.map(formatForTable)

      const totalRow = {
        id: 'total',
        name: t('improvementsTable.total'),
        implemented: getTotalNumbers(formattedItems, 'implemented'),
        interrupted: getTotalNumbers(formattedItems, 'interrupted'),
        customRowClassName: 'dashboard-table__body__row--total'
      }

      setItemsForTable([...formattedItems, totalRow])
    }
  }, [items, t])

  return (
    <>
      <DateRangeForm
        setDateRange={setDateRange}
        isLoading={isFetching}
        lng={lng}
      />

      {!items && isFetching ? <Loading /> : <Table items={itemsForTable} />}
    </>
  )
}

export default PerTeamTableContainer
