import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import actions from '../../../state/actions/data'
import Header from './Header'
import { SlidePanel } from '../../Common/SlidePanel'
import Form from './Form'
import { VfButton } from '../../DesignSystem/Buttons'
import { formModes, listConfig } from './helpers'
import {
  getAllUsersList,
  getAllUsersUrl,
  getPaginationConfig,
  getAllUsersPaginationSetup,
  getIsAllUsersFetching,
  getAllUsersPagePrevUrl,
  getAllUsersPageNextUrl
} from '../../../selectors'
import { Loading, Pagination, SearchBox } from '../../Common'
import { useData, useLanguage } from '../../../hooks'
import { useSelector } from 'react-redux'

import './list.scss'

const Item = ({ item, listConfig }) => {
  const {
    attributes = [],
    gridTemplateColumns = '',
    itemsWithTitle = []
  } = listConfig

  const getItem = attr => {
    if (typeof attr.formatFn === 'function') {
      return attr.formatFn(item)
    }
    return get(item, attr.propName) || '-'
  }

  const getTitle = attr => {
    if (itemsWithTitle.includes(attr.propName)) {
      if (typeof attr.formatFn === 'function') {
        return attr.formatFn(item)
      }
      return get(item, attr.propName)
    }
  }

  return (
    <div className='list-item' style={{ gridTemplateColumns }}>
      {attributes.map(attr => (
        <div
          title={getTitle(attr)}
          key={attr.propName}
          className={`list-item-cell ${
            attr.getClassName ? attr.getClassName(item) : ''
          }`}>
          {getItem(attr)}
        </div>
      ))}
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  listConfig: PropTypes.object
}

const List = ({ items, listConfig }) => {
  return (
    <>
      {items?.map(item => (
        <Item key={item.id} item={item} listConfig={listConfig} />
      ))}
    </>
  )
}

List.propTypes = {
  items: PropTypes.array,
  listConfig: PropTypes.object
}

const ListContainer = ({
  showBottomPagination = true,
  showTopPagination = true
}) => {
  const { t } = useTranslation()
  const lng = useLanguage()

  const storeKey = 'all-users-list'

  const [pageUrl, setPageUrl] = useState(null)
  const [showSlidePanel, setShowSlidePanel] = useState(false)

  const initialPaginationConfig = useSelector(getPaginationConfig(storeKey))

  const getInitialPage = () => {
    if (!!initialPaginationConfig?.currentPage) {
      return initialPaginationConfig.currentPage - 1
    }
    return 0
  }

  const [urlParams, setUrlParams] = useState({
    page: getInitialPage(),
    pageSize: 50,
    lng
  })

  const { data, url, isFetching } = useData(
    'allUsers',
    { ...urlParams },
    getAllUsersUrl,
    getAllUsersList,
    actions.requestPreInit,
    getIsAllUsersFetching,
    pageUrl
  )

  const [panelTitle, setPanelTitle] = useState('')
  const [panelContent, setPanelContent] = useState(null)

  const handleClosePanel = () => {
    setShowSlidePanel(false)
    setPanelTitle('')
    setPanelContent(null)
  }

  const handleForm = (item = null) => {
    // item is null for create
    const initialValues = {
      id: item?.id ?? '',
      userName: item?.displayName ?? '',
      activeUser: item?.activeUser ?? true,
      expertUser: item?.expertUser ?? false
    }

    setPanelTitle(
      item
        ? t('admin.users.labels.modal.edit')
        : t('admin.users.labels.modal.create')
    )
    setPanelContent(
      <Form
        initialValues={initialValues}
        refreshUrl={url}
        urlParams={urlParams}
        closePanel={handleClosePanel}
        mode={!!item ? formModes.edit : formModes.create}
      />
    )

    setShowSlidePanel(true)
  }

  const prevUrl = useSelector(getAllUsersPagePrevUrl(url))
  const nextUrl = useSelector(getAllUsersPageNextUrl(url))

  const paginationSetup = useSelector(getAllUsersPaginationSetup(url))

  const handlePageClick = url => {
    setPageUrl(url)
  }

  useEffect(() => {
    setPageUrl(null)
  }, [urlParams])

  const [query, setQuery] = useState('')

  const handleSearch = () => {
    setUrlParams({ ...urlParams, query })
  }

  const handleClearSearch = () => {
    setQuery('')
    setUrlParams({ ...urlParams, query: '' })
  }

  return (
    <>
      <div
        className='mb-2 vf-text--right'
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}>
        <SearchBox
          query={query}
          handleSearch={handleSearch}
          clearSearchButton
          onChange={setQuery}
          handleClearSearch={handleClearSearch}
          placeholder={t('admin.users.common.search')}
        />
        <VfButton
          color='secondary'
          text={t('admin.users.common.addUser')}
          onClick={() => handleForm(null)}
        />
      </div>
      <div className='list-container'>
        {showTopPagination && (
          <div className='vf-row'>
            <Pagination
              storeKey={storeKey}
              totalPages={paginationSetup.totalPages}
              currentPage={paginationSetup.currentPage + 1}
              isFirst={!prevUrl}
              isLast={!nextUrl}
              handlePrevClick={() => handlePageClick(prevUrl)}
              handleNextClick={() => handlePageClick(nextUrl)}
            />
          </div>
        )}
        <Header listConfig={listConfig(t)} />

        {!data.length && !isFetching && <p>{t('common.sorryNoItems')}</p>}

        {!data.length && isFetching ? (
          <Loading />
        ) : (
          <List items={data} listConfig={listConfig(t, handleForm)} />
        )}
      </div>
      {showBottomPagination && (
        <div className='vf-row'>
          <Pagination
            storeKey={storeKey}
            totalPages={paginationSetup.totalPages}
            currentPage={paginationSetup.currentPage + 1}
            isFirst={!prevUrl}
            isLast={!nextUrl}
            handlePrevClick={() => handlePageClick(prevUrl)}
            handleNextClick={() => handlePageClick(nextUrl)}
          />
        </div>
      )}

      <SlidePanel
        show={showSlidePanel}
        title={panelTitle}
        closeBtn
        noFooter
        handleClose={handleClosePanel}>
        {panelContent}
      </SlidePanel>
    </>
  )
}

export default ListContainer
