import React from 'react'
import spinnerSrc from './spinner.gif'

const Loader = () => (
  <div style={{ textAlign: 'center' }}>
    <img src={spinnerSrc} alt='Loading...' style={{ height: 100 }} />
  </div>
)

export default Loader
