import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Form } from '.'
import {
  getChallengeDetailsById,
  getChallengeDetailsUrl
} from '../../../selectors'
import { dataTypes } from '../../../configurations'
import { useLanguage, useData } from '../../../hooks'
import actions from '../../../state/actions/data'
import { getEnvVariable } from '../../../.env.custom'
import { mergeUrlWithParams } from '../../../helpers/url'

const EditForm = ({ notificationsTrigger }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { challengeId } = useParams()
  const lng = useLanguage()
  const params = { lng, challengeId }

  const { data: item, url: challengeContext } = useData(
    dataTypes.challengeDetails.name,
    params,
    getChallengeDetailsUrl,
    getChallengeDetailsById,
    actions.requestPreInit
  )

  const validationSchema = Yup.object({
    title: Yup.string().required(t('team.form.validation.nameRequired')),
    description: Yup.string().max(
      500,
      t('team.form.validation.descriptionTooLong')
    ),
    members: Yup.array().required(t('team.form.validation.membersRequired'))
  })

  const initialValues = {
    title: item?.title || '',
    description: item?.description || '',
    members:
      item?.members.map(item => ({
        label: item.displayName,
        value: item.id
      })) || []
  }

  const [apiError, setApiError] = useState(null)

  const formatDataForApi = ({ title, description, members }) => ({
    title,
    description,
    members: members.map(item => item.value)
  })

  const sendDataToApi = (data, formActions) => {
    const callback = res => {
      if (!!res.error) {
        setApiError(t('messages.somethingWentWrong'))
        formActions.setSubmitting(false)
      } else {
        const returnUrl = `/team-details/${res.id}`

        notificationsTrigger({ id: res.id, url: returnUrl })
      }
    }

    const rootApi = getEnvVariable('REACT_APP_API_ROOT')
    const rawUrl = `${rootApi}challenges{?lng,challengeId}`
    const editUrl = mergeUrlWithParams({ lng, challengeId })(rawUrl)

    const dataToInvalidate = [
      { dataType: 'challenges' },
      { dataType: 'challenge-details', context: challengeContext }
    ]

    dispatch(
      actions.updateDataPreInit(
        editUrl,
        formatDataForApi(data),
        'challenges',
        dataToInvalidate,
        callback
      )
    )
  }

  return item ? (
    <Form
      validationSchema={validationSchema}
      initialValues={initialValues}
      handleSubmit={sendDataToApi}
      apiError={apiError}
    />
  ) : null
}

EditForm.propTypes = {
  notificationsTrigger: PropTypes.func
}

export default EditForm
