// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import IdeaSearchField from './IdeaSearchField'
import Attachments from '../../Common/Attachments/AttachmentsInput'
import {
  TextArea,
  DropDown,
  MSGraphSearch,
  TagsPicker,
  DatePicker
} from '../../Common'
import { getUrlFromConfig } from '../../../selectors'
import { useLanguage, useProfile, useVfAlchemyScript } from '../../../hooks'
import ideaStates from '../../../configurations/ideaStates'
import {
  getIdeaSearchUrl,
  getLabel,
  obligatoryStatusesForAssignee,
  obligatoryStatusesForDeadline
} from './helpers'
import { getAssignee } from '../Expert/IdeaForm/helpers'

import './form.scss'

const LINK_TO_SHAREPOINT_FILE =
  'https://sharepoint-de.corp.vattenfall.com/sites/TheGrid/Shared%20Documents/Forms/Home.aspx?id=%2fsites%2fTheGrid%2fShared%20Documents%2fDS-M%2fDS-MC%2fSt%C3%A4ndiga%20f%C3%B6rb%C3%A4ttringar%2fAdaptive%20Maintenance-fl%C3%B6det'

const LINK_TEXT = 'Adaptive Maintenance-flödet'

const Form = ({ t, edit, minDate, statuses = [] }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const lng = useLanguage()

  useVfAlchemyScript()

  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'))

  const ideaSearchUrl = getIdeaSearchUrl()

  const { user } = useProfile()
  const assignee = getAssignee(user)

  const handleStatusChange = (_, status) => {
    const isStatusComplted = status === ideaStates.completed.apiValue
    const isStatusIterrupted = status === ideaStates.interrupted.apiValue
    if (isStatusComplted || isStatusIterrupted) {
      if (!values.assignee) {
        setFieldValue('assignee', assignee)
        setFieldTouched('assignee', false)
      }
      if (!values.deadline) {
        setFieldValue('deadline', new Date())
        setFieldTouched('deadline', false)
      }
    }
  }

  return (
    <>
      <Field
        name='title'
        label={getLabel(t, 'title', true)}
        component={edit ? TextArea : IdeaSearchField}
        rawUrl={ideaSearchUrl}
      />
      <fieldset className='fieldSet'>
        <label htmlFor='description' className='form-label'>
          {getLabel(t, 'description', true)}
        </label>

        <div className='form-label-longDescription'>
          {getLabel(t, 'descriptionLong')}
        </div>
        <Field name='description' component={TextArea} />
      </fieldset>
      <fieldset className='fieldSet'>
        <label htmlFor='ideaValue' className='form-label'>
          {getLabel(t, 'ideaValue')}
        </label>
        <div className='form-label-longDescription'>
          {getLabel(t, 'ideaValueLong')}
        </div>
        <Field name='ideaValue' component={TextArea} />
      </fieldset>

      <fieldset className='fieldSet'>
        <label htmlFor='solution' className='form-label'>
          {getLabel(t, 'solution')}
        </label>

        <div className='form-label-longDescription'>
          {getLabel(t, 'solutionLong')}
        </div>
        <Field name='solution' component={TextArea} />
      </fieldset>

      <fieldset className='fieldSet'>
        <label htmlFor='activities' className='form-label'>
          {getLabel(t, 'activities')}
        </label>

        <div className='form-label-longDescription'>
          {getLabel(t, 'activitiesLong')}
        </div>
        <Field name='activities' component={TextArea} maxLength={500} />
      </fieldset>

      <Field
        name='ideaState'
        onChange={handleStatusChange}
        label={getLabel(t, 'status')}
        component={DropDown}
        options={statuses}
      />

      <Field
        name='deadline'
        label={getLabel(
          t,
          'deadline',
          obligatoryStatusesForDeadline.includes(values.ideaState)
        )}
        component={DatePicker}
        minDate={minDate}
        language={lng}
      />

      <Field
        name='assignee'
        label={getLabel(
          t,
          'assignee',
          obligatoryStatusesForAssignee.includes(values.ideaState)
        )}
        component={MSGraphSearch}
        rawUrl={profileSearchUrl}
        isMulti={false}
        isClearable
      />

      <Field
        name='firstCategoryTags'
        // label is set in the TagsPicker component
        component={TagsPicker}
        onlyFirstCategory
      />

      <Field
        name='tags'
        label={getLabel(t, 'tags')}
        component={TagsPicker}
        withoutFirstCategory
      />

      <fieldset className='fieldSet'>
        <label htmlFor='attachments' className='form-label'>
          {getLabel(t, 'attachments')}
        </label>

        <div className='form-label-longDescription'>
          {getLabel(t, 'attachmentsLong')}{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={LINK_TO_SHAREPOINT_FILE}>
            {LINK_TEXT}
          </a>
        </div>
        <Field name='attachments' component={Attachments} />
      </fieldset>
    </>
  )
}

Form.propTypes = {
  t: PropTypes.func,
  edit: PropTypes.bool,
  minDate: PropTypes.object,
  statuses: PropTypes.array
}

export default Form
