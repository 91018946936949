import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EditForm from './Form/Edit'
import ActionButton from '../../Common/ActionButton'
import { ReactComponent as EditIcon } from '../../../assets/icons/VF_edit_rgb.svg'
import MarkAsFirstBtn from './Ctas/MarkAsFirst'
import { InlineListItem } from '../../Common/InlineList'
import { getApiErrorMessage } from './Form/helpers'

const Ctas = ({ item, setIsEdited, editCallback }) => {
  const { t } = useTranslation()

  return (
    <>
      {!item.firstCategory && (
        <MarkAsFirstBtn item={item} editCallback={editCallback} />
      )}

      <ActionButton title={t('common.edit')} onClick={() => setIsEdited(true)}>
        <EditIcon height={16} />
      </ActionButton>
    </>
  )
}

Ctas.propTypes = {
  item: PropTypes.object,
  setIsEdited: PropTypes.func,
  editCallback: PropTypes.func
}

const Item = ({
  item,
  apiError,
  editCallback,
  handleCancelEdit,
  isEdited,
  ...props
}) => (
  <InlineListItem
    isEdited={isEdited}
    customStyle={{ borderColor: !isEdited ? item.color : undefined }}
    EditForm={props => (
      <EditForm
        item={item}
        apiError={apiError}
        editCallback={editCallback}
        handleCancel={handleCancelEdit}
        {...props}
      />
    )}
    Ctas={props => <Ctas item={item} editCallback={editCallback} {...props} />}
    {...props}>
    <span>{item.name}</span>
  </InlineListItem>
)

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    color: PropTypes.string
  }),
  apiError: PropTypes.string,
  editCallback: PropTypes.func,
  handleCancelEdit: PropTypes.func,
  isEdited: PropTypes.bool
}

const ItemContainer = ({
  item,
  editCategoryOptimisticUI,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation()
  const [isEdited, setIsEdited] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleCancelEdit = () => {
    setApiError(null)
    setIsEdited(false)
  }

  const editCallback = (newItem, formikActions) => res => {
    if (!!res.error) {
      const errorMessage = getApiErrorMessage(res, t)

      formikActions.setSubmitting(false)
      setApiError(errorMessage)
    } else {
      setApiError(null)
      editCategoryOptimisticUI({ ...item, ...newItem })
      setIsEdited(false)
    }
  }

  return (
    <Item
      item={item}
      isEdited={isEdited}
      setIsEdited={setIsEdited}
      apiError={apiError}
      editCallback={editCallback}
      handleCancelEdit={handleCancelEdit}
      readOnly={readOnly}
      {...props}
    />
  )
}

ItemContainer.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    active: PropTypes.bool,
    _links: PropTypes.object
  }),
  editCategoryOptimisticUI: PropTypes.func,
  readOnly: PropTypes.bool
}

export default ItemContainer
