// @ts-check
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import actions from '../../state/actions/data'
import otherActions from '../../state/actions/other'
import { useData, useLanguage } from '../../hooks'
import { dataTypes } from '../../configurations'
import {
  getFormUrl,
  getFormDetails,
  searchFormParams,
  getIsFormFetching
} from '../../selectors'
import { Loader } from '../Common'
import FormComponent from './SearchIdeas/Form'
import {
  convertFormValuesToParams,
  TAG_SEARCH_KEY,
  TAG_SEARCH_TYPES
} from './SearchIdeas/helpers'

import './search-form.scss'

const SearchForm = ({ handleSearch, isSearching }) => {
  const lng = useLanguage()
  const dispatch = useDispatch()

  const { data: form, isFetching } = useData(
    dataTypes.forms.name,
    { lng },
    getFormUrl('search'),
    getFormDetails,
    actions.requestPreInit,
    getIsFormFetching
  )

  const [isDirty, setIsDirty] = useState(false)
  const initialValuesInStore = useSelector(searchFormParams)

  const getInitialValues = elements =>
    elements.reduce(
      (acc, next) => ({
        ...acc,
        [next.parameterName]:
          next.parameterName === 'sort' ? 'LAST_PUBLISHED' : ''
      }),
      { [TAG_SEARCH_KEY]: TAG_SEARCH_TYPES.OR } // does not come from the API search form config
    )

  const handleSubmit = values => {
    dispatch(otherActions.setSearchFormParams(values))

    const params = convertFormValuesToParams(values)
    handleSearch(params, isDirty)
  }

  return isFetching || !form ? (
    <Loader />
  ) : (
    <Formik
      initialValues={getInitialValues(form.elements)}
      onSubmit={handleSubmit}>
      {formikProps => (
        <FormComponent
          lng={lng}
          form={form}
          initialValuesInStore={initialValuesInStore}
          isSearching={isSearching}
          handleSubmit={handleSubmit}
          setIsDirty={setIsDirty}
          {...formikProps}
        />
      )}
    </Formik>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func,
  isSearching: PropTypes.bool
}

SearchForm.defaultProps = {
  isSearching: false
}

export default SearchForm
