import Publish from './event_security_rgb.png'
import Edit from './edit_rgb.png'
import Pause from './pause_rgb.png'
import Play from './play_rgb.png'
import Close from './off_rgb.png'
import Delete from './trash_rgb.png'

export const icons = {
  Publish,
  Edit,
  Pause,
  Play,
  Close,
  Delete
}
