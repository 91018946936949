import React from 'react'

import './action-btn.scss'

const ActionButton = ({
  type = 'button',
  title,
  onClick = () => {},
  className = '',
  children,
  disabled = false,
  ...props
}) => (
  <button
    type={type}
    title={title}
    className={`action-btn ${className}`}
    onClick={onClick}
    disabled={disabled}
    {...props}>
    {children}
  </button>
)

export default ActionButton
