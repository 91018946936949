import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import './color-picker.scss'

const ColorPicker = ({
  options = [],
  value,
  onChange,
  label = 'Select color'
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const colorPickerRef = useRef()

  /**
   * Close the popover when clicking outside of it.
   * @param {Event} e
   * @returns {void}
   */
  const handleClickOutside = e => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
      setPopoverVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  const getOptionClassName = color => {
    let className = 'color-picker__popover__list__option'

    if (value === color) {
      className += ' color-picker__popover__list__option--selected'
    }

    return className
  }

  const onColorChange = color => {
    onChange(color)
    setPopoverVisible(false)
  }

  return (
    <div className='color-picker' ref={colorPickerRef}>
      <div
        className='color-picker__selected-color'
        style={{
          backgroundColor: value || '#eee'
        }}
        onClick={() => setPopoverVisible(!popoverVisible)}
      />

      {popoverVisible && (
        <div className='color-picker__popover'>
          <div>{label}</div>

          <div className='color-picker__popover__list'>
            {options.map((color, i) => (
              <div key={color} className={getOptionClassName(color)}>
                <label
                  htmlFor={`color-${i}`}
                  style={{ backgroundColor: color, borderColor: color }}
                />

                <input
                  type='radio'
                  id={`color-${i}`}
                  name='color-picker'
                  value={color}
                  checked={value === color}
                  onChange={() => onColorChange(color)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

ColorPicker.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
}

const ColorPickerWithFormik = ({ ...props }) => {
  const [field, , helpers] = useField(props)

  const { setValue } = helpers

  const handleChange = value => {
    setValue(value)
  }

  return <ColorPicker {...field} {...props} onChange={handleChange} />
}

export default ColorPickerWithFormik
