// @ts-check
import React, { useEffect, useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import routes from './routes'
import Template from './Components/Template'
import ScrollToTop from './Components/ScrollToTop'
import { requestPreInit as requestPreInitConfig } from './state/actions/config'
import actions from './state/actions/data'
import { useProfile, useData, useLanguage } from './hooks'
import {
  getPageUrl,
  getPageDetails,
  getIsUserExpert,
  getUserDetails,
  getErrorFromConfig,
  getConfig
} from './selectors'
import { dataTypes } from './configurations'
import TelemetryProvider from './appInsights/telemetry-provider'
import NotificationCountContext from './Components/Template/NotificationCountContext'
import { ConfirmModalContextProvider } from './contexts/ConfirmModalContext'
import { getToken, login, getCurrentUserId } from './auth'
import { NotAuthorizedPage } from './pages'

const RestrictRoute = ({ children, isExpertRoute }) => {
  const userId = getCurrentUserId()
  const user = useSelector(getUserDetails(userId))
  const isExpert = useSelector(getIsUserExpert(userId))

  if (isExpertRoute && !user) {
    return null
  }

  if (isExpertRoute && !isExpert) {
    return <Redirect to='/404' />
  }

  return children
}

RestrictRoute.propTypes = {
  children: PropTypes.node,
  isExpertRoute: PropTypes.bool
}

const RouteWithTemplate = ({
  component: Component,
  template,
  pageId,
  ...rest
}) => {
  const lng = useLanguage()
  const { data } = useData(
    dataTypes.pageDetails.name,
    { lng },
    getPageUrl(pageId),
    getPageDetails,
    actions.requestPreInit
  )

  const InnerTemplate =
    template && template.component ? template.component : null

  const heroImg = !!data ? data._links.heroImage?.href : null // wp: cannot be : '' in here because of console Warning
  const title = !!data ? data.title : null // wp: cannot be : '' in here because of console Warning

  return (
    <Route
      {...rest}
      render={props => (
        <RestrictRoute isExpertRoute={rest.expertRoute}>
          <Template {...rest}>
            {InnerTemplate ? (
              <InnerTemplate heroImg={heroImg} title={title}>
                <Component {...rest} {...data} />
              </InnerTemplate>
            ) : (
              <Component {...rest} {...data} />
            )}
          </Template>
        </RestrictRoute>
      )}
    />
  )
}

RouteWithTemplate.propTypes = {
  component: PropTypes.func,
  template: PropTypes.object,
  pageId: PropTypes.string
}

RouteWithTemplate.defaultProps = {
  template: null
}

function App() {
  const dispatch = useDispatch()

  // handle authentication
  useEffect(() => {
    getToken().then(
      () => {},
      login // redirect to login if token is not valid
    )
  }, [])

  const [notificationsCounter, setNotificationsCounter] = useState(0)
  const { user } = useProfile()

  const config = useSelector(getConfig)
  const isPayloadReady = Object.keys(config).length > 0

  const error = useSelector(getErrorFromConfig)
  const isNotAuthorized = error === 401

  useEffect(() => {
    dispatch(requestPreInitConfig())
  }, [dispatch])

  useEffect(() => {
    if (!!user) {
      setNotificationsCounter(user.pendingNotificationsCount)
    }
  }, [user])

  const resetCounter = () => setNotificationsCounter(0)

  if (!isPayloadReady) {
    return null
  }

  if (isNotAuthorized) {
    return <NotAuthorizedPage />
  }

  return (
    <Suspense fallback={null}>
      <Router>
        <TelemetryProvider>
          <ScrollToTop>
            <NotificationCountContext.Provider
              value={{ resetCounter, counter: notificationsCounter }}>
              <ConfirmModalContextProvider>
                <div>
                  <Switch>
                    {routes.map((item, i) => (
                      // @ts-ignore
                      <RouteWithTemplate key={i} {...item} />
                    ))}
                  </Switch>
                </div>
              </ConfirmModalContextProvider>
            </NotificationCountContext.Provider>
          </ScrollToTop>
        </TelemetryProvider>
      </Router>
    </Suspense>
  )
}

export default App
